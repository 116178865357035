/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

/** Icons/images */
import BannerImgFirm from '../assets/images/gca-isolated-bookkeeping-tax-firms.png';
import WhyTaxFirm from '../assets/images/why-bookkeeping-tax-firm.jpg';
import CheckIcon from '@material-ui/icons/Check';

/** Components ----------------------------------------------------------- */
import { makeStyles, Button, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Paper, Container } from '@material-ui/core';
import Banner from './submodules/Banner';
import FaqSection from './submodules/FaqSection';
import HowSection from './submodules/HowSection';
import SubBanner from './submodules/SubBanner';
import WhySection from './submodules/WhySection';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    rootBanner: {
        flexGrow: 1,
        paddingTop: 100,
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            background: '#2F3190'
        },
        [theme.breakpoints.up('lg')]: {
            background: 'linear-gradient(90deg, #2F3190 50%, #ffffff 50%)'
        },
    },
    bannerButton: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 60,
            fontSize: 18
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 150,
        },
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 60,
            fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 120,
        },
    },
    button2: {
        borderRadius: 50,
        paddingLeft: 60,
        paddingRight: 60,
        marginBottom: 35,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 60,
            fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 60,
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: 80,
        },
    },
    purpleBack: {
        [theme.breakpoints.down('md')]: {
            padding: '30px !important'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    mainBack2: {
        backgroundColor: '#F2F2F1',
        [theme.breakpoints.down('md')]: {
            margin: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: 70
        },
        [theme.breakpoints.up('xl')]: {
            margin: 120
        },
        borderRadius: 50
    },
    secondBack: {
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '60px 60px 0',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '80px 80px 0'
        },
    },
    secondBack2: {
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '30px 30px 80px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 60
        },
        [theme.breakpoints.up('xl')]: {
            padding: 80
        },
    },
    paper: {
        [theme.breakpoints.down('md')]: {
            padding: '30px !important',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '30px !important',
            marginTop: '-30px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '30px !important',
            marginTop: '-30px',
            width: 330,
        },
        borderRadius: 20,
    },
    firstT1: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        }
    },
    firstT3: {
        paddingTop: 100,
        [theme.breakpoints.up('lg')]: {
            height: 170,
        },
    },
    secondT: {
        paddingBottom: 30,
        [theme.breakpoints.up('lg')]: {
            height: 130,
        },
        [theme.breakpoints.up('xl')]: {
            height: 120,
        },
    },
    secondT2:{
        marginBottom: 50,
        [theme.breakpoints.up('lg')]: {
            height: 66,
        },
        [theme.breakpoints.up('xl')]: {
            height: 'auto',
        },
    },
    primaryItem: {
        width: '95%',
    },
    spacing: {
        [theme.breakpoints.down('md')]: {
            paddingTop: 30,
            paddingBottom: 20,
        },
        [theme.breakpoints.up('lg')]: {
            height: 70,
        },
    },
    spacing2: {
        paddingBottom: 15,
    },
}));

const Firms = () => {
    const classes = useStyles();
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    useEffect(() => {
        let checkBox = document.getElementById('firmD').childNodes;
        checkBox[0].innerHTML = t('firms.firmDedi');
    }, [t]);

    let buttonBookDemo = (
        <Button component={Link} to="/become-partner" variant="contained" color="secondary"
            disableElevation className={classes.bannerButton}
        >
            {t('banner.bookDemo')}
        </Button>
    );

    let buttonTalkSpecialist = (
        <Button component={Link} to="/become-partner" variant="contained" color="secondary" 
            disableElevation className={classes.button}
        >
            {t('firms.firmTalk')}
        </Button>
    );

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Banner
                firstT={t('firms.firmTax')}
                secondT={t('firms.firmClient')}
                secondT2={t('firms.firmPract')}
                thirdT={t('firms.firmDeliv')}
                button={buttonBookDemo}
                firstBack={classes.purpleBack}
                persona={BannerImgFirm}
                root={classes.rootBanner}
            />
            <SubBanner
                firstT={t('subBanner.subWhat')}
                secondT={t('firms.firmBank')}
                thirdT={t('firms.firmModel')}
                firstT2={t('subBanner.subCharge')}
                secondT2={t('firms.firmPublic')}
                thirdT2={t('firms.firmSolid')}
                firstT3={t('subBanner.subWhere')}
                secondT3={t('firms.firmAdapt')}
                thirdT3={t('firms.firmManage')}
                subBannerT2Class={classes.secondT2}
            />
            <HowSection
                firstMain={t('howSection.howWork')}
                firstT={t('firms.firmDeve')}
                secondT={t('firms.firmOnb')}
                thirdT={t('firms.firmBook')}
                firstT2={t('firms.firmClose')}
                secondT2={t('firms.firmWork')}
                thirdT2={t('firms.firmPass')}
                button={buttonTalkSpecialist}
            />
            <div className={classes.mainBack2}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2} className={classes.secondBack}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.firstT1}>{t('faq.faqPrice')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.secondBack2} spacing={4}>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.spacing} dangerouslySetInnerHTML={{ __html: t('header.submenuBooke') }}></Typography>
                            <List>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText id="firmD" primary="" classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('firms.firmCash')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('firms.firmMonth')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('firms.firmBal')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('firms.firmUnli')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.spacing}>{t('firms.firmDeli')}</Typography>
                            <List>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('firms.firmKey')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('firms.firmVirt')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Paper elevation={0} className={classes.paper}>
                                <Typography variant="h5" color="secondary" className={classes.spacing2} dangerouslySetInnerHTML={{ __html: t('firms.firmsBackOffice') }}></Typography>
                                <Typography variant="body1" className={classes.secondT} dangerouslySetInnerHTML={{ __html: t('firms.firmOri') }}></Typography>
                                <Typography variant="h6">{t('firms.firmStart')}</Typography>
                                <Typography variant="h5" className={classes.spacing2}>{t('firms.firmM')}</Typography>
                                <Typography variant="body1">{t('firms.firmExp')}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Paper elevation={0} className={classes.paper}>
                                <Typography variant="h5" color="secondary" className={classes.spacing2} dangerouslySetInnerHTML={{ __html: t('firms.firmsFullSer') }}></Typography>
                                <Typography variant="body1" className={classes.secondT}>{t('firms.firmGreat')}</Typography>
                                <Typography variant="h6">{t('firms.firmStart')}</Typography>
                                <Typography variant="h5" className={classes.spacing2}>{t('firms.firmM2')}</Typography>
                                <Typography variant="body1">{t('firms.firmExp')}</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button component={Link} to="/become-partner" variant="contained" color="secondary"
                                disableElevation className={classes.button2}
                            >
                                {t('firms.firmFree')}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <WhySection
                firstMain={t('firms.firmWhy')}
                secondT={t('firms.firmPlug')}
                thirdT={t('firms.firmLong')}
                secondT2={t('firms.firmTech')}
                thirdT2={t('firms.firmCom')}
                secondT3={t('firms.firmSpan')}
                thirdT3={t('firms.firmYou')}
                image={WhyTaxFirm}
                button={t('header.getStarted')}
                firstT3Class={classes.firstT3}
            />
            <FaqSection
                firstMain={t('faq.faq')}
                firstT={t('firms.firmFaq')}
                secondT={t('firms.firmOut')}
                thirdT={t('firms.firmSom')}
                firstT2={t('faq.faqPrice')}
                secondT2={t('firms.firmGCA')}
                thirdT2={t('faq.faqWork')}
                thirdT3={t('firms.firmReq')}
                fourth1={t('faq.faqSol')}
                fourth2={t('firms.firmSupp')}
                more={t('faq.faqMore')}
                section={'firm'}
            />
        </div>
    )
}

export default Firms;
