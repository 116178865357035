/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

/** Icons/images */
import { ReactComponent as DearLogo } from '../assets/images/logo-dear.svg';
import { ReactComponent as KiiperLogo } from '../assets/images/logo-kiiper.svg';
import { ReactComponent as QuickbooksLogo } from '../assets/images/logo-quickbooks.svg';
import { ReactComponent as XeroLogo } from '../assets/images/logo-xero.svg';
import { ReactComponent as TealcaLogo } from '../assets/images/logo-tealca.svg';
import { ReactComponent as ViderayLogo } from '../assets/images/logo-videray.svg';
import { ReactComponent as ZettaLogo } from '../assets/images/logo-zetta.svg';
import FirstPerson from '../assets/images/gca-home-01.png';
import FourthPerson from '../assets/images/gca-home-03.png';
import MainPerson from '../assets/images/gca-home-00.png';
import MainPersonMobile from '../assets/images/gca-isolated-bookkeeping-accounting2.png';
import SecGraph from '../assets/images/secondary-graphic-version-1.svg';
import SecondGraph from '../assets/images/secondary-graphic-version-2.svg';
import SecondPerson from '../assets/images/gca-home-02.png';
import ThirdPerson from '../assets/images/gca-home-05.png';
import HomeVideo from '../assets/videos/Animation-Home.mp4';
import HomeVideoMobile from '../assets/videos/Animation-Home-Mobile.mp4';
import HomeVideoES from '../assets/videos/Animation-Home-ESP.mp4';
import HomeVideoMobileES from '../assets/videos/Animation-Home-Mobile-ESP.mp4';

/** GSAP ----------------------------------------------------------- */
import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    commonCoral: {
        color: theme.palette.secondary.main,
    },
    commonWhite: {
        color: '#FFFFFF',
    },
    mobileText: {
        [theme.breakpoints.down('md')]: {
            fontSize: 22
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 30
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 40
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 60
        },
    },
    container: {
        width: '100%',
        height: '500vh',
        backgroundColor: '#031851',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    containerAnimations: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
    },
    personImg: {
        display: 'block',
        height: '100%',
        [theme.breakpoints.up('xs')]: {
            width: 250,
            margin: 'auto'
        },
        [theme.breakpoints.up('sm')]: {
            width: 350,
            margin: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 200,
            width: 400,
            float: 'right',
        },
    },
    personImgAnim: {
        position: 'absolute',
        bottom: 0,
    },
    mainPerson: {
        zIndex: 2,
        transformOrigin: '0% 100%',
        background: 'url(' + SecGraph + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'center',
        backgroundSize: 0,
        [theme.breakpoints.up('lg')]: {
            width: '33%',
        },
    },
    firstPerson: {
        zIndex: 1,
        [theme.breakpoints.up('lg')]: {
            width: '27%',
            left: '17%',
        },
    },
    secondPerson: {
        zIndex: 1,
        [theme.breakpoints.up('lg')]: {
            width: '27%',
            right: '18%',
        },
    },
    thirdPerson: {
        zIndex: 0,
        [theme.breakpoints.up('lg')]: {
            width: '23%',
            left: '5%',
        },
    },
    fourthPerson: {
        zIndex: 0,
        [theme.breakpoints.up('lg')]: {
            width: '23%',
            right: '5%',
        },
    },
    scrollDiv: {
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
        [theme.breakpoints.up('sm')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '50px 100px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '60px 120px',
        },
    },
    bgSection: {
        marginTop: 100,
        backgroundColor: '#2f3190',
        [theme.breakpoints.up('xs')]: {
            padding: '0px 30px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '0px 50px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0px 70px',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    bgSection2: {
        '& p': {
            lineHeight: 1.2
        },
        backgroundColor: '#031851',
        [theme.breakpoints.down('sm')]: {
            padding: '60px 30px !important'
        },
        [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 100px)',
            padding: '70px !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    bgSection3: {
        backgroundColor: '#2F3190',
        '& p': {
            lineHeight: 1.2
        },
        background: 'url(' + SecondGraph + ')',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 100px)',
            backgroundSize: 200,
            backgroundPosition: 'left 100px',
            padding: '60px 30px !important'
        },
        [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 100px)',
            backgroundSize: 250,
            backgroundPosition: 'left 90%',
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            backgroundSize: 300,
            backgroundPosition: 'left 90%',
            padding: '120px !important'
        },
    },
    bgSectionWhite: {
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]: {
            padding: '60px 30px !important'
        },
        [theme.breakpoints.up('md')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    bgSectionBlue: {
        '& h2': {
            lineHeight: 1.2
        },
        backgroundColor: theme.palette.text.primary,
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px !important'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    landingTextCont: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            padding: '100px 70px 0px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '100px 120px 0px',
        },
    },
    landingText1: {
        color: '#FFFFFF',
        [theme.breakpoints.up('xs')]: {
            paddingTop: 30,
            fontSize: 22,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 50,
            fontSize: 40,
        },
        [theme.breakpoints.up('lg')]: {
            width: 900,
            paddingTop: 100,
            fontSize: 60,
        },
        [theme.breakpoints.up('xl')]: {
            width: 1200,
            paddingTop: 120,
            fontSize: 80,
        },
    },
    landingText2: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 22
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 40
        },
        [theme.breakpoints.up('lg')]: {
            width: 800,
            fontSize: 60
        },
        [theme.breakpoints.up('xl')]: {
            width: 1100,
            fontSize: 80
        },
    },
    landingText3: {
        color: '#FFFFFF',
        [theme.breakpoints.up('xs')]: {
            paddingTop: 30,
            fontSize: 15,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 40,
            fontSize: 20
        },
        [theme.breakpoints.up('lg')]: {
            width: 400,
            paddingTop: 100,
            fontSize: 20,
        },
        [theme.breakpoints.up('xl')]: {
            width: 480,
            paddingTop: 120,
            fontSize: 25,
        },
    },
    thirdText: {
        color: '#FFFFFF',
        paddingTop: '30px',
        width: '95%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            paddingBottom: 50
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 30
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 40
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 60
        },
    },
    thirdText2: {
        color: '#FFFFFF',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '30px',
            paddingTop: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 25,
            paddingBottom: '30px',
            paddingTop: '50px',
        },
    },
    thirdText3: {
        color: '#FFFFFF',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '30px',
            paddingTop: '30px',
            fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 25,
            paddingBottom: '50px',
            paddingTop: '100px',
        },
    },
    thirdText4: {
        color: '#FFFFFF',
        paddingBottom: '50px',
        [theme.breakpoints.down('md')]: {
            fontSize: 22
        },
    },
    thirdText5: {
        color: '#FFFFFF',
        paddingBottom: '50px',
    },
    fourthText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 30
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 35
        },
    },
    thirdPanel: {
        [theme.breakpoints.down('sm')]: {
            borderTop: '3px solid #ff5968',
        },
        [theme.breakpoints.up('md')]: {
            borderLeft: '3px solid #ff5968',
            paddingLeft: '50px',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '100px',
        },
        backgroundColor: '#031851'
    },
    purpleBack: {
        backdropFilter: 'brightness(40%)',
    },
    carouselSection: {
        backgroundColor: '#F8F9F8',
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px !important'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    carouselPaper: {
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: 50,
        [theme.breakpoints.down('sm')]: {
            padding: '60px 30px !important',
        },
        [theme.breakpoints.up('md')]: {
            padding: '50px !important',
        },
    },
    carouselBrand: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down('sm')]: {
            width: 150,
            paddingBottom: 50,
        },
        [theme.breakpoints.up('md')]: {
            width: 180,
            paddingBottom: 70,
        },
    },
    carouselBrand2: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        [theme.breakpoints.down('sm')]: {
            width: 150,
            paddingBottom: 50,
        },
        [theme.breakpoints.up('md')]: {
            width: 180,
            paddingBottom: 70,
        },
    },
    carouselBrand3: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down('sm')]: {
            width: 220,
            paddingBottom: 50,
        },
        [theme.breakpoints.up('md')]: {
            width: 240,
            paddingBottom: 70,
        },
    },
    carouselBrandTitle: {
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
    },
    carouselBrandText: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            width: "85%",
        },
        [theme.breakpoints.up('md')]: {
            width: "70%",
        },
    },
    carouselDivider: {
        background: '#ff5968',
        width: 3,
        height: 350,
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
    },
    divider: {
        background: '#ff5968',
        width: '60%',
        height: 3,
    },
    divider2: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
        [theme.breakpoints.up('sm')]: {
            height: 500,
        },
        [theme.breakpoints.up('xl')]: {
            height: 650,
        },
        background: '#ff5968',
        width: 3,
        left: 0,
        right: 0,
        margin: 'auto'
    },
    videoSec: {
        height: '100%',
        '& #video': {
            display: 'block',
            width: '100%',
        },
    },
    svgXero: {
        [theme.breakpoints.down('sm')]: {
            height: 90
        },
        [theme.breakpoints.up('md')]: {
            height: 75
        },
        [theme.breakpoints.up('xl')]: {
            height: 90
        },
    },
    svg1: {
        [theme.breakpoints.down('sm')]: {
            height: 60
        },
        [theme.breakpoints.up('md')]: {
            height: 45
        },
        [theme.breakpoints.up('xl')]: {
            height: 60
        },
    },
    svg2: {
        [theme.breakpoints.down('sm')]: {
            height: 45
        },
        [theme.breakpoints.up('md')]: {
            height: 35
        },
        [theme.breakpoints.up('xl')]: {
            height: 45
        },
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.up('xs')]: {
            marginTop: 40,
            marginBottom: 60,
            fontSize: 15
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 40,
            marginBottom: 50,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 100,
            marginBottom: 100,
            fontSize: 20
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 120,
            marginBottom: 120,
            fontSize: 25
        },
    },
    button2: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
    },
    button3: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 15
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 30,
            fontSize: 20
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 70,
            fontSize: 20
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 120,
            fontSize: 25,
        },
    },
    spacingTest: {
        [theme.breakpoints.down('sm')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: 70,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120,
        },
    },
    spacingTextTest: {
        paddingBottom: 40
    },
}));

const Home = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component references */
    let containerRef = useRef(null);

    let animationsRef = useRef(null);

    let mainRef = useRef(null);

    let firstRef = useRef(null);

    let secondRef = useRef(null);

    let thirdRef = useRef(null);

    let fourthRef = useRef(null);

    let landingTextRef = useRef(null);

    let mainMobileRef = useRef(null);

    let landingTextMobileRef = useRef(null);

    let videoRef = useRef(null);

    let introVideoRef = useRef(null);

    const [video, setVideo] = useState(null);

    useEffect(() => {
        const w = window.matchMedia("(max-width: 600px)");
        let killed = false;
        videoRef.current.currentTime = 0;

        if (w.matches) {
            if (sessionStorage.getItem('lng') === 'es') {
                setVideo(HomeVideoMobileES);
            }
            else {
                setVideo(HomeVideoMobile);
            }
        }
        else {
            if (sessionStorage.getItem('lng') === 'es') {
                setVideo(HomeVideoES);
            }
            else {
                setVideo(HomeVideo);
            }
        }

        ScrollTrigger.matchMedia({
            // large
            "(min-width: 960px)": () => {
                if (!killed) {
                    gsap.from(mainMobileRef.current, { x: '100vw', duration: 1.5 });
                }
            },

            // small
            "(max-width: 959px)": () => {
                if (!killed) {
                    gsap.from(mainMobileRef.current, { x: '-100vw', duration: 1.5 });
                }
            },

            // all 
            "all": () => {
                if (!killed) {
                    gsap.from(landingTextMobileRef.current, { x: '-100vw', duration: 1.5 });

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: animationsRef.current,
                            pin: true,
                            start: 'top top',
                            end: '400% 0',
                            scrub: 2,
                        }
                    });
            
                    tl.to(thirdRef.current, { x: '25vh', opacity: 0, duration: 4 });
                    tl.to(fourthRef.current, { x: '-25vh', opacity: 0, duration: 4 }, '-=4');
                    tl.to(firstRef.current, { x: '25vh', opacity: 0, duration: 4 });
                    tl.to(secondRef.current, { x: '-25vh', opacity: 0, duration: 4 }, '-=4');
                    tl.to(containerRef.current, { backgroundColor: "#2F3190", duration: 1 });
                    tl.fromTo(mainRef.current, { scale: 1 }, { right: 0, backgroundSize: '95%', scale: 0.8, duration: 4 }, '-=1');
                    tl.to(landingTextRef.current, { display: 'block', duration: 4 });

                    ScrollTrigger.create({
                        trigger: introVideoRef.current,
                        start: 'top center',
                        end: 'bottom center',
                        onEnter: () => {
                            if (videoRef.current !== null) {
                                var playPromise = videoRef.current.play();

                                if (playPromise !== undefined) {
                                    playPromise.then(_ => {
                                        // Automatic playback started!
                                        // Show playing UI.
                                    })
                                        .catch(error => {
                                            // Auto-play was prevented
                                            // Show paused UI.
                                        });
                                }
                            }
                        },
                        onEnterBack: () => {
                            if (videoRef.current !== null) {
                                var playPromise = videoRef.current.play();

                                if (playPromise !== undefined) {
                                    playPromise.then(_ => {
                                        // Automatic playback started!
                                        // Show playing UI.
                                    })
                                        .catch(error => {
                                            // Auto-play was prevented
                                            // Show paused UI.
                                        });
                                }
                            }
                        },
                        onLeave: () => {
                            if (videoRef.current !== null) {
                                videoRef.current.pause();
                            }
                        },
                        onLeaveBack: () => {
                            if (videoRef.current !== null) {
                                videoRef.current.pause();
                            }
                        },
                    });
                }
            }
        });

        return () => {
            ScrollTrigger.getAll().forEach(t => t.kill());
            killed = true;
        };
    }, [t]);

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <div ref={containerRef} className={classes.container}>
                <div ref={animationsRef} className={classes.containerAnimations}>
                    <img ref={thirdRef} src={ThirdPerson} alt="third-person" className={`${classes.personImgAnim} ${classes.thirdPerson}`} />
                    <img ref={firstRef} src={FirstPerson} alt="first-person" className={`${classes.personImgAnim} ${classes.firstPerson}`} />
                    <img ref={mainRef} src={MainPerson} alt="main-person" className={`${classes.personImgAnim} ${classes.mainPerson}`} />
                    <img ref={secondRef} src={SecondPerson} alt="main-person" className={`${classes.personImgAnim} ${classes.secondPerson}`} />
                    <img ref={fourthRef} src={FourthPerson} alt="fourth-person" className={`${classes.personImgAnim} ${classes.fourthPerson}`} />
                    <Container maxWidth={false} ref={landingTextRef} className={classes.landingTextCont}>
                        <Typography variant="h1" align="left" className={classes.landingText1}>{t('home.homeTextP')}</Typography>
                        <Typography variant="h1" color="secondary" align="left" className={classes.landingText2}>
                                {t('home.homeTextS')}
                        </Typography>
                        <Typography align="left" className={classes.landingText3}>{t('home.financial')}</Typography>
                        <Button component={Link} to="/pricing" variant="contained" color="secondary" disableElevation className={classes.button}>
                            {t('home.button')}
                        </Button>
                    </Container>
                </div>
            </div>
            <div className={classes.bgSection}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container>
                        <Grid item xs={12} md={6} ref={landingTextMobileRef}>
                            <Typography variant="h1" className={classes.landingText1}>{t('home.homeTextP')}</Typography>
                            <Typography variant="h1" color="secondary" className={classes.landingText2}>{t('home.homeTextS')}</Typography>
                            <Typography className={classes.landingText3}>{t('home.financial')}</Typography>
                            <Button component={Link} to="/pricing" variant="contained" color="secondary" disableElevation className={classes.button}>
                                {t('home.button')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src={MainPersonMobile} alt="main-person-mobile" ref={mainMobileRef} className={classes.personImg} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div ref={introVideoRef} className={classes.videoSec}>
                <video ref={videoRef} id="video" src={video} muted loop />
            </div>
            <div className={classes.bgSection2}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} xl={7}>
                            <Typography variant="h2" className={classes.thirdText}>
                                {t('home.financialText')}
                                <span className={classes.commonCoral}> {t('home.accountingText')}</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} xl={5} className={classes.thirdPanel}>
                            <Typography className={classes.thirdText2} dangerouslySetInnerHTML={{ __html: t('home.clientsText') }}></Typography>
                            <Typography className={classes.thirdText3} dangerouslySetInnerHTML={{ __html: t('home.trustText') }}></Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.bgSection3}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2" className={ `${classes.commonWhite} ${classes.mobileText}` }>
                                {t('home.oneforText')}
                            </Typography>
                            <Typography variant="h2" color="secondary" className={classes.mobileText}>
                                {t('home.stagesText')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className="techDiv">
                            <div className={classes.purpleBack}>
                                <div className={classes.scrollDiv}>
                                    <Typography variant="h5" className={classes.thirdText4} dangerouslySetInnerHTML={{ __html: t('home.fineText') }}></Typography>
                                    <Typography variant="body1" className={classes.thirdText5} dangerouslySetInnerHTML={{ __html: t('home.topText') }}></Typography>
                                    <Button component={Link} to="/solutions/accounting" variant="contained" color="secondary"
                                        disableElevation className={classes.button2}
                                    >
                                        {t('home.guideDecisions')}
                                    </Button>
                                </div>
                                <Divider classes={{ root: classes.divider }} />
                                <div className={classes.scrollDiv}>
                                    <Typography variant="h5" className={classes.thirdText4} dangerouslySetInnerHTML={{ __html: t('home.ownText') }}></Typography>
                                    <Typography variant="body1" className={classes.thirdText5} dangerouslySetInnerHTML={{ __html: t('home.controllerText') }}>
                                    </Typography>
                                    <Button component={Link} to="/solutions/controller" variant="contained" color="secondary"
                                        disableElevation className={classes.button2}
                                    >
                                        {t('home.guideTeam')}
                                    </Button>
                                </div>
                                <Divider classes={{ root: classes.divider }} />
                                <div className={classes.scrollDiv}>
                                    <Typography variant="h5" className={classes.thirdText4} dangerouslySetInnerHTML={{ __html: t('home.wayText') }}></Typography>
                                    <Typography variant="body1" className={classes.thirdText5} dangerouslySetInnerHTML={{ __html: t('home.cfoText') }}>
                                    </Typography>
                                    <Button component={Link} to="/solutions/controller" variant="contained" color="secondary"
                                        disableElevation className={classes.button2}
                                    >
                                        {t('home.getStrategic')}
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.bgSectionWhite}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item xs={12} lg={8}>
                            <Typography variant="h4" color="textPrimary" className={classes.fourthText} 
                                dangerouslySetInnerHTML={{ __html: t('home.makesHappier') }}>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth="xl" disableGutters className={classes.carouselSection}>
                <Carousel showStatus={false} infiniteLoop={true} autoPlay={true} showThumbs={false}>
                    <Paper elevation={3} className={classes.carouselPaper}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={5}>
                                <ZettaLogo className={classes.carouselBrand} />
                                <Typography variant="body1" color="textPrimary" align="left" className={classes.carouselBrandText} 
                                    dangerouslySetInnerHTML={{ __html: t('home.zettaTest1') }}>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Divider classes={{ root: classes.carouselDivider }} orientation="vertical" />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className={classes.carouselBrandText}>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test1')}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" className={classes.spacingTextTest}>
                                        {t('home.zettaTest3')}
                                    </Typography>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test2')}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" className={classes.spacingTextTest}>
                                        {t('home.zettaTest5')}
                                    </Typography>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test3')}
                                    </Typography>
                                    <Typography variant="body1" color="secondary">
                                        {t('home.zettaTest7')}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={3} className={classes.carouselPaper}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={5}>
                                <TealcaLogo className={classes.carouselBrand2} />
                                <Typography variant="body1" color="textPrimary" align="left" className={classes.carouselBrandText} 
                                    dangerouslySetInnerHTML={{ __html: t('home.tealcaTest1') }}>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Divider classes={{ root: classes.carouselDivider }} orientation="vertical" />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className={classes.carouselBrandText}>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test1')}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" className={classes.spacingTextTest}>
                                        {t('home.tealcaTest2')}
                                    </Typography>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test2')}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" className={classes.spacingTextTest}>
                                        {t('home.tealcaTest3')}
                                    </Typography>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test3')}
                                    </Typography>
                                    <Typography variant="body1" color="secondary">
                                        {t('home.tealcaTest4')}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={3} className={classes.carouselPaper}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={5}>
                                <ViderayLogo className={classes.carouselBrand3} />
                                <Typography variant="body1" color="textPrimary" align="left" className={classes.carouselBrandText} 
                                    dangerouslySetInnerHTML={{ __html: t('home.viderayTest1') }}>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Divider classes={{ root: classes.carouselDivider }} orientation="vertical" />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div className={classes.carouselBrandText}>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test1')}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" className={classes.spacingTextTest}>
                                        {t('home.viderayTest2')}
                                    </Typography>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test2')}
                                    </Typography>
                                    <Typography variant="body1" color="textPrimary" className={classes.spacingTextTest}>
                                        {t('home.viderayTest3')}
                                    </Typography>
                                    <Typography variant="h5" color="textPrimary" className={classes.carouselBrandTitle}>
                                        {t('home.test3')}
                                    </Typography>
                                    <Typography variant="body1" color="secondary">
                                        {t('home.viderayTest4')}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Carousel>
            </Container>
            <div className={classes.bgSectionBlue}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h2" className={ `${classes.commonWhite} ${classes.mobileText}` }>
                                {t('home.ourSolutions')}
                            </Typography>
                            <Typography variant="h2" color="secondary" className={classes.mobileText}>
                                {t('home.bestApps')} <span style={{color: "#FFFFFF"}}>{t('home.bestApps2')}</span>
                            </Typography> 
                        </Grid>
                        <Grid item xs={12}>
                            <Button component={Link} to="/solutions/automation" variant="contained" color="secondary"
                                disableElevation className={classes.button3}
                            >
                                {t('home.automateBusiness')}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.bgSectionWhite}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={7} alignItems="center" align="center">
                        <Grid item xs={12} md={3}>
                            <XeroLogo className={classes.svgXero} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <QuickbooksLogo className={classes.svg1} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <KiiperLogo className={classes.svg1} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DearLogo className={classes.svg2} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
}

export default Home;