/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from 'react';
import { HashRouter, Route, Switch } from "react-router-dom";

/** Components ----------------------------------------------------------- */
import About from './components/About';
import Accounting from './components/Bookkeeping';
import Ambassadors from './components/forms/Ambassadors';
import Automation from './components/Automation';
import AutoQuote from './components/forms/AutoQuote';
import BookDemo from './components/forms/BookDemo';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Controller from './components/Controller';
import CustomerCupport from './components/Support';
import Error from './components/Error';
import Faq from './components/Faq';
import Firms from './components/Firms';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import More100k from './components/forms/More100k';
import Partners from './components/forms/Partners';
import Pricing from './components/Pricing';
import Reviews from './components/forms/Reviews';
import Quote from './components/Quote';
import ScrollToTop from "./components/scrollToTop";

import "moment/locale/es"

const App = () => {
    return (
        <HashRouter>
            <ScrollToTop />
            <Route render={({ location }) =>
                location.pathname !== "/quote" ? (
                    <Header />
                ) : (
                    null
                )
            } />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/solutions/accounting" component={Accounting} />
                <Route path="/solutions/controller" component={Controller} />
                <Route path="/solutions/automation" component={Automation} />
                <Route path="/solutions/firms" component={Firms} />
                <Route path="/pricing" component={Pricing} />
                <Route path="/company/about" component={About} />
                <Route path="/company/contact" component={Contact} />
                <Route path="/resources/faq" component={Faq} />
                <Route path="/resources/customer-support" component={CustomerCupport} />
                <Route path="/resources/blog" component={Blog} />
                <Route path="/tailored-solution" component={More100k} />
                <Route path="/book-demo" component={BookDemo} />
                <Route path="/become-ambassador" component={Ambassadors} />
                <Route path="/become-partner" component={Partners} />
                <Route path="/automation-quote" component={AutoQuote} />
                <Route path="/reviews" component={Reviews} />
                <Route path="/quote" component={Quote} />
                <Route path="*" component={Error} />
            </Switch>
            <Route render={({ location }) =>
                location.pathname !== "/quote" ? (
                    <Footer />
                ) : (
                    null
                )
            } />
        </HashRouter>
    );
}

export default App;