/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Icons/images */
import CheckIcon from "@material-ui/icons/Check";
import { ReactComponent as XeroLogo } from "../../assets/images/logo-xero.svg";
import { ReactComponent as KiiperLogo } from "../../assets/images/logo-kiiper.svg";

/** Components ----------------------------------------------------------- */
import {
    makeStyles,
    withStyles,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import BasicForm from "./BasicForm";
import Backdrop from "../Backdrop";
import calls from "../js/calls";

const CssTextField = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 50,
        marginBottom: 15,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 50,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
})(TextField);

const CssTextField2 = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 15,
        marginBottom: 10,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 10,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    commonBold: {
        fontWeight: 700,
    },
    paperCustom: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: "10px 10px 15px rgba(3,24,81,0.8)",
        [theme.breakpoints.down("xs")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "95%",
        },
        [theme.breakpoints.up("md")]: {
            borderRadius: 40,
            padding: "85px !important",
            width: "90%",
        },
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        marginTop: 70,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    input: {
        "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    marginLogo: {
        marginTop: 10,
        marginBottom: 10,
    },
    spacing: {
        padding: "20px 18.5px 35px !important",
    },
    spacing2: {
        [theme.breakpoints.down("md")]: {
            paddingBottom: 30,
        },
        [theme.breakpoints.up("lg")]: {
            paddingBottom: 35,
        },
        [theme.breakpoints.up("xl")]: {
            paddingBottom: 45,
        },
    },
    spacing3: {
        paddingTop: 40,
        paddingBottom: 20,
    },
}));

const BookDemo = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [name, setName] = useState("");

    const [last, setLast] = useState("");

    const [email, setEmail] = useState("");

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const [dialogMsg, setDialogMsg] = useState("");

    const [isError, setisError] = useState(false);

    const [onlineChkbox, setOnlineChkbox] = useState(false);

    const [billsChkbox, setBillsChkbox] = useState(false);

    const [bankChkbox, setBankChkbox] = useState(false);

    const [multiChkbox, setMultiChkbox] = useState(false);

    const [shortChkbox, setShortChkbox] = useState(false);

    const [mobileChkbox, setMobileChkbox] = useState(false);

    const [bankingChkbox, setBankingChkbox] = useState(false);

    const [taxesChkbox, setTaxesChkbox] = useState(false);

    const [loading, showLoading] = useState(false);

    const clearInputs = () => {
        setName("");
        setLast("");
        setEmail("");
        setMessage("");
        setOnlineChkbox(false);
        setBillsChkbox(false);
        setBankChkbox(false);
        setMultiChkbox(false);
        setShortChkbox(false);
        setMobileChkbox(false);
        setBankingChkbox(false);
        setTaxesChkbox(false);
        setisError(false);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeLast = (event) => {
        setLast(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleChangeOnlineChkbox = (event) => {
        setOnlineChkbox(event.target.checked);
    };

    const handleChangeBillsChkbox = (event) => {
        setBillsChkbox(event.target.checked);
    };

    const handleChangeBankChkbox = (event) => {
        setBankChkbox(event.target.checked);
    };

    const handleChangeMultiChkbox = (event) => {
        setMultiChkbox(event.target.checked);
    };

    const handleChangeShortChkbox = (event) => {
        setShortChkbox(event.target.checked);
    };

    const handleChangeMobileChkbox = (event) => {
        setMobileChkbox(event.target.checked);
    };

    const handleChangeBankingChkbox = (event) => {
        setBankingChkbox(event.target.checked);
    };

    const handleChangeTaxesChkbox = (event) => {
        setTaxesChkbox(event.target.checked);
    };

    const sendEmail = async () => {
        if (name.trim() && last.trim() && email.trim() && /.+@.+\..+/.test(email)) {
            showLoading(true);

            var param = {
                firstName: name,
                lastName: last,
                emailAddress: email,
                onlineInv: onlineChkbox,
                billsPay: billsChkbox,
                bankRec: bankChkbox,
                multCurr: multiChkbox,
                shortTerm: shortChkbox,
                mobileApp: mobileChkbox,
                kiiperBank: bankingChkbox,
                kiiperTax: taxesChkbox,
                message: message,
            };

            await calls
                .postQuote(param)
                .then((response) => {
                    if (response.data) {
                        setDialogMsg(t("forms.formGetQuoteWill"));
                        setOpen(true);
                        clearInputs();
                    } else {
                        setDialogMsg(t("forms.forError"));
                        setOpen(true);
                    }
                })
                .catch((err) => {
                    setDialogMsg(err.message);
                    setOpen(true);
                });

            showLoading(false);
        } else {
            setisError(true);
        }
    };

    useEffect(() => {}, [t]);

    let formBody = (
        <Paper elevation={3} className={classes.paperCustom}>
            <Typography variant="h5" color="secondary" className={classes.spacing2}>
                {t("contact.contDrop")}
            </Typography>
            <Grid container justifyContent="flex-start" spacing={2}>
                <Grid item xs={12} lg={6}>
                    <CssTextField
                        fullWidth
                        placeholder={t("bookkeeping.bookFirst")}
                        variant="outlined"
                        value={name}
                        onChange={handleChangeName}
                        error={isError && !name.trim()}
                        helperText={isError && !name.trim() ? t("forms.forReq") : ""}
                        InputProps={{
                            classes: { input: classes.input },
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CssTextField
                        fullWidth
                        placeholder={t("bookkeeping.bookLast")}
                        variant="outlined"
                        value={last}
                        onChange={handleChangeLast}
                        error={isError && !last.trim()}
                        helperText={isError && !last.trim() ? t("forms.forReq") : ""}
                        InputProps={{
                            classes: { input: classes.input },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField
                        fullWidth
                        placeholder={t("bookkeeping.bookEmail")}
                        variant="outlined"
                        onChange={handleChangeEmail}
                        value={email}
                        error={isError && (!email.trim() || !/.+@.+\..+/.test(email))}
                        helperText={
                            isError && !email.trim()
                                ? t("forms.forReq")
                                : isError && !/.+@.+\..+/.test(email)
                                ? t("error.enterEmail")
                                : ""
                        }
                        InputProps={{
                            classes: { input: classes.input },
                        }}
                    />
                </Grid>
                <Grid item container xs={12} lg={6} justifyContent="flex-start" className={classes.spacing}>
                    <Grid item xs={12}>
                        <XeroLogo height="60" />
                        <Typography variant="body1" color="textPrimary" className={classes.spacing3}>
                            {t("forms.formThe")}
                            <span className={classes.commonBold}> {t("forms.formXeroFeatures")} </span>
                            {t("forms.formInterested")}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#031851" }}
                                    checked={onlineChkbox}
                                    onChange={handleChangeOnlineChkbox}
                                />
                            }
                            label={t("automation.autoOnli")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#031851" }}
                                    checked={billsChkbox}
                                    onChange={handleChangeBillsChkbox}
                                />
                            }
                            label={t("automation.autoBills")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#031851" }}
                                    checked={bankChkbox}
                                    onChange={handleChangeBankChkbox}
                                />
                            }
                            label={t("automation.autoBank")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#031851" }}
                                    checked={multiChkbox}
                                    onChange={handleChangeMultiChkbox}
                                />
                            }
                            label={t("automation.autoMult")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#031851" }}
                                    checked={shortChkbox}
                                    onChange={handleChangeShortChkbox}
                                />
                            }
                            label={t("automation.autoShort")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#031851" }}
                                    checked={mobileChkbox}
                                    onChange={handleChangeMobileChkbox}
                                />
                            }
                            label={t("automation.autoMob")}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6} justifyContent="flex-start" className={classes.spacing}>
                    <Grid item xs={12}>
                        <KiiperLogo height="40" className={classes.marginLogo} />
                        <Typography variant="body1" color="textPrimary" className={classes.spacing3}>
                            {t("forms.formThe2")}
                            <span className={classes.commonBold}> {t("forms.formKiiperProducts")} </span>
                            {t("forms.formBusinessNeeds")}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#031851" }}
                                    checked={bankingChkbox}
                                    onChange={handleChangeBankingChkbox}
                                />
                            }
                            label={"Kiiper Banking"}
                        />
                        <List className={classes.spacing}>
                            <ListItem disableGutters alignItems="flex-start">
                                <ListItemIcon style={{ minWidth: 35 }}>
                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                </ListItemIcon>
                                <ListItemText primary={t("automation.autoState")} />
                            </ListItem>
                            <ListItem disableGutters alignItems="flex-start">
                                <ListItemIcon style={{ minWidth: 35 }}>
                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                </ListItemIcon>
                                <ListItemText primary={t("automation.autoMon")} />
                            </ListItem>
                            <ListItem disableGutters alignItems="flex-start">
                                <ListItemIcon style={{ minWidth: 35 }}>
                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                </ListItemIcon>
                                <ListItemText primary={t("automation.autoStat")} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ color: "#031851" }}
                                    checked={taxesChkbox}
                                    onChange={handleChangeTaxesChkbox}
                                />
                            }
                            label={"Kiiper Taxes"}
                        />
                        <List className={classes.spacing}>
                            <ListItem disableGutters alignItems="flex-start">
                                <ListItemIcon style={{ minWidth: 35 }}>
                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                </ListItemIcon>
                                <ListItemText primary={t("automation.autoWith")} />
                            </ListItem>
                            <ListItem disableGutters alignItems="flex-start">
                                <ListItemIcon style={{ minWidth: 35 }}>
                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                </ListItemIcon>
                                <ListItemText primary={t("automation.autoTax")} />
                            </ListItem>
                            <ListItem disableGutters alignItems="flex-start">
                                <ListItemIcon style={{ minWidth: 35 }}>
                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                </ListItemIcon>
                                <ListItemText primary={t("automation.autoRep")} />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CssTextField2
                        fullWidth
                        placeholder={t("forms.formGiveQuote")}
                        variant="outlined"
                        multiline
                        rows={4}
                        value={message}
                        onChange={handleChangeMessage}
                        InputProps={{
                            classes: { input: classes.input },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        className={classes.button}
                        onClick={sendEmail}
                    >
                        {t("automation.autoGet")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <BasicForm
                name={"autoQuote"}
                firstT={t("forms.formAutoQuoteTitle")}
                secondT={t("forms.formLetsDigitize")}
                secondT2={t("forms.formBusinessManagement")}
                thirdT={t("forms.formDesignSolutions")}
                formFields={formBody}
                open={open}
                onChangeOpen={(value) => setOpen(value)}
                dialogMsg={dialogMsg}
                dialogMsg2={t("forms.formIamAvailable")}
            />
            <Backdrop open={loading} />
        </div>
    );
};

export default BookDemo;
