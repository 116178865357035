/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** Icons/images */
import BannerImgBook from "../assets/images/gca-isolated-bookkeeping-accounting.png";
import WhyBookkeeping from "../assets/images/why-accountig.jpg";
import GeraldineCallesImg from "../assets/images/our-team-geraldine-calles.jpg";

/** Components ----------------------------------------------------------- */
import { makeStyles, withStyles, Button, Container, Grid, Paper, TextField, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Banner from "./submodules/Banner";
import SubBanner from "./submodules/SubBanner";
import WhySection from "./submodules/WhySection";
import HowSection from "./submodules/HowSection";
import EsphereSection from "./submodules/EsphereSection";
import FaqSection from "./submodules/FaqSection";
import Backdrop from "./Backdrop";
import calls from "./js/calls";

const CssTextField = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 50,
        marginBottom: 15,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 50,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
})(TextField);

const CssTextField2 = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 15,
        marginBottom: 10,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 10,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    root2: {
        flexGrow: 1,
        [theme.breakpoints.down("md")]: {
            background: "#031851",
        },
        [theme.breakpoints.up("lg")]: {
            background: "linear-gradient(90deg, #031851 50%, #F8F9F8 50%)",
        },
    },
    rootBanner: {
        flexGrow: 1,
        paddingTop: 100,
        "& p": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.down("md")]: {
            background: "#2F3190",
        },
        [theme.breakpoints.up("lg")]: {
            background: "linear-gradient(90deg, #2F3190 50%, #031851 50%)",
        },
    },
    bannerButton: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        "&:hover": {
            backgroundColor: "#fff",
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down("md")]: {
            marginTop: 60,
            fontSize: 18,
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: 150,
        },
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        "&:hover": {
            backgroundColor: "#fff",
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down("md")]: {
            marginTop: 60,
            fontSize: 18,
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: 120,
        },
    },
    button2: {
        borderRadius: "50px",
        paddingLeft: "40px",
        paddingRight: "40px",
        marginTop: 70,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    purpleBack: {
        [theme.breakpoints.down("md")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "70px !important",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "120px !important",
        },
    },
    mainBack2: {
        "& p": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px 0px !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "70px !important",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "120px !important",
        },
    },
    secondBack: {
        "& p": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.down("md")]: {
            padding: "0px 30px 60px !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "47px 70px !important",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "80px 120px !important",
        },
    },
    firstT: {
        [theme.breakpoints.down("lg")]: {
            fontSize: 40,
        },
        color: "#FFFFFF",
    },
    firstT2: {
        [theme.breakpoints.down("lg")]: {
            fontSize: 40,
        },
    },
    firstT3: {
        paddingTop: 100,
        [theme.breakpoints.up("lg")]: {
            height: 170,
        },
    },
    secondT: {
        paddingTop: 120,
    },
    secondT2: {
        marginBottom: 50,
    },
    thirdT: {
        color: "#FFFFFF",
        paddingTop: 20,
        paddingBottom: 100,
        [theme.breakpoints.down("md")]: {
            width: "auto",
        },
        [theme.breakpoints.up("lg")]: {
            width: 450,
        },
    },
    paperCustom: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
        [theme.breakpoints.down("xs")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "70%",
        },
        [theme.breakpoints.up("md")]: {
            borderRadius: 40,
        },
        [theme.breakpoints.up("lg")]: {
            padding: "55px !important",
            width: "100%",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "75px !important",
        },
    },
    spacing: {
        [theme.breakpoints.down("md")]: {
            paddingBottom: 30,
        },
        [theme.breakpoints.up("lg")]: {
            paddingBottom: 35,
        },
        [theme.breakpoints.up("xl")]: {
            paddingBottom: 45,
        },
    },
    spacing2: {
        padding: "30px 0px",
    },
    input: {
        "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    dialogRoot: {
        [theme.breakpoints.down("md")]: {
            borderRadius: 20,
        },
        [theme.breakpoints.up("lg")]: {
            borderRadius: 50,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("md")]: {
            padding: "60px !important",
        },
    },
}));

const Bookkeeping = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    moment.locale(t("bookkeeping.dateFormat"));

    /** Component states */
    const [date, handleDateChange] = useState(moment());

    const [name, setName] = useState("");

    const [last, setLast] = useState("");

    const [email, setEmail] = useState("");

    const [phone, setPhone] = useState("");

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const [alertMess, setAlertMess] = useState("");

    const [isError, setisError] = useState(false);

    const [loading, showLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeLast = (event) => {
        setLast(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };
    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const clearInputs = () => {
        handleDateChange(moment());
        setName("");
        setLast("");
        setEmail("");
        setPhone("");
        setMessage("");
        setisError(false);
    };

    const sendEmail = async () => {
        if (name.trim() && last.trim() && email.trim() && /.+@.+\..+/.test(email)) {
            showLoading(true);

            const templateParams = {
                firstName: name,
                lastName: last,
                emailAddress: email,
                phoneNumber: phone,
                date: moment(date).format("DD/MM/YYYY"),
                message: message,
            };

            await calls
                .postBook(templateParams)
                .then((response) => {
                    if (response.data) {
                        setAlertMess(t("forms.formBookTalkSoon"));
                        setOpen(true);
                        clearInputs();
                    } else {
                        setAlertMess(t("forms.forError"));
                        setOpen(true);
                    }
                })
                .catch((err) => {
                    setAlertMess(err.message);
                    setOpen(true);
                });

            showLoading(false);
        } else {
            setisError(true);
        }
    };

    useEffect(() => {}, [t]);

    let buttonBookDemo = (
        <Button
            component={Link}
            to="/book-demo"
            variant="contained"
            color="secondary"
            disableElevation
            className={classes.bannerButton}
        >
            {t("banner.bookDemo")}
        </Button>
    );

    let buttonPersonalizeService = (
        <Button
            component={Link}
            to="/pricing"
            variant="contained"
            color="secondary"
            disableElevation
            className={classes.button}
        >
            {t("howSection.howServ")}
        </Button>
    );

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Banner
                firstT={t("header.submenuAcc")}
                secondT={t("banner.accuBook")}
                secondT2={t("banner.insightBook")}
                thirdT={t("banner.expertBook")}
                button={buttonBookDemo}
                firstBack={classes.purpleBack}
                persona={BannerImgBook}
                root={classes.rootBanner}
            />
            <SubBanner
                firstT={t("subBanner.subWhat")}
                secondT={t("subBanner.subBook")}
                thirdT={t("subBanner.subTax")}
                firstT2={t("subBanner.subCharge")}
                secondT2={t("subBanner.subCPA")}
                thirdT2={t("subBanner.subPlan")}
                firstT3={t("subBanner.subWhere")}
                secondT3={t("subBanner.subAdapt")}
                thirdT3={t("subBanner.subXero")}
                subBannerT2Class={classes.secondT2}
            />
            <WhySection
                firstMain={t("whySection.whyMain")}
                firstT={t("whySection.whyStop")}
                secondT={t("whySection.whyFull")}
                thirdT={t("whySection.whyExpert")}
                firstT2={t("whySection.whyPow")}
                secondT2={t("whySection.whyTech")}
                thirdT2={t("whySection.whyBook")}
                firstT3={t("whySection.whyBuilt")}
                secondT3={t("whySection.whyFormer")}
                thirdT3={t("whySection.whySteps")}
                image={WhyBookkeeping}
                button={t("header.getStarted")}
                firstT3Class={classes.firstT3}
            />
            <HowSection
                firstMain={t("howSection.howWork")}
                firstT={t("howSection.howCustom")}
                secondT={t("howSection.howBus")}
                thirdT={t("howSection.howReport")}
                firstT2={t("howSection.howPlan")}
                secondT2={t("howSection.howApp")}
                thirdT2={t("howSection.howClear")}
                button={buttonPersonalizeService}
            />
            <EsphereSection
                firstMain={t("esphereSection.esphereShap")}
                firstT={t("esphereSection.esphereExp")}
                secondT={t("esphereSection.esphereTech")}
                thirdT={t("esphereSection.esphereSoft")}
                firstT2={t("esphereSection.esphereCons")}
                secondT2={t("esphereSection.esphereMan")}
                thirdT2={t("esphereSection.espherePro")}
                thirdT3={t("esphereSection.esphereAcc")}
            />
            <div className={classes.root2}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container>
                        <Grid item xs={12} lg={6} className={classes.mainBack2}>
                            <Typography variant="h3" className={classes.firstT}>
                                {t("bookkeeping.bookWe")}
                            </Typography>
                            <Typography variant="h3" color="secondary" className={classes.firstT2}>
                                {t("bookkeeping.bookInd")}
                            </Typography>
                            <Typography variant="h3" className={classes.firstT}>
                                {t("bookkeeping.bookCase")}
                            </Typography>
                            <Typography variant="h3" className={classes.firstT}>
                                {t("bookkeeping.bookTeam")}
                            </Typography>
                            <Typography variant="h5" color="secondary" className={classes.secondT}>
                                {t("bookkeeping.bookFinan")}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.thirdT}
                                dangerouslySetInnerHTML={{ __html: t("bookkeeping.bookPrep") }}
                            ></Typography>
                            <Typography variant="h5" color="secondary">
                                {t("bookkeeping.bookCPA")}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.thirdT}
                                dangerouslySetInnerHTML={{ __html: t("bookkeeping.bookLim") }}
                            ></Typography>
                        </Grid>
                        <Grid item xs={12} lg={6} className={classes.secondBack}>
                            <Paper elevation={3} className={classes.paperCustom}>
                                <Typography variant="h5" color="secondary" className={classes.spacing}>
                                    {t("bookkeeping.bookMeet")}
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder={t("bookkeeping.bookFirst")}
                                    value={name}
                                    onChange={handleChangeName}
                                    error={isError && !name.trim()}
                                    helperText={isError && !name.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookLast")}
                                    variant="outlined"
                                    value={last}
                                    onChange={handleChangeLast}
                                    error={isError && !last.trim()}
                                    helperText={isError && !last.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookEmail")}
                                    variant="outlined"
                                    value={email}
                                    onChange={handleChangeEmail}
                                    error={isError && (!email.trim() || !/.+@.+\..+/.test(email))}
                                    helperText={
                                        isError && !email.trim()
                                            ? t("forms.forReq")
                                            : isError && !/.+@.+\..+/.test(email)
                                            ? t("error.enterEmail")
                                            : ""
                                    }
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookPhone")}
                                    variant="outlined"
                                    value={phone}
                                    onChange={handleChangePhone}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <Typography variant="h6" color="secondary" className={classes.spacing2}>
                                    {t("bookkeeping.bookSche")}
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    variant="outlined"
                                    value={moment(date).format(t("bookkeeping.dateFormatText"))}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <div style={{ overflow: "hidden" }}>
                                    <MuiPickersUtilsProvider
                                        libInstance={moment}
                                        utils={MomentUtils}
                                        locale={t("bookkeeping.dateFormat")}
                                    >
                                        <Calendar date={date} onChange={handleDateChange} />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <CssTextField2
                                    fullWidth
                                    placeholder={t("bookkeeping.bookMess")}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={handleChangeMessage}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.button2}
                                            onClick={sendEmail}
                                        >
                                            {t("bookkeeping.bookBook")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <FaqSection
                firstMain={t("faq.faq")}
                firstT={t("faq.faqBook")}
                secondT={t("faq.faqBookQ3")}
                thirdT={t("faq.faqServ")}
                firstT2={t("faq.faqPrice")}
                secondT2={t("faq.faqServi")}
                thirdT2={t("faq.faqWork")}
                thirdT3={t("faq.faqDur")}
                fourth1={t("faq.faqSupp")}
                fourth2={t("faq.faqUnli")}
                more={t("faq.faqMore")}
                section={"book"}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
                fullWidth={true}
                classes={{ paper: classes.dialogRoot }}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12} lg={2}>
                                <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Typography variant="h6" color="textPrimary">
                                    {t("forms.formHi")}
                                    <span className={classes.commonCoral}> Geraldine </span>
                                </Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogFirstT}>
                                    {alertMess}
                                </Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogSecondT}>
                                    {t("forms.formWeAreHappy")}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {t("forms.formIamAvailable")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Backdrop open={loading} />
        </div>
    );
};

export default Bookkeeping;
