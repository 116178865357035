/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Icons/images */
import Background from "../assets/images/faq-more-questions.jpg";
import BannerImgFaq from "../assets/images/gca-isolated-faq.png";
import Chip from "@material-ui/core/Chip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GeraldineCallesImg from "../assets/images/our-team-geraldine-calles.jpg";

/** Components ----------------------------------------------------------- */
import { makeStyles, withStyles, Button, Container, Grid, Paper, TextField, Typography } from "@material-ui/core";
import Banner from "./submodules/Banner";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Backdrop from "./Backdrop";
import calls from "./js/calls";

const Accordion = withStyles({
    root: {
        borderBottom: "3px solid #ff5968",
        borderTop: "3px solid #ff5968",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:first-child": {
            borderTop: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        padding: "50px 0px",
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: "0px 0px 80px 0px",
    },
}))(MuiAccordionDetails);

const CssTextField = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 50,
        marginBottom: 15,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 50,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
})(TextField);

const CssTextField2 = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 15,
        marginBottom: 10,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 10,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "white",
    },
    root2: {
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px 0",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "70px 70px 0",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "120px 120px 0",
        },
    },
    root3: {
        "& p": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.down("md")]: {
            padding: "30px 30px 60px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "70px 70px 120px",
        },
        [theme.breakpoints.up("xl")]: {
            padding: 120,
        },
    },
    rootBanner: {
        flexGrow: 1,
        paddingTop: 100,
        "& p": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.down("md")]: {
            background: "#2F3190",
        },
        [theme.breakpoints.up("lg")]: {
            background: "linear-gradient(90deg, #2F3190 50%, #ffffff 50%)",
        },
    },
    purpleBack: {
        [theme.breakpoints.down("md")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "70px !important",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "120px !important",
        },
    },
    mainBack2: {
        background: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(" + Background + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    chip: {
        backgroundColor: "#031851",
        color: "white",
        borderRadius: 50,
        fontWeight: "bold",
        [theme.breakpoints.down("md")]: {
            marginTop: 30,
            fontSize: 15,
            padding: 25,
            width: 300,
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 15,
            padding: 25,
            width: 290,
        },
        [theme.breakpoints.up("xl")]: {
            fontSize: 20,
            width: 400,
        },
        "&:hover": {
            backgroundColor: "#4F5D85",
        },
        "&:focus": {
            backgroundColor: "#031851",
        },
    },
    notChip: {
        backgroundColor: "#031851",
        color: "white",
        borderRadius: 50,
        fontWeight: "bold",
        opacity: 0.5,
        [theme.breakpoints.down("md")]: {
            marginTop: 30,
            fontSize: 15,
            padding: 25,
            width: 300,
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 15,
            padding: 25,
            width: 290,
        },
        [theme.breakpoints.up("xl")]: {
            fontSize: 20,
            width: 400,
        },
        "&:hover": {
            backgroundColor: "#4F5D85",
        },
        "&:focus": {
            backgroundColor: "#031851",
        },
    },
    firstBack2: {
        paddingTop: "80px !important",
    },
    firstT: {
        [theme.breakpoints.down("lg")]: {
            fontSize: 40,
        },
        color: "#F8F9F8",
    },
    button2: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        marginTop: 70,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    paperCustom: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: "10px 10px 15px rgba(2,12,15,0.60)",
        [theme.breakpoints.down("xs")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "70%",
        },
        [theme.breakpoints.up("md")]: {
            borderRadius: 40,
            padding: "55px 30px !important",
            width: "45%",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "75px !important",
        },
        [theme.breakpoints.up("xl")]: {
            width: "40%",
        },
    },
    firstBack: {
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px 0 !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "120px 70px 0 !important",
        },
    },
    secondBack: {
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px 60px !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "90px 70px 120px !important",
        },
    },
    input: {
        "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    spacing: {
        [theme.breakpoints.down("md")]: {
            paddingBottom: 30,
        },
        [theme.breakpoints.up("lg")]: {
            paddingBottom: 35,
        },
        [theme.breakpoints.up("xl")]: {
            paddingBottom: 45,
        },
    },
    dialogRoot: {
        [theme.breakpoints.down("md")]: {
            borderRadius: 20,
        },
        [theme.breakpoints.up("lg")]: {
            borderRadius: 50,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("md")]: {
            padding: "60px !important",
        },
    },
}));

const Faq = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [bookkeeping, setBook] = useState(sessionStorage.getItem("section") === "book" ? true : false);

    const [controller, setCont] = useState(sessionStorage.getItem("section") === "contr" ? true : false);

    const [automation, setAuto] = useState(sessionStorage.getItem("section") === "auto" ? true : false);

    const [firms, setFirms] = useState(sessionStorage.getItem("section") === "firm" ? true : false);

    const [expanded, setExpanded] = useState("panel1");

    const [expanded2, setExpanded2] = useState("panelC1");

    const [expanded3, setExpanded3] = useState("panelA1");

    const [expanded4, setExpanded4] = useState("panelF1");

    const [name, setName] = useState("");

    const [last, setLast] = useState("");

    const [email, setEmail] = useState("");

    const [phone, setPhone] = useState("");

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const [alertMess, setAlertMess] = useState("");

    const [isError, setisError] = useState(false);

    const [loading, showLoading] = useState(false);

    const clearInputs = () => {
        setName("");
        setLast("");
        setEmail("");
        setPhone("");
        setMessage("");
        setisError(false);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleChange2 = (panel) => (event, isExpanded) => {
        setExpanded2(isExpanded ? panel : false);
    };

    const handleChange3 = (panel) => (event, isExpanded) => {
        setExpanded3(isExpanded ? panel : false);
    };

    const handleChange4 = (panel) => (event, isExpanded) => {
        setExpanded4(isExpanded ? panel : false);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeLast = (event) => {
        setLast(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };
    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sendEmail = async () => {
        if (name.trim() && last.trim() && email.trim() && /.+@.+\..+/.test(email)) {
            showLoading(true);

            const param = {
                firstName: name,
                lastName: last,
                emailAddress: email,
                phoneNumber: phone,
                message: message,
            };

            await calls
                .postContact(param)
                .then((response) => {
                    if (response.data) {
                        setAlertMess(t("forms.forMail"));
                        setOpen(true);
                        clearInputs();
                    } else {
                        setAlertMess(t("forms.forError"));
                        setOpen(true);
                    }
                })
                .catch((err) => {
                    setAlertMess(err.error);
                    setOpen(true);
                });

            showLoading(false);
        } else {
            setisError(true);
        }
    };

    const handleClick = (val) => {
        setBook(false);
        setCont(false);
        setAuto(false);
        setFirms(false);
        switch (val) {
            case 1:
                setBook(true);
                setExpanded2("panelC1");
                setExpanded3("panelA1");
                setExpanded4("panelF1");
                break;
            case 2:
                setCont(true);
                setExpanded("panel1");
                setExpanded3("panelA1");
                setExpanded4("panelF1");
                break;
            case 3:
                setAuto(true);
                setExpanded("panel1");
                setExpanded2("panelC1");
                setExpanded4("panelF1");
                break;
            case 4:
                setFirms(true);
                setExpanded("panel1");
                setExpanded2("panelC1");
                setExpanded3("panelA1");
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        sessionStorage.setItem("section", "book");

        let chipL = document.getElementById("chipL").childNodes;
        chipL[0].innerHTML = t("header.submenuAcc");

        let chipCFO = document.getElementById("chipCFO").childNodes;
        chipCFO[0].innerHTML = t("header.submenuCFO");

        let chipF = document.getElementById("chipFirms").childNodes;
        chipF[0].innerHTML = t("header.submenuFirms");
    }, [t]);

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Banner
                firstT={t("faq.faq")}
                secondT={t("faq.faqDo")}
                secondT2={t("faq.faqOur")}
                thirdT={t("faq.faqWe")}
                firstBack={classes.purpleBack}
                persona={BannerImgFaq}
                root={classes.rootBanner}
            />
            <div className={classes.root2}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container justifyContent="center" align="center" spacing={4}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Chip
                                id="chipL"
                                className={bookkeeping === true ? classes.chip : classes.notChip}
                                onClick={() => handleClick(1)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Chip
                                id="chipCFO"
                                className={controller === true ? classes.chip : classes.notChip}
                                onClick={() => handleClick(2)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Chip
                                className={automation === true ? classes.chip : classes.notChip}
                                label={t("header.submenuProcess")}
                                onClick={() => handleClick(3)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Chip
                                id="chipFirms"
                                className={firms === true ? classes.chip : classes.notChip}
                                onClick={() => handleClick(4)}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.root3}>
                <Container maxWidth="xl" disableGutters>
                    {bookkeeping === true ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookQ1") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookA1") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography variant="h5">{t("faq.faqBookQ2")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookA2") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookQ3") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookA3") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                    >
                                        <Typography variant="h5">{t("faq.faqPrice")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqServi") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6a-content"
                                        id="panel6a-header"
                                    >
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookQ5") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookA5") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel7a-content"
                                        id="panel7a-header"
                                    >
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqWork") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqDur") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8a-content"
                                        id="panel8a-header"
                                    >
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookQ6") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookA6") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel9a-content"
                                        id="panel9a-header"
                                    >
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookQ7") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookA7") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel10"} onChange={handleChange("panel10")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookQ12") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookA12") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel11"} onChange={handleChange("panel11")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel11a-content"
                                        id="panel11a-header"
                                    >
                                        <Typography variant="h5">{t("faq.faqBookQ9")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqBookA9")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel12"} onChange={handleChange("panel12")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel12a-content"
                                        id="panel12a-header"
                                    >
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookQ10") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqBookA10") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === "panel13"} onChange={handleChange("panel13")}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel13a-content"
                                        id="panel13a-header"
                                    >
                                        <Typography variant="h5">{t("faq.faqBookQ11")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqBookA11")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    ) : controller === true ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded2 === "panelC1"} onChange={handleChange2("panelC1")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ1") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA1") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC2"} onChange={handleChange2("panelC2")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ2") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA2") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC3"} onChange={handleChange2("panelC3")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ3") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA3") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC4"} onChange={handleChange2("panelC4")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ4") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA4") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC5"} onChange={handleChange2("panelC5")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ5") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA5") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC6"} onChange={handleChange2("panelC6")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqPrice")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("controller.contBased") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC8"} onChange={handleChange2("panelC8")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqControlQ7")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA7") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC9"} onChange={handleChange2("panelC9")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqControlQ8")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA8") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC10"} onChange={handleChange2("panelC10")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ9") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA9") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC11"} onChange={handleChange2("panelC11")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqBookQ9")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA10") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC12"} onChange={handleChange2("panelC12")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ11") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA11") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC15"} onChange={handleChange2("panelC15")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ13") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA13") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC13"} onChange={handleChange2("panelC13")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlQ10") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqControlA12") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded2 === "panelC14"} onChange={handleChange2("panelC14")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqBookQ11")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqBookA11")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    ) : automation === true ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded3 === "panelA1"} onChange={handleChange3("panelA1")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqAutoQ1")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqAutoA1")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA2"} onChange={handleChange3("panelA2")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqAutoQ2")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqAutoA2")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA3"} onChange={handleChange3("panelA3")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqAutoQ3")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqAutoA3")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA4"} onChange={handleChange3("panelA4")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqAutoQ4")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqAutoA4")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA5"} onChange={handleChange3("panelA5")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqHow")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqAutoA5")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA6"} onChange={handleChange3("panelA6")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqAutoQ6")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqAutoA6")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA7"} onChange={handleChange3("panelA7")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqAutoQ7")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqAutoA7")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA8"} onChange={handleChange3("panelA8")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqPrice")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqXero")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA9"} onChange={handleChange3("panelA9")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqWhat")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqD")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA10"} onChange={handleChange3("panelA10")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqAutoQ8")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqAutoA8")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded3 === "panelA11"} onChange={handleChange3("panelA11")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqSu")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqImp")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    ) : firms === true ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded4 === "panelF1"} onChange={handleChange4("panelF1")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmQ1") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA1") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF2"} onChange={handleChange4("panelF2")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqAutoQ2")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA2") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF3"} onChange={handleChange4("panelF3")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmQ3") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA3") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF4"} onChange={handleChange4("panelF4")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqFirmQ4")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA4") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF5"} onChange={handleChange4("panelF5")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqFirmQ5")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA5") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF6"} onChange={handleChange4("panelF6")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmQ6") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA6") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF7"} onChange={handleChange4("panelF7")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqFirmQ7")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA7") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF8"} onChange={handleChange4("panelF8")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqPrice")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA8") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF9"} onChange={handleChange4("panelF9")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA15") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("firms.firmReq")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF10"} onChange={handleChange4("panelF10")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmQ9") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA9") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF11"} onChange={handleChange4("panelF11")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmQ10") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA10") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF12"} onChange={handleChange4("panelF12")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqSol")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA11") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF13"} onChange={handleChange4("panelF13")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmQ12") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA12") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF14"} onChange={handleChange4("panelF14")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmQ13") }}
                                        ></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: t("faq.faqFirmA13") }}
                                        ></Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded4 === "panelF15"} onChange={handleChange4("panelF15")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5">{t("faq.faqFirmQ14")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1">{t("faq.faqFirmA14")}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    ) : null}
                </Container>
            </div>
            <div className={classes.mainBack2}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container justifyContent="center" className={classes.firstBack}>
                        <Grid item>
                            <Typography variant="h3" className={classes.firstT}>
                                {t("faq.faqQuest")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.secondBack}>
                        <Grid item>
                            <Paper elevation={3} className={classes.paperCustom}>
                                <Typography variant="h5" color="secondary" className={classes.spacing}>
                                    {t("contact.contDrop")}
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookFirst")}
                                    variant="outlined"
                                    value={name}
                                    onChange={handleChangeName}
                                    error={isError && !name.trim()}
                                    helperText={isError && !name.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookLast")}
                                    variant="outlined"
                                    value={last}
                                    onChange={handleChangeLast}
                                    error={isError && !last.trim()}
                                    helperText={isError && !last.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookEmail")}
                                    variant="outlined"
                                    onChange={handleChangeEmail}
                                    value={email}
                                    error={isError && (!email.trim() || !/.+@.+\..+/.test(email))}
                                    helperText={
                                        isError && !email.trim()
                                            ? t("forms.forReq")
                                            : isError && !/.+@.+\..+/.test(email)
                                            ? t("error.enterEmail")
                                            : ""
                                    }
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookPhone")}
                                    variant="outlined"
                                    className={classes.spacing2}
                                    value={phone}
                                    onChange={handleChangePhone}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField2
                                    fullWidth
                                    placeholder={t("faq.faqQuestion")}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={handleChangeMessage}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.button2}
                                            onClick={sendEmail}
                                        >
                                            {t("contact.contSend")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
                fullWidth={true}
                classes={{ paper: classes.dialogRoot }}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12} lg={2}>
                                <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Typography variant="h6" color="textPrimary">
                                    {t("forms.formHi")}
                                    <span className={classes.commonCoral}> Geraldine </span>
                                </Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogFirstT}>
                                    {alertMess}
                                </Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogSecondT}>
                                    {t("forms.formWeAreHappy")}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {t("forms.formIamAvailable")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Backdrop open={loading} />
        </div>
    );
};

export default Faq;
