/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from 'react';

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Hidden, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#2F3190',
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120
        },
    },
    commonWhite: {
        color: 'white',
    },
    firstT: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40
        },
        paddingBottom: 70,
        color: '#FFFFFF',
    },
    secondT: {
        color: '#FFFFFF',
        [theme.breakpoints.down('md')]: {
            padding: '30px 35px 60px',
        },
        [theme.breakpoints.up('lg')]: {
            width: 350,
            paddingTop: 60,
            paddingLeft: 35,
        },
        [theme.breakpoints.up('xl')]: {
            width: 400,
        },
    },
    secondT2: {
        color: '#FFFFFF',
        [theme.breakpoints.down('md')]: {
            padding: '30px 35px 60px',
        },
        [theme.breakpoints.up('lg')]: {
            width: 320,
            paddingTop: 60,
            marginLeft: -10,
        },
        [theme.breakpoints.up('xl')]: {
            width: 420,
        },
    },
    secondT3: {
        color: '#FFFFFF',
        [theme.breakpoints.down('md')]: {
            padding: '30px 35px 0',
        },
        [theme.breakpoints.up('lg')]: {
            width: 320,
            paddingTop: 60,
            marginLeft: -10,
        },
        [theme.breakpoints.up('xl')]: {
            width: 420,
        },
    },
    greyBack1: {
        backgroundColor: '#B3BACB',
        borderRadius: 50,
        padding: '15px 35px',
        [theme.breakpoints.down('md')]: {
            fontSize: '20px !important',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px !important',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '25px !important',
        },
    },
    greyBack2: {
        backgroundColor: '#D9DCE5',
        borderRadius: 50,
        padding: '15px 35px',
        [theme.breakpoints.down('md')]: {
            fontSize: '20px !important',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px !important',
            marginLeft: -50
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '25px !important',
        },
    },
    greyBack3: {
        backgroundColor: '#FFFFFF',
        borderRadius: 50,
        padding: '15px 35px',
        [theme.breakpoints.down('md')]: {
            fontSize: '20px !important',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px !important',
            marginLeft: -50
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '25px !important',
        },
    },
    oval: {
        width: 30,
        height: '115%',
        borderRadius: 50,
        backgroundColor: '#B3BACB',
    },
    oval2: {
        width: 30,
        height: '115%',
        borderRadius: 50,
        backgroundColor: '#D9DCE5',
    },
    oval3: {
        width: 30,
        height: '100%',
        borderRadius: 50,
        backgroundColor: '#FFFFFF',
    },
    ovalItemMobile: {
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0 40px 30px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '40px 0',
        },
    },
    ovalItemTitle: {
        color: '#FFFFFF',
        fontSize: '20px !important',
        paddingBottom: 30,
    },
}));

const HowSection = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="xl" disableGutters>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.firstT}>{props.firstMain}</Typography>
                    </Grid>
                </Grid>
                <Hidden only={['xs', 'sm', 'md']}>
                    <Grid container>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="h5" className={classes.greyBack1}>{props.firstT}</Typography>
                            <Typography variant="body1" className={classes.secondT} dangerouslySetInnerHTML={{ __html: props.firstT2 }}></Typography>
                        </Grid>
                        <Grid item xs={12} lg={4} >
                            <Typography variant="h5" className={classes.greyBack2}>{props.secondT}</Typography>
                            <Typography variant="body1" className={classes.secondT2} dangerouslySetInnerHTML={{ __html: props.secondT2 }}></Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="h5" className={classes.greyBack3} dangerouslySetInnerHTML={{ __html: props.thirdT }}></Typography>
                            <Typography variant="body1" className={classes.secondT3}>{props.thirdT2}</Typography>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden only={['lg', 'xl']}>
                    <Grid container>
                        <Grid container item xs={12}>
                            <Grid item xs={1}>
                                <div className={classes.oval}></div>
                            </Grid>
                            <Grid item xs={11} className={classes.ovalItemMobile}>
                                <Typography variant="h5" className={classes.ovalItemTitle}>{props.firstT}</Typography>
                                <Typography variant="body1" className={classes.commonWhite} dangerouslySetInnerHTML={{ __html: props.firstT2 }}></Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className={classes.oval2}></div>
                            </Grid>
                            <Grid item xs={11} className={classes.ovalItemMobile}>
                                <Typography variant="h5" className={classes.ovalItemTitle}>{props.secondT}</Typography>
                                <Typography variant="body1" className={classes.commonWhite} dangerouslySetInnerHTML={{ __html: props.secondT2 }}></Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className={classes.oval3}></div>
                            </Grid>
                            <Grid item xs={11} className={classes.ovalItemMobile}>
                                <Typography variant="h5" className={classes.ovalItemTitle} dangerouslySetInnerHTML={{ __html: props.thirdT }}></Typography>
                                <Typography variant="body1" className={classes.commonWhite} dangerouslySetInnerHTML={{ __html: props.thirdT2 }}></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        {props.button}
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default HowSection;