/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Components ----------------------------------------------------------- */
import {
    makeStyles,
    withStyles,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import MainForm from "./MainForm";
import Backdrop from "../Backdrop";
import calls from "../js/calls";

const CssTextField = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 50,
        marginBottom: 15,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 50,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
})(TextField);

const CssTextField2 = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 15,
        marginBottom: 10,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 10,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paperCustom: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: "10px 10px 15px rgba(3,24,81,0.8)",
        [theme.breakpoints.down("xs")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "70%",
        },
        [theme.breakpoints.up("md")]: {
            borderRadius: 40,
            padding: "55px 30px !important",
            width: "90%",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "75px !important",
        },
        [theme.breakpoints.up("xl")]: {
            width: "100%",
        },
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        marginTop: 70,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    input: {
        "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    spacing: {
        padding: "20px 18.5px 35px",
    },
    spacing2: {
        [theme.breakpoints.down("md")]: {
            paddingBottom: 30,
        },
        [theme.breakpoints.up("lg")]: {
            paddingBottom: 35,
        },
        [theme.breakpoints.up("xl")]: {
            paddingBottom: 45,
        },
    },
    spacing3: {
        paddingBottom: 20,
    },
}));

const BookDemo = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [name, setName] = useState("");

    const [last, setLast] = useState("");

    const [email, setEmail] = useState("");

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const [dialogMsg, setDialogMsg] = useState("");

    const [isError, setisError] = useState(false);

    const [acctChkbox, setAcctChkbox] = useState(false);

    const [contrChkbox, setContrChkbox] = useState(false);

    const [cfoChkbox, setCfoChkbox] = useState(false);

    const [autoChkbox, setAutoChkbox] = useState(false);

    const [loading, showLoading] = useState(false);

    const clearInputs = () => {
        setName("");
        setLast("");
        setEmail("");
        setMessage("");
        setAcctChkbox(false);
        setContrChkbox(false);
        setCfoChkbox(false);
        setAutoChkbox(false);
        setisError(false);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeLast = (event) => {
        setLast(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleChangeAcctChkbox = (event) => {
        setAcctChkbox(event.target.checked);
    };

    const handleChangeContrChkbox = (event) => {
        setContrChkbox(event.target.checked);
    };

    const handleChangeCfoChkbox = (event) => {
        setCfoChkbox(event.target.checked);
    };

    const handleChangeAutoChkbox = (event) => {
        setAutoChkbox(event.target.checked);
    };

    const sendEmail = async () => {
        if (name.trim() && last.trim() && email.trim() && /.+@.+\..+/.test(email)) {
            showLoading(true);

            const param = {
                firstName: name,
                lastName: last,
                emailAddress: email,
                checkAcc: acctChkbox,
                checkCont: contrChkbox,
                checkCfo: cfoChkbox,
                checkAuto: autoChkbox,
                message: message,
            };

            await calls
                .postBookDemo(param)
                .then((response) => {
                    if (response.data) {
                        setDialogMsg(t("forms.formBookTalkSoon"));
                        setOpen(true);
                        clearInputs();
                    } else {
                        setDialogMsg(t("forms.forError"));
                        setOpen(true);
                    }
                })
                .catch((err) => {
                    setDialogMsg(err.message);
                    setOpen(true);
                });

            showLoading(false);
        } else {
            setisError(true);
        }
    };

    useEffect(() => {
        let checkBox = document.getElementById("bookCheck").childNodes;
        checkBox[1].innerHTML = t("header.submenuAcc");

        let checkBoxContr = document.getElementById("ContrCheck").childNodes;
        checkBoxContr[1].innerHTML = t("header.controller");

        let checkBoxCFO = document.getElementById("CFOCheck").childNodes;
        checkBoxCFO[1].innerHTML = t("header.cfo");
    }, [t]);

    let formBody = (
        <Paper elevation={3} className={classes.paperCustom}>
            <Typography variant="h5" color="secondary" className={classes.spacing2}>
                {t("contact.contDrop")}
            </Typography>
            <CssTextField
                fullWidth
                placeholder={t("bookkeeping.bookFirst")}
                variant="outlined"
                value={name}
                onChange={handleChangeName}
                error={isError && !name.trim()}
                helperText={isError && !name.trim() ? t("forms.forReq") : ""}
                InputProps={{
                    classes: { input: classes.input },
                }}
            />
            <CssTextField
                fullWidth
                placeholder={t("bookkeeping.bookLast")}
                variant="outlined"
                value={last}
                onChange={handleChangeLast}
                error={isError && !last.trim()}
                helperText={isError && !last.trim() ? t("forms.forReq") : ""}
                InputProps={{
                    classes: { input: classes.input },
                }}
            />
            <CssTextField
                fullWidth
                placeholder={t("bookkeeping.bookEmail")}
                variant="outlined"
                onChange={handleChangeEmail}
                value={email}
                error={
                    isError && (!email.trim() || !/.+@.+\..+/.test(email))
                }
                helperText={
                    isError && !email.trim()
                        ? t("forms.forReq")
                        : isError && !/.+@.+\..+/.test(email)
                        ? t("error.enterEmail")
                        : ""
                }
                InputProps={{
                    classes: { input: classes.input },
                }}
            />
            <Grid container justifyContent="flex-start" className={classes.spacing}>
                <Grid item xs={12}>
                    <Typography variant="body1" color="textPrimary" className={classes.spacing3}>
                        {t("forms.formSelectSolutions")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        id="bookCheck"
                        control={
                            <Checkbox
                                style={{ color: "#031851" }}
                                checked={acctChkbox}
                                onChange={handleChangeAcctChkbox}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        id="ContrCheck"
                        control={
                            <Checkbox
                                style={{ color: "#031851" }}
                                checked={contrChkbox}
                                onChange={handleChangeContrChkbox}
                            />
                        }
                        label={t("header.controller")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        id="CFOCheck"
                        control={
                            <Checkbox
                                style={{ color: "#031851" }}
                                checked={cfoChkbox}
                                onChange={handleChangeCfoChkbox}
                            />
                        }
                        label={t("header.cfo")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ color: "#031851" }}
                                checked={autoChkbox}
                                onChange={handleChangeAutoChkbox}
                            />
                        }
                        label={t("blog.blPro")}
                    />
                </Grid>
            </Grid>
            <CssTextField2
                fullWidth
                placeholder={t("forms.formGivePresentation")}
                variant="outlined"
                multiline
                rows={4}
                value={message}
                onChange={handleChangeMessage}
                InputProps={{
                    classes: { input: classes.input },
                }}
            />
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        className={classes.button}
                        onClick={sendEmail}
                    >
                        {t("forms.formBookNow")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <MainForm
                name={"bookDemo"}
                firstT={t("banner.bookDemo")}
                secondT={t("forms.formNeedBookDemo")}
                secondT2={t("forms.formBusinessFin")}
                thirdT={t("forms.formBookExplore")}
                firstT2={t("forms.formBookWill")}
                firstT3={t("forms.formBook30min")}
                firstT4={t("forms.formHelpBusiness")}
                firstT5={t("forms.formBookCustomize")}
                firstT6={t("forms.formBookHelped")}
                formFields={formBody}
                open={open}
                onChangeOpen={(value) => setOpen(value)}
                dialogMsg={dialogMsg}
            />
            <Backdrop open={loading} />
        </div>
    );
};

export default BookDemo;
