/** React imports */
import React, { useEffect, useState } from 'react';

/** Components ----------------------------------------------------------- */
import { makeStyles, Typography, Grid, Container, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";
import calls from './js/calls';

/** Icons/images */
import { ReactComponent as GcaLogoP } from '../assets/images/gca-logo-desktop.svg';
import FirstPerson from '../assets/images/gca-isolated-bookkeeping-accounting2.png';
import GcaLogo from '../assets/images/GCA-rgb-p.png';
import GeraldineCallesImg from '../assets/images/our-team-geraldine-calles.jpg';
import SecondGraph from '../assets/images/secondary-graphic-version-2.svg';

const useStyles = makeStyles((theme) => ({
    body: {
        [theme.breakpoints.down('md')]: {
            background: '#031851'
        },
        [theme.breakpoints.up('md')]: {
            background: 'linear-gradient(90deg, #031851 35%, #2f3190 35%)'
        },
    },
    body2: {
        backgroundColor: 'white',
        marginTop: 15,
        [theme.breakpoints.down('md')]: {
            padding: 30
        },
        [theme.breakpoints.up('md')]: {
            padding: '30px 50px 30px 50px'
        },
    },
    logoGCA: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 150,
        paddingTop: 30
    },
    logoGCAF: {
        display: 'block',
        [theme.breakpoints.down('md')]: {
            marginRight: 20,
            width: 80
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 45,
            width: 100
        },
    },
    logoGCAF2: {
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 25,
            fontSize: 14
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50
        },
    },
    logoGCAF3: {
        color: 'white',
        [theme.breakpoints.down('md')]: {
            fontSize: 14
        },
    },
    logoGCAText: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 700
    },
    imgGCA: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 400
        },
    },
    panelsL: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '35%'
        },
    },
    panels: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '60px 30px 0 30px'
        },
        [theme.breakpoints.up('md')]: {
            width: '35%',
            padding: '130px 45px 0 45px'
        },
    },
    panels2: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '60px 30px 0px 30px'
        },
        [theme.breakpoints.up('md')]: {
            width: '65%',
            paddingTop: 60
        },
    },
    panel2Text: {
        color: 'white',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: 350,
        },
        margin: '0 auto'
    },
    gcaText: {
        [theme.breakpoints.up('md')]: {
            paddingTop: 300,
            textAlign: 'center'
        },
    },
    footer: {
        backgroundColor: '#031851'
    },
    monthYear: {
        paddingTop: 30,
        paddingBottom: 20,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 45,
            paddingRight: 10,
            position: 'absolute',
            width: 330
        },
    },
    taxMYear: {
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 20,
            position: 'absolute',
            width: 330
        },
    },
    taxMYear2: {
        paddingBottom: 15, 
        paddingTop: 60,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 85,
            paddingRight: 15,
            position: 'absolute',
            width: 330
        },
    },
    serviceTitle: {
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 15
        },
    },
    serviceTitle2: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 35
        },
    },
    greyBack1: {
        backgroundColor: '#031851',
        borderRadius: 50,
        padding: 3,
        color: 'white',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 14
        },
    },
    greyBack2: {
        backgroundColor: '#cbcbe3',
        borderRadius: 50,
        color: 'white',
        textAlign: 'center',
        padding: 3,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    greyBack3: {
        backgroundColor: '#acadd3',
        borderRadius: 50,
        color: 'white',
        textAlign: 'center',
        padding: 3,
        marginLeft: -25,
        [theme.breakpoints.down('sm')]: {
            marginLeft: -10,
            fontSize: 12,
        },
    },
    greyBack4: {
        backgroundColor: '#8d8ec2',
        borderRadius: 50,
        color: 'white',
        textAlign: 'center',
        padding: 3,
        marginLeft: -25,
        [theme.breakpoints.down('sm')]: {
            marginLeft: -10,
            fontSize: 12,
        },
    },
    greyBack5: {
        backgroundColor: '#6d6fb1',
        borderRadius: 50,
        color: 'white',
        textAlign: 'center',
        padding: 3,
        marginLeft: -25,
        [theme.breakpoints.down('sm')]: {
            marginLeft: -10,
            fontSize: 12,
        },
    },
    greyBack6: {
        backgroundColor: '#4e50a1',
        borderRadius: 50,
        color: 'white',
        textAlign: 'center',
        padding: 3,
        marginLeft: -25,
        [theme.breakpoints.down('sm')]: {
            marginLeft: -10,
            fontSize: 12,
        },
    },
    greyBack7: {
        backgroundColor: '#2f3190',
        borderRadius: 50,
        color: 'white',
        textAlign: 'center',
        padding: 3,
        marginLeft: -25,
        [theme.breakpoints.down('sm')]: {
            marginLeft: -10,
            fontSize: 12,
        },
    },
    mainBack: {
        backgroundColor: '#f8f9f8',
        borderRadius: 20,
        padding: '25px 20px'
    },
    mainBack2: {
        backgroundColor: '#f8f9f8',
        borderRadius: '0 0px 20px 20px',
        padding: '25px 20px',
        [theme.breakpoints.up('md')]: {
            marginTop: '-30px'
        },
    },
    mainBack3: {
        backgroundColor: '#f8f9f8',
        borderRadius: 20,
        padding: '25px 20px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px'
        },
    },
    divider: {
        background: '#ff5968',
        height: 3,
        margin: '15px 0px',
        borderRadius: 20
    },
    divider2: {
        background: '#ff5968',
        height: 10,
        borderRadius: 20
    },
    divider3: {
        background: '#ff5968',
        borderRadius: 20,
        display: 'block',
        margin: '0 auto',
        width: 3
    },
    divider4: {
        background: '#ff5968',
        borderRadius: 20,
        height: 3,
        marginTop: 50,
        marginBottom: 50,
        width: 300
    },
    mainText1: {
        fontSize: 14
    },
    mainText2: {
        fontSize: 12
    },
    teamImage: {
        borderRadius: 10,
        height: 80,
        width: 80,
        marginLeft: 15,
    },
    teamImage2: {
        borderRadius: 10,
        backgroundColor: '#E9EAE8',
        height: 80,
        width: 80,
        marginLeft: 15
    },
    teamName: {
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
            paddingBottom: 10,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 0,
            paddingBottom: 10,
        },
    },
    positionReview: {
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingBottom: 30,
        },
        [theme.breakpoints.up('md')]: {
            width: '85%',
            paddingBottom: 10,
        },
        [theme.breakpoints.up('lg')]: {
            width: '90%',
        },
    },
    FirmImage: {
        borderRadius: 10,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            height: 100,
            width: 200
        },
    },
    ListText: {
        "& li": {
            fontSize: 12,
            paddingBottom: 10
        },
    },
    testBox: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 650
        },
        paddingTop: 30,
        paddingBottom: 30
    },
    textBoxM: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 500
        },
    },
    testBox2: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 650
        },
        paddingTop: 10,
        paddingBottom: 30
    },
    testBox3: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 650
        },
        paddingTop: 10,
    },
    panelText1: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 350
        },
        margin: '0 auto'
    },
    panelText2: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: 350
        },
        color: 'white',
        margin: '0 auto',
        paddingBottom: 50
    },
    body3: {
        backgroundColor: '#031851',
        marginTop: 15,
        [theme.breakpoints.down('sm')]: {
            padding: 30,
            height: 700,
        },
        [theme.breakpoints.up('sm')]: {
            height: 800,
        },
        [theme.breakpoints.up('md')]: {
            height: 1300,
        },
        [theme.breakpoints.up('lg')]: {
            height: 1000,
        },
    },
    headerLogo: {
        display: 'block',
        [theme.breakpoints.down('md')]: {
            marginTop: 50,
            height: 40
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 50
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 200
        },
        height: 50,
    },
    secondGraphImg: {
        width: 280,
        [theme.breakpoints.down('sm')]: {
            width: 150,
            marginLeft: -30,
            zIndex: 2
        },
    },
    textPadd: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 30,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 100,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 350,
        },
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Quote = ({ history }) => {
    const [t, i18n] = useTranslation("global");
    const classes = useStyles();
    const [quote, setQuote] = useState([]);
    const [TotalServiceA, setTotalService] = useState(0);
    const [AddonsS, setAddonsS] = useState([]);
    const [AddonsT, setAddonsT] = useState([]);
    const query = useQuery();

    useEffect(() => {
        (async () => {
            let id = query.get("quoteId");
            let amount = 0;
            if (id === null) {
                history.push("/");
            }

            await calls.getPricing(id).then((response) => {
                if (response.data !== "") {
                    if (response.data.pay_annually) {
                        amount = (response.data.total_fee_amount - (response.data.total_fee_amount * 0.15)) * 12;
                    }
                    else {
                        amount = response.data.total_fee_amount;
                    }
                    setQuote(response.data);
                    setAddonsS(response.data.service_addons);
                    setAddonsT(response.data.tax_addons);
                    setTotalService(amount);
                    i18n.changeLanguage(response.data.language);
                }
                else {
                    history.push("/");
                }
            }).catch((err) => {
                console.log(err.error);
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ backgroundColor: '#B3BACB' }}>
            <Container maxWidth="md" disableGutters>
                <div className={classes.body}>
                    <Grid container alignItems="center">
                        <Grid item className={classes.panelsL}>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <img
                                    alt="gca-logo"
                                    src={GcaLogo}
                                    className={classes.logoGCA}
                                />
                                <Typography
                                    color="secondary"
                                    className={classes.logoGCAText}
                                >
                                    Growing Companies Advisors
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="flex-end">
                        <Grid item className={classes.panels2}>
                            <Typography
                                variant="h4"
                                color="secondary"
                                className={classes.panelText1}
                            >
                                {t("banner.accuBook")}
                            </Typography>
                            <Typography
                                className={classes.panelText2}
                                variant="h4"
                            >
                                {t("banner.insightBook")}
                            </Typography>
                            <Typography
                                className={classes.panel2Text}
                                style={{ paddingBottom: 15 }}
                                variant="h6"
                                dangerouslySetInnerHTML={{
                                    __html: quote.service_name
                                }}
                            >
                            </Typography>
                            <Typography
                                className={classes.panel2Text}
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                    __html: quote.service_name === t('pricing.priAcc') ? t('pricing.priTop')
                                        : quote.service_name === t('pricing.priCont') ? t('pricing.priGui')
                                            : t('pricing.priStrat')
                                }}
                            >
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item className={classes.panels}>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <Typography
                                    color="secondary"
                                    variant="h6"
                                    className={classes.gcaText}
                                >
                                    GCA.digital
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item className={classes.panels2}>
                            <img
                                alt="gca-img"
                                src={FirstPerson}
                                className={classes.imgGCA}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.body2}>
                    <Typography
                        style={{ paddingTop: 50 }}
                        variant="h5"
                        color="secondary"
                        dangerouslySetInnerHTML={{
                            __html: t('quote.quoteChosen') + " " + quote.service_name
                        }}
                    >
                    </Typography>
                    <Typography
                        variant="h5"
                        className={classes.textBoxM}
                    >
                        {t('quote.quoteSerChosen')}
                    </Typography>
                    <Typography
                        variant="body2"
                        className={classes.textBoxM}
                        style={{ paddingTop: 50, fontWeight: 700 }}
                    >
                        {t('quote.quoteUsual')}
                    </Typography>
                    <ul className={classes.ListText}>
                        <li>
                            {t('quote.quoteAcc')}
                        </li>
                        <li>
                            {t('quote.quoteAccHist')}
                        </li>
                        <li>
                            {t('quote.quoteCost')}
                        </li>
                    </ul>
                    <Typography
                        variant="body2"
                        className={classes.textBoxM}
                        style={{ paddingTop: 50, fontWeight: 700 }}
                    >
                        {t('quote.quoteGuided')}
                    </Typography>
                    <ul className={classes.ListText}>
                        <li>
                            {t('quote.quoteConf')}
                        </li>
                        <li>
                            {t('quote.quoteSup')}
                        </li>
                        <li>
                            {t('quote.quoteReli')}
                        </li>
                    </ul>
                    <Divider classes={{ root: classes.divider4 }} />
                    <Typography
                        variant="h6"
                        className={classes.textBoxM}
                        style={{ paddingBottom: 50 }}
                    >
                        <span style={{ color: "#ff5968" }}>{t('quote.quoteTeam')}</span> {t('quote.quoteTeam2')}
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={2}>
                                    <div className={classes.teamImage2}></div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="h6" className={classes.teamName}>Stephanie Rodríguez</Typography>
                                    <Typography variant="body2" style={{ paddingBottom: 10, fontSize: 14 }} dangerouslySetInnerHTML={{ __html: t('pricing.priTaxExp') }}></Typography>
                                    <Typography variant="body2" className={classes.positionReview} dangerouslySetInnerHTML={{ __html: t('quote.quoteMakesSure') }}></Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6" className={classes.teamName}>Geraldine Calles</Typography>
                                    <Typography variant="body2" style={{ paddingBottom: 10, fontSize: 14 }} dangerouslySetInnerHTML={{ __html: t('about.abChief') }}></Typography>
                                    <Typography variant="body2" className={classes.positionReview} dangerouslySetInnerHTML={{ __html: t('quote.quoteLeads') }}></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.footer}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <Typography
                                    variant="body2"
                                    className={classes.logoGCAF2}
                                    color="secondary"
                                >
                                    GCA.digital
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className={classes.logoGCAF3}
                            >
                                GCA 360 INC
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <img
                                    alt="gca-logo"
                                    src={GcaLogo}
                                    className={classes.logoGCAF}
                                />
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.body2}>
                    <Typography
                        variant="h5"
                    >
                        {t('pricing.priScopWork')}
                    </Typography>
                    <Grid container alignItems="center" justifyContent="flex-end" style={{ paddingBottom: 15 }}>
                        <Grid item>
                            <Typography
                                variant="body2"
                                style={{ fontWeight: 700, paddingBottom: 10 }}
                            >
                                {t('pricing.priMCashO')}
                            </Typography>
                            <Typography variant="h6" className={classes.greyBack1}>{t('pricing.priCoin') + quote.amc_amount}K</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="flex-end" style={{ paddingBottom: 5 }}>
                        <Grid item>
                            <Typography
                                variant="body2"
                                style={{ fontWeight: 700, paddingRight: 50 }}
                            >
                                {t('pricing.priFees')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" style={{ paddingBottom: 15 }}>
                        <Grid item xs={4} md={8}>
                            <Typography
                                variant="h6"
                            >
                                {t('pricing.priOnboard')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography
                                variant="body2"
                                style={{ fontSize: 10, textAlign: 'center' }}
                            >
                                {t('pricing.priOneTime')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Typography variant="h6" className={classes.greyBack1}>{t('pricing.priCoin') + quote.total_fee_amount}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <div className={classes.mainBack}>
                                <Typography
                                    variant="h6"
                                    dangerouslySetInnerHTML={{
                                        __html: quote.service_name
                                    }}
                                >
                                </Typography>
                                <Divider classes={{ root: classes.divider }} />
                                {quote.service_name === t('pricing.priAcc') ?
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priDedic')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolAcc')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priA')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolCash')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priWeekAcc')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolBank')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priMonthAcc')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolBalan')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priVirtAcc')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolVid')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priUnliAcc')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolSup')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    : quote.service_name === t('pricing.priCont') ?
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                    dangerouslySetInnerHTML={{ __html: t('pricing.priContDed') }}
                                                >
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolPub')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priContBuss')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolStruc')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priContAdmin')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolAccCont')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priContFinan')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolContFin')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priContAcc')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolImp')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priContPol')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolDef')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priContMont')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolTay')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                    dangerouslySetInnerHTML={{ __html: t('pricing.priCFODed') }}
                                                >
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolPubCFO')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priCFOBud')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolFin')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priCFOFina')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolStru')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priCFOBus')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolImpa')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priCFOCash')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolPrem')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priCFOKpi')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolBus')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.mainText1}
                                                >
                                                    {t('pricing.priContMont')}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.mainText2}
                                                >
                                                    {t('pricing.priToolTay')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                }
                            </div>
                        </Grid>
                        {quote.pay_annually ?
                            <Grid item xs={6} md={2}>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, paddingTop: 30, textAlign: 'center' }}
                                >
                                    {t('pricing.priYFee')}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, textAlign: 'center' }}
                                >
                                    {t('pricing.priYPaid')}
                                </Typography>
                            </Grid>
                            :
                            <Grid item xs={6} md={2}>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, paddingTop: 30, textAlign: 'center' }}
                                >
                                    {t('pricing.priMFee')}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, textAlign: 'center' }}
                                >
                                    {t('pricing.priMPaid')}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={6} md={2}>
                            <Typography variant="h6" className={classes.greyBack1} style={{ marginTop: 30 }}>{t('pricing.priCoin') + parseFloat(TotalServiceA).toLocaleString(t("language.currencyFormat"), { maximumFractionDigits: 2 })}</Typography>
                        </Grid>
                    </Grid>
                    {AddonsS.length !== 0 ?
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <div className={classes.mainBack2}>
                                    <Typography
                                        variant="h6"
                                    >
                                        {t('pricing.priAddons')}
                                    </Typography>
                                    <Divider classes={{ root: classes.divider }} />
                                    {quote.service_name === t('pricing.priAcc') ?
                                        <Grid container alignItems="center" spacing={2}>
                                            {quote.service_addons.map((val) => (
                                                <>
                                                    <Grid item md={6}>
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.mainText1}
                                                        >
                                                            {val}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.mainText2}
                                                        >
                                                            {val === t('pricing.priRec') ? t('pricing.priToolAct') :
                                                                val === t('pricing.priPRec') ? t('pricing.priToolTie') :
                                                                    val === t('pricing.priClass') ? t('pricing.priToolCost') :
                                                                        val === t('pricing.priMulAcc') ? t('pricing.priToolInter') :
                                                                            val === t('pricing.priPerAcc') ? t('pricing.priToolTay') : null}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>
                                        : quote.service_name === t('pricing.priCont') ?
                                            <Grid container alignItems="center" spacing={2}>
                                                {quote.service_addons.map((val) => (
                                                    <>
                                                        <Grid item md={6}>
                                                            <Typography
                                                                variant="body1"
                                                                className={classes.mainText1}
                                                            >
                                                                {val}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <Typography
                                                                variant="body2"
                                                                className={classes.mainText2}
                                                            >
                                                                {val === t('pricing.priContTax') ? t('pricing.priToolImpCont') :
                                                                    val === t('pricing.priContFin') ? t('pricing.priToolComb') :
                                                                        val === t('pricing.priContExt') ? t('pricing.priToolManag') : null}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                            :
                                            <Grid container alignItems="center" spacing={2}>
                                                {quote.service_addons.map((val) => (
                                                    <>
                                                        <Grid item md={6}>
                                                            <Typography
                                                                variant="body1"
                                                                className={classes.mainText1}
                                                            >
                                                                {val}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <Typography
                                                                variant="body2"
                                                                className={classes.mainText2}
                                                            >
                                                                {val === t('pricing.priCFOCorp') ? t('pricing.priToolRul') :
                                                                    val === t('pricing.priCFOInv') ? t('pricing.priToolSupp') :
                                                                        t('pricing.priToolTayl')}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, textAlign: 'center' }}
                                >
                                    {t('pricing.priHFee')}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, textAlign: 'center' }}
                                >
                                    {t('pricing.priMPaid')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography variant="h6" className={classes.greyBack1}>{t('pricing.priCoin') + (quote.service_name === "Accountant" ? 50
                                    : quote.service_name === "Controller" ? 150 : 250)}</Typography>
                            </Grid>
                        </Grid>
                        : null
                    }
                    {quote.tax_service ?
                        <Grid container spacing={2} style={{ paddingTop: 20 }}>
                            <Grid item xs={12} md={8}>
                                <div className={classes.mainBack}>
                                    <Typography
                                        variant="h6"
                                        dangerouslySetInnerHTML={{
                                            __html: t('pricing.priTaxExp')
                                        }}
                                    >
                                    </Typography>
                                    <Divider classes={{ root: classes.divider }} />
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priTaxDed')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolPubl')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priTaxFed')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolInc')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes.mainText1}
                                            >
                                                {t('pricing.priTaxUnli')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Typography
                                                variant="body2"
                                                className={classes.mainText2}
                                            >
                                                {t('pricing.priToolSupport')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, paddingTop: 30, textAlign: 'center' }}
                                >
                                    {t('pricing.priMFee')}
                                </Typography>

                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, textAlign: 'center' }}
                                >
                                    {quote.pay_annually ?
                                        t('pricing.priYPaid')
                                        :
                                        t('pricing.priMPaid')
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography variant="h6" className={classes.greyBack1} style={{ marginTop: 30 }}>{t('pricing.priCoin') + parseFloat(quote.total_taxes_amount).toLocaleString(t("language.currencyFormat"), { maximumFractionDigits: 2 })}</Typography>
                            </Grid>
                        </Grid>
                        : null
                    }
                    {AddonsT.length !== 0 ?
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <div className={classes.mainBack2}>
                                    <Typography
                                        variant="h6"
                                    >
                                        {t('pricing.priAddons')}
                                    </Typography>
                                    <Divider classes={{ root: classes.divider }} />
                                    <Grid container alignItems="center" spacing={2}>
                                        {quote.tax_addons.map((val) => (
                                            <>
                                                <Grid item md={6}>
                                                    <Typography
                                                        variant="body1"
                                                        className={classes.mainText1}
                                                    >
                                                        {val}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.mainText2}
                                                    >
                                                        {val === t('pricing.priTaxFor') ? t('pricing.priToolForm') :
                                                            val === t('pricing.priTaxInter') ? t('pricing.priToolForm3') :
                                                                val === t('pricing.priTaxInt') ? t('pricing.priToolForm2') :
                                                                    val === t('pricing.priTaxQuar') ? t('pricing.priToolFinan') :
                                                                        t('pricing.priToolDefi')}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, textAlign: 'center' }}
                                >
                                    {t('pricing.priHFee')}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{ fontSize: 10, textAlign: 'center' }}
                                >
                                    {t('pricing.priMPaid')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Typography variant="h6" className={classes.greyBack1}>{t('pricing.priCoin') + 150}</Typography>
                            </Grid>
                        </Grid>
                        : null
                    }
                </div>
                <div className={classes.footer}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <Typography
                                    variant="body2"
                                    className={classes.logoGCAF2}
                                    color="secondary"
                                >
                                    GCA.digital
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className={classes.logoGCAF3}
                            >
                                GCA 360 INC
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <img
                                    alt="gca-logo"
                                    src={GcaLogo}
                                    className={classes.logoGCAF}
                                />
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.body2}>
                    <Typography
                        variant="h5"
                    >
                        {t('pricing.priS&D')}
                    </Typography>
                    <Grid container alignItems="center" justifyContent="flex-end" className={classes.monthYear}>
                        <Grid item xs={6} md={6}>
                            <Typography
                                variant="body2"
                                style={{ fontWeight: 700, textAlign: 'center' }}
                            >
                                {t('pricing.priEverM')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography
                                variant="body2"
                                style={{ fontWeight: 700, textAlign: 'center' }}
                            >
                                {t('pricing.priNextM')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" className={classes.serviceTitle} spacing={6}>
                        <Grid item xs={12} md={2}>
                            <Typography
                                variant="body2"
                                style={{ fontWeight: 700 }}
                                dangerouslySetInnerHTML={{
                                    __html: quote.service_name
                                }}
                            >
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container alignItems="center">
                                <Grid item xs={2} md={2}>
                                    <Typography variant="h6" className={classes.greyBack2}>Wk 1</Typography>
                                </Grid>
                                <Grid item xs={2} md={2} >
                                    <Typography variant="h6" className={classes.greyBack3}>Wk 2</Typography>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <Typography variant="h6" className={classes.greyBack4}>Wk 3</Typography>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <Typography variant="h6" className={classes.greyBack5}>Wk 4</Typography>
                                </Grid>
                                <Grid item xs={2} md={2} >
                                    <Typography variant="h6" className={classes.greyBack6}>Wk 1</Typography>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <Typography variant="h6" className={classes.greyBack7}>Wk 2</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                        <Grid item xs={12} md={2}>
                            <Typography
                                variant="body2"
                                style={{ fontSize: 12 }}
                            >
                                {t("pricing.priBankRe")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container alignItems="center" justifyContent="center">
                                <Grid item xs={8} md={8}>
                                    <Divider classes={{ root: classes.divider2 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                        <Grid item xs={12} md={2}>
                            <Typography
                                variant="body2"
                                style={{ fontSize: 12 }}
                            >
                                {t("pricing.priBalSheet")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container alignItems="center" justifyContent="flex-end">
                                <Grid item xs={2} md={2}>
                                    <Divider classes={{ root: classes.divider2 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                        <Grid item xs={12} md={2}>
                            <Typography
                                variant="body2"
                                style={{ fontSize: 12 }}
                            >
                                {t("pricing.priIncState")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container alignItems="center" justifyContent="flex-end">
                                <Grid item xs={2} md={2}>
                                    <Divider classes={{ root: classes.divider2 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ paddingBottom: 50 }}>
                        <Grid item xs={12} md={2}>
                            <Typography
                                variant="body2"
                                style={{ fontSize: 12 }}
                            >
                                {t("pricing.priCashF")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid container alignItems="center" justifyContent="flex-end">
                                <Grid item xs={2} md={2}>
                                    <Divider classes={{ root: classes.divider2 }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="flex-end" className={classes.taxMYear}>
                        <Grid item xs={6} md={6}>
                            <Typography
                                variant="body2"
                                style={{ fontWeight: 700, textAlign: 'center' }}
                            >
                                {t('pricing.priTaxY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography
                                variant="body2"
                                style={{ fontWeight: 700, textAlign: 'center' }}
                            >
                                {t('pricing.priNextY')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                        <Grid item xs={12} md={2}>
                            <Typography
                                variant="body2"
                                style={{ fontSize: 12 }}
                            >
                                {t("pricing.priVirtAcc")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10} className={classes.serviceTitle2}>
                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                <Grid item xs={2} md={2}>
                                    <Typography variant="h6" className={classes.greyBack1}>Apr</Typography>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <Typography variant="h6" className={classes.greyBack1}>Jul</Typography>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <Typography variant="h6" className={classes.greyBack1}>Oct</Typography>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <Typography variant="h6" className={classes.greyBack1}>Jan</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {quote.tax_service ?
                        <>
                            <Grid container alignItems="center" justifyContent="flex-end" className={classes.taxMYear2}>
                                <Grid item xs={7} md={7}>
                                    <Typography
                                        variant="body2"
                                        style={{ fontWeight: 700, textAlign: 'center' }}
                                    >
                                        {t('pricing.priTaxY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <Typography
                                        variant="body2"
                                        style={{ fontWeight: 700, textAlign: 'center' }}
                                    >
                                        {t('pricing.priNextY')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <div className={classes.mainBack3}>
                                <Grid container alignItems="center" style={{ paddingBottom: 15 }} spacing={4}>
                                    <Grid item xs={12} md={2}>
                                        <Typography
                                            variant="h6"
                                            dangerouslySetInnerHTML={{
                                                __html: t('pricing.priTaxExp')
                                            }}
                                        >
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container alignItems="center" justifyContent="center">
                                            <Grid item xs={3} md={3}>
                                                <Typography variant="h6" className={classes.greyBack2}>Q1</Typography>
                                            </Grid>
                                            <Grid item xs={2} md={2} >
                                                <Typography variant="h6" className={classes.greyBack3}>Q2</Typography>
                                            </Grid>
                                            <Grid item xs={2} md={2}>
                                                <Typography variant="h6" className={classes.greyBack4}>Q3</Typography>
                                            </Grid>
                                            <Grid item xs={2} md={2}>
                                                <Typography variant="h6" className={classes.greyBack5}>Q4</Typography>
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <Typography variant="h6" className={classes.greyBack7}>Jan</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                                    <Grid item xs={12} md={2}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: 12 }}
                                        >
                                            {t("pricing.priFedI")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container alignItems="center" justifyContent="flex-end">
                                            <Grid item xs={3} md={3}>
                                                <Divider classes={{ root: classes.divider2 }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                                    <Grid item xs={12} md={2}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: 12 }}
                                        >
                                            {t("pricing.priStateI")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container alignItems="center" justifyContent="flex-end">
                                            <Grid item xs={3} md={3}>
                                                <Divider classes={{ root: classes.divider2 }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                                    <Grid item xs={12} md={2}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: 12 }}
                                        >
                                            {t("pricing.priTaxFor")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container alignItems="center" justifyContent="center">
                                            <Grid item xs={6} md={6}>
                                                <Divider classes={{ root: classes.divider2 }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                                    <Grid item xs={12} md={2}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: 12 }}
                                        >
                                            {t("pricing.priQTaxP")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container alignItems="center" justifyContent="center">
                                            <Grid item xs={6} md={6}>
                                                <Divider classes={{ root: classes.divider2 }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" style={{ paddingBottom: 15 }}>
                                    <Grid item xs={12} md={2}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: 12 }}
                                        >
                                            {t("pricing.priPlanIni")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Grid container alignItems="center" justifyContent="center">
                                            <Grid item xs={6} md={6}>
                                                <Divider classes={{ root: classes.divider2 }} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                        : null}
                    <Typography
                        variant="body2"
                        style={{ paddingTop: 30, fontSize: 14 }}
                    >
                        {t('pricing.priFYI')}
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{ fontSize: 14 }}
                    >
                        {t('pricing.priFYI2')}
                    </Typography>

                </div>
                <div className={classes.footer}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <Typography
                                    variant="body2"
                                    className={classes.logoGCAF2}
                                    color="secondary"
                                >
                                    GCA.digital
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className={classes.logoGCAF3}
                            >
                                GCA 360 INC
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <img
                                    alt="gca-logo"
                                    src={GcaLogo}
                                    className={classes.logoGCAF}
                                />
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.body2}>
                    <Typography
                        variant="h5"
                    >
                        {t('pricing.priTerms')}
                    </Typography>
                    <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: t('pricing.priTermsSub') }}
                    >
                    </Typography>
                    <div className={classes.testBox}>
                        <Typography
                            variant="h5"
                        >
                            {t('pricing.priService')}
                        </Typography>
                        <Typography
                            variant="h6"
                            dangerouslySetInnerHTML={{
                                __html: t('pricing.priAcc')
                            }}
                        >
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>
                                {t('pricing.priAccTerm')}
                            </li>
                            <li>
                                {t('pricing.priAccTerm2')}
                            </li>
                            <li>
                                {t('pricing.priAccTerm3')}
                            </li>
                        </ul>
                    </div>
                    {quote.tax_service ?
                        <div className={classes.testBox2}>
                            <Typography
                                variant="h6"
                                dangerouslySetInnerHTML={{
                                    __html: t('pricing.priTaxExp')
                                }}
                            >
                            </Typography>
                            <ul className={classes.ListText}>
                                <li>
                                    {t('pricing.priTaxTerm')}
                                </li>
                                <li>
                                    {t('pricing.priTaxTerm2')}
                                </li>
                            </ul>
                        </div>
                        : null
                    }
                    <div className={classes.testBox2}>
                        <Typography
                            variant="h5"
                        >
                            {t('pricing.priFees')}
                        </Typography>
                        <Typography
                            variant="h6"
                            dangerouslySetInnerHTML={{
                                __html: t('pricing.priAcc')
                            }}
                        >
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>
                                {t('pricing.priAccFeeT')}
                            </li>
                            <li>
                                {t('pricing.priAccFeeT2')}
                            </li>
                            <li>
                                {t('pricing.priAccFeeT3')}
                            </li>
                            {quote.pay_annually ?
                                <li>
                                    {t('pricing.priAccFeeTA')}
                                </li>
                                :
                                <li>
                                    {t('pricing.priAccFeeTM')}
                                </li>
                            }
                            <li>
                                {t('pricing.priAccFeeT4')}
                            </li>
                            <li>
                                {t('pricing.priAccFeeT5')}
                            </li>
                            <li>
                                {t('pricing.priAccFeeT6')}
                            </li>
                            <li>
                                {t('pricing.priAccFeeT7')}
                            </li>
                        </ul>
                    </div>
                    {quote.tax_service ?
                        <div className={classes.testBox2}>
                            <Typography
                                variant="h6"
                                dangerouslySetInnerHTML={{
                                    __html: t('pricing.priTaxExp')
                                }}
                            >
                            </Typography>
                            <ul className={classes.ListText}>
                                <li>
                                    {t('pricing.priTaxFee')}
                                </li>
                                <li>
                                    {t('pricing.priTaxFee2')}
                                </li>
                                <li>
                                    {t('pricing.priTaxFee3')}
                                </li>
                                {quote.pay_annually ?
                                    <li>
                                        {t('pricing.priTaxFee5')}
                                    </li>
                                    :
                                    <li>
                                        {t('pricing.priTaxFee4')}
                                    </li>
                                }
                                <li>
                                    {t('pricing.priTaxFee6')}
                                </li>
                            </ul>
                        </div>
                        : null
                    }
                    <div className={classes.testBox3}>
                        <Typography
                            variant="h6"
                        >
                            General
                        </Typography>
                        <ul className={classes.ListText}>
                            <li>
                                {t('pricing.priGen')}
                            </li>
                            <li>
                                {t('pricing.priGen2')}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.footer}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <Typography
                                    variant="body2"
                                    className={classes.logoGCAF2}
                                    color="secondary"
                                >
                                    GCA.digital
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                className={classes.logoGCAF3}
                            >
                                GCA 360 INC
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <img
                                    alt="gca-logo"
                                    src={GcaLogo}
                                    className={classes.logoGCAF}
                                />
                            </Link>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.body3}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6}>
                            <img src={SecondGraph} alt="isotype" className={classes.secondGraphImg} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h5"
                                color="secondary"
                                className={classes.textPadd}
                            >
                                {t('quote.quoteThri')}
                            </Typography>
                            <Typography
                                variant="h5"
                                style={{ color: 'white' }}
                            >
                                {t('quote.quoteCrunch')}
                            </Typography>
                            <Typography
                                variant="h6"
                                style={{ color: 'white', paddingTop: 80 }}
                            >
                                hola@gca.digital
                            </Typography>
                            <Typography
                                variant="h6"
                                color="secondary"
                            >
                                GCA.digital
                            </Typography>
                            <Link to='/'>
                                <GcaLogoP className={classes.headerLogo} />
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export default Quote;