/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from 'react';

/** Icons/images */
import SecondGraph from '../../assets/images/secondary-graphic-version-2.svg';

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& p': {
            lineHeight: 1.2
        },
        backgroundColor: 'white',
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120
        },
    },
    personaImg: {
        [theme.breakpoints.down('sm')]: {
            width: 300
        },
        [theme.breakpoints.up('sm')]: {
            width: 600
        },
        [theme.breakpoints.up('lg')]: {
            width: 800
        },
        [theme.breakpoints.up('xl')]: {
            width: 950
        },
        paddingTop: 120,
    },
    firstT: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40
        },
    },
    firstT2: {
        color: '#ff5968',
    },
    secondT: {
        paddingTop: 30,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '90%',
        },
    },
    graphSection: {
        paddingTop: 100,
    },
    secondGraphImg: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: 150,
            marginLeft: -30,
            zIndex: 2
        },
        [theme.breakpoints.up('md')]: {
            width: 300,
            marginLeft: -70,
        },
        [theme.breakpoints.up('lg')]: {
            width: 400,
            marginLeft: -120,
        },
        [theme.breakpoints.up('xl')]: {
            width: 500,
            marginLeft: -120,
        },
    },
    whySectionImg: {
        position: 'absolute',
        borderRadius: 20,
        [theme.breakpoints.only('xs')]: {
            width: 260,
            right: '10%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 280,
            right: '10%',
        },
        [theme.breakpoints.up('md')]: {
            width: 600,
            right: '5%',
        },
        [theme.breakpoints.up('lg')]: {
            width: 800,
            right: '6%',
        },
        [theme.breakpoints.up('xl')]: {
            width: 950,
            right: '7%',
        },
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 60,
            fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 120,
            marginBottom: 80,
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: 30,
        },
    },
}));

const WhySection = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="xl" disableGutters>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={9}>
                        <Typography variant="h3" className={classes.firstT}>{props.firstMain}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={6}>
                    <Grid item xs={12} lg={4}>
                        <Typography variant="h5" className={props.firstT3Class}>{props.firstT} <span className={classes.firstT2}>{props.secondT}</span></Typography>
                        <Typography variant="body1" className={classes.secondT} dangerouslySetInnerHTML={{ __html: props.thirdT }}></Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography variant="h5" className={props.firstT3Class}>
                            {props.firstT2} <span className={classes.firstT2} dangerouslySetInnerHTML={{ __html: props.secondT2 }}></span>
                        </Typography>
                        <Typography variant="body1" className={classes.secondT}>{props.thirdT2}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography variant="h5" className={props.firstT3Class}>{props.firstT3} <span className={classes.firstT2}>{props.secondT3}</span></Typography>
                        <Typography variant="body1" className={classes.secondT}>{props.thirdT3}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" className={classes.graphSection}>
                    <Grid item xs={12}>
                        <img src={SecondGraph} alt="isotype" className={classes.secondGraphImg} />
                        <img src={props.image} alt="whyImage" className={classes.whySectionImg} />
                    </Grid>
                </Grid>
                {/* <Grid container justifyContent="center">
                    <Grid item>
                        <Button component={Link} to="/pricing" variant="contained" color="secondary" 
                            disableElevation className={classes.button}
                        >
                            {props.button}
                        </Button>
                    </Grid>
                </Grid> */}
            </Container>
        </div>
    )
}

export default WhySection;
