/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

/** Icons/images */
import { ReactComponent as GcaLogoFooter } from "../assets/images/gca-logo-desktop-blue.svg";
import { ReactComponent as InstagramLogo } from "../assets/images/icon-instagram.svg";
import { ReactComponent as LinkedinLogo } from "../assets/images/icon-linkedin-enclosed.svg";

/** Components ----------------------------------------------------------- */
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import calls from "./js/calls";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    hidden: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    btnRoot: {
        borderRadius: 50,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    topContainer: {
        backgroundColor: theme.palette.text.secondary,
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: 70,
        },
    },
    bottomContainer: {
        backgroundColor: "#F2F2F1",
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("lg")]: {
            padding: "35px 70px",
        },
    },
    copyrightGrid: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
            textAlign: "left",
        },
    },
    policiesGrid: {
        [theme.breakpoints.up("lg")]: {
            justifyContent: "flex-end",
            textAlign: "right",
        },
    },
    bottomFooterLeft: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center !important",
        },
        [theme.breakpoints.down("md")]: {
            textAlign: "left",
            padding: "12px 0",
        },
    },
    bottomFooterRight: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "center !important",
        },
        [theme.breakpoints.down("md")]: {
            textAlign: "right",
            padding: "12px 0",
        },
    },
    footerLogo: {
        display: "block",
        [theme.breakpoints.down("xs")]: {
            height: 35,
        },
        [theme.breakpoints.up("sm")]: {
            height: 50,
        },
    },
    footerTitles: {
        padding: "24px 0",
        marginBottom: 48,
        borderBottom: "1px solid #FF5968",
    },
    listItemText: {
        "&:hover": {
            color: "#FF5968",
        },
    },
    joinUsTitle: {
        paddingTop: 18,
        marginBottom: 48,
        [theme.breakpoints.down("md")]: {
            textAlign: "left",
        },
        [theme.breakpoints.up("lg")]: {
            textAlign: "right",
        },
    },
    joinUsFields: {
        [theme.breakpoints.down("md")]: {
            justifyContent: "flex-start",
        },
        [theme.breakpoints.up("lg")]: {
            justifyContent: "flex-end",
            textAlign: "right",
        },
    },
    socialMedia: {
        [theme.breakpoints.down("md")]: {
            marginTop: 20,
            justifyContent: "flex-start",
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: 20,
            justifyContent: "flex-end",
            textAlign: "right",
        },
        [theme.breakpoints.up("xl")]: {
            justifyContent: "flex-end",
        },
    },
    socialLogo: {
        padding: 12,
    },
    input: {
        "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    brandianLink: {
        textDecoration: "none",
        fontWeight: 600,
        fontSize: 16,
        color: theme.palette.text.primary,
        "&:hover": {
            color: "#FF5968",
        },
    },
    dialogRoot: {
        [theme.breakpoints.down("md")]: {
            borderRadius: 20,
        },
        [theme.breakpoints.up("lg")]: {
            borderRadius: 50,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("md")]: {
            padding: "60px !important",
        },
    },
}));

const CustomTextField = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 50,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 50,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
})(TextField);

const Footer = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const [emailVal, setEmailVal] = useState("");
    const [open, setOpen] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [isError, setisError] = useState(false);

    const handleChangeEmail = (event) => {
        setEmailVal(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const currentYear = new Date().getFullYear();

    const sendEmail = async () => {
        if (emailVal.trim() && /.+@.+\..+/.test(emailVal)) {
            const param = { user_email: emailVal };
            await calls
                .postJoinUS(param)
                .then((response) => {
                    if (response.data) {
                        setEmailVal("");
                        setTitle("Success");
                        setDescription(t("forms.succSubs"));
                        setOpen(true);
                    }
                })
                .catch((err) => {
                    setTitle("Error");
                    setDescription(err.message);
                    setOpen(true);
                });
        } else {
            setisError(true);
        }
    };

    return (
        <div className={classes.root}>
            <Container maxWidth={false} className={classes.topContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <GcaLogoFooter className={classes.footerLogo} />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.hidden}>
                        <Typography className={classes.footerTitles} variant="h6" color="textPrimary">
                            {t("header.solutions")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <List component="ul" disablePadding>
                                    <ListItem disableGutters={true} component={Link} to="/solutions/accounting">
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItemText}
                                                    variant="body2"
                                                    color="textPrimary"
                                                    dangerouslySetInnerHTML={{ __html: t("header.submenuAcc") }}
                                                ></Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters={true} component={Link} to="/solutions/controller">
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItemText}
                                                    variant="body2"
                                                    color="textPrimary"
                                                    dangerouslySetInnerHTML={{ __html: t("header.submenuCFO") }}
                                                ></Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters={true} component={Link} to="/solutions/automation">
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItemText}
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {t("header.submenuProcess")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters={true} component={Link} to="/solutions/firms">
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItemText}
                                                    variant="body2"
                                                    color="textPrimary"
                                                    dangerouslySetInnerHTML={{ __html: t("header.submenuFirms") }}
                                                ></Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <List component="ul" disablePadding>
                                    <ListItem disableGutters={true} component={Link} to="/book-demo">
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItemText}
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {t("banner.bookDemo")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters={true} component={Link} to="/pricing">
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItemText}
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {t("header.pricing")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters={true} component={Link} to="/reviews">
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItemText}
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {t("footer.indepReviews")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem disableGutters={true} component={Link} to="/become-partner">
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItemText}
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {t("footer.becomePartner")}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.hidden}>
                        <Typography className={classes.footerTitles} variant="h6" color="textPrimary">
                            {t("header.submenuAbout")}
                        </Typography>
                        <List component="ul" disablePadding>
                            <ListItem disableGutters={true} component={Link} to="/company/about">
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.listItemText}
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {t("footer.ourCompany")}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem disableGutters={true} component={Link} to="/become-ambassador">
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.listItemText}
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {t("footer.ambassadorsProgram")}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem disableGutters={true} component={Link} to="/company/contact">
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.listItemText}
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {t("header.submenuContact")}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.hidden}>
                        <Typography className={classes.footerTitles} variant="h6" color="textPrimary">
                            {t("header.resources")}
                        </Typography>
                        <List component="ul" disablePadding>
                            <ListItem disableGutters={true} component={Link} to="/resources/faq">
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.listItemText}
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {t("footer.faq")}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem disableGutters={true} component={Link} to="/resources/customer-support">
                                <ListItemText
                                    primary={
                                        <Typography
                                            className={classes.listItemText}
                                            variant="body2"
                                            color="textPrimary"
                                            dangerouslySetInnerHTML={{ __html: t("header.submenuCustom") }}
                                        ></Typography>
                                    }
                                />
                            </ListItem>
                            {/* <ListItem disableGutters={true} component={Link} to="/resources/blog">
                                <ListItemText
                                    primary={
                                        <Typography className={classes.listItemText} variant="body2" color="textPrimary">
                                            {t('footer.blog')}
                                        </Typography>
                                    }
                                />
                            </ListItem> */}
                        </List>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Typography className={classes.joinUsTitle} variant="h5" color="secondary">
                            {t("footer.joinUs")}
                        </Typography>
                        <Grid container className={classes.joinUsFields} spacing={3} alignItems="center">
                            <Grid item xs={12} sm={8} lg={10} xl={8}>
                                <form autoComplete="off">
                                    <CustomTextField
                                        placeholder={t("footer.emailInput")}
                                        variant="outlined"
                                        fullWidth
                                        value={emailVal}
                                        onChange={handleChangeEmail}
                                        error={
                                            isError && emailVal.trim() === ""
                                                ? true
                                                : isError && !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(emailVal)
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            isError && emailVal.trim() === ""
                                                ? t("forms.forReq")
                                                : isError && !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(emailVal)
                                                ? t("error.enterEmail")
                                                : ""
                                        }
                                        InputProps={{
                                            classes: { input: classes.input },
                                        }}
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={10} xl={2}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disableElevation
                                    className={classes.btnRoot}
                                    onClick={sendEmail}
                                >
                                    {t("footer.joinNow")}
                                </Button>
                            </Grid>
                            <Grid item container xs={12} sm={8} lg={10} className={classes.socialMedia}>
                                <a
                                    href="https://www.instagram.com/gcaconsulting/"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.socialLogo}
                                >
                                    <InstagramLogo height="30" />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/gca-360/"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.socialLogo}
                                >
                                    <LinkedinLogo height="30" />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth={false} className={classes.bottomContainer}>
                <Grid container alignItems="center">
                    <Grid item container xs={12} lg={6} className={classes.copyrightGrid}>
                        <Grid item xs={12} md={6} lg={8} xl={6}>
                            <Typography variant="body2" color="textPrimary" className={classes.bottomFooterLeft}>
                                {`© ${currentYear} ${t("footer.rightsReserved")}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={6}>
                            <Typography variant="body2" color="textPrimary" className={classes.bottomFooterRight}>
                                {t("footer.designedBy")}
                                <a href="https://www.brandian.uk/" className={classes.brandianLink}>
                                    {" "}
                                    Brandian{" "}
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={6} className={classes.policiesGrid}>
                        <Grid item xs={12} md={6} lg={8}>
                            <Typography variant="body2" color="textPrimary" className={classes.bottomFooterLeft}>
                                <a
                                    href={
                                        "https://gca-website.s3.us-east-2.amazonaws.com/documents/" +
                                        t("footer.docTermsService")
                                    }
                                    className={classes.brandianLink}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {t("footer.termsService")}
                                </a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Typography variant="body2" color="textPrimary" className={classes.bottomFooterRight}>
                                <a
                                    href={
                                        "https://gca-website.s3.us-east-2.amazonaws.com/documents/" +
                                        t("footer.docPrivacyNotice")
                                    }
                                    className={classes.brandianLink}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {t("footer.privacyNotice")}
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"xs"}
                fullWidth={true}
                classes={{ paper: classes.dialogRoot }}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" style={{ textAlign: "center" }}>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                    style={{ textAlign: "center", paddingTop: 15 }}
                                >
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Footer;
