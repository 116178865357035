/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/** Icons/images */
import CheckIcon from '@material-ui/icons/Check';
import GeraldineCallesImg from '../../assets/images/our-team-geraldine-calles.jpg';

/** Components ----------------------------------------------------------- */
import {
    makeStyles, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 100,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120,
        },
    },
    secondBack: {
        [theme.breakpoints.down('md')]: {
            paddingTop: 60,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 70,
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: 90,
        },
    },
    commonWhite: {
        color: 'white',
    },
    commonCoral: {
        color: theme.palette.secondary.main,
    },
    firstT: {
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        },
    },
    secondT: {
        color: '#FFFFFF',
        paddingTop: 90,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 40
        },
        [theme.breakpoints.up('xl')]: {
            width: 750,
            fontSize: 50
        },
    },
    secondT2: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 40
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 50
        },
    },
    thirdT: {
        width: '85%',
        color: '#FFFFFF',
        paddingTop: 70,
    },
    firstT3: {
        color: '#FFFFFF',
        padding: '70px 0',
    },
    greetingText: {
        color: 'white',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '85%',
        },
    },
    teamImage: {
        borderRadius: 10,
        height: 100,
        width: 100
    },
    primaryItem: {
        color: 'white',
        fontWeight: 700,
    },
    dialogRoot: {
        [theme.breakpoints.down('md')]: {
            borderRadius: 20,
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: 50,
        },
    },
    dialogContent: {
        [theme.breakpoints.down('sm')]: {
            padding: '30px !important',
        },
        [theme.breakpoints.up('md')]: {
            padding: '60px !important',
        },
    },
    dialogFirstT: {
        paddingTop: 10,
    },
    dialogSecondT: {
        paddingTop: 10,
        paddingBottom: 40,
    },
    spacing: {
        [theme.breakpoints.down('md')]: {
            paddingTop: 90,
            paddingRight: 0,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 90,
        },
        [theme.breakpoints.up('xl')]: {
            paddingRight: 70
        },
    },
}));

const MainForm = (props) => {

    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const handleClose = () => {
        props.onChangeOpen(false);
    };

    useEffect(() => {
        if (document.getElementById('partner') !== null) {
            let checkBox = document.getElementById('partner').childNodes;
            checkBox[0].innerHTML = props.firstT6;

            let checkBox1 = document.getElementById('partnerCheck1').childNodes;
            checkBox1[0].innerHTML = props.firstT4;

            let checkBox2 = document.getElementById('partnerCheck2').childNodes;
            checkBox2[0].innerHTML = props.firstT5;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <div className={classes.root}>
            <Container maxWidth="xl" disableGutters>
                <Grid container>
                    <Grid item xs={12} md={6} xl={7}>
                        <Typography variant="h5" className={classes.firstT}>{props.firstT}</Typography>
                        <Typography variant="h3" className={classes.secondT}>{props.secondT}</Typography>
                        <Typography variant="h3" color="secondary" className={classes.secondT2}>{props.secondT2}</Typography>
                        <Typography variant="body1" className={classes.thirdT} dangerouslySetInnerHTML={{ __html: props.thirdT }}></Typography>
                        <Grid container className={ classes.spacing } spacing={2}>
                            <Grid item xs={12} lg={3} xl={2}>
                                <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                            </Grid>
                            <Grid item container xs={12} lg={9} xl={10} direction="column" justifyContent="space-between" alignItems="flex-start">
                                <Typography variant="h6" className={classes.commonWhite}>
                                    {t('forms.formHi')}
                                    <span className={classes.commonCoral}> Geraldine </span>
                                </Typography>
                                <Typography variant="body1" className={classes.greetingText} dangerouslySetInnerHTML={{ __html: props.firstT2 }}></Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.firstT3}>{props.firstT3}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <List>
                                        <ListItem disableGutters alignItems="flex-start">
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <CheckIcon fontSize="small" style={{ color: '#FFFFFF' }} />
                                            </ListItemIcon>
                                            <ListItemText id="partnerCheck1" primary="" classes={{ primary: classes.primaryItem }} />
                                        </ListItem>
                                        <ListItem disableGutters alignItems="flex-start">
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <CheckIcon fontSize="small" style={{ color: '#FFFFFF' }} />
                                            </ListItemIcon>
                                            <ListItemText id="partnerCheck2" primary="" classes={{ primary: classes.primaryItem }} />
                                        </ListItem>
                                        <ListItem disableGutters alignItems="flex-start">
                                            <ListItemIcon style={{ minWidth: 35 }}>
                                                <CheckIcon fontSize="small" style={{ color: '#FFFFFF' }} />
                                            </ListItemIcon>
                                            <ListItemText id="partner" primary="" classes={{ primary: classes.primaryItem }} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6} xl={5} justifyContent="center" className={classes.secondBack}>
                        <Grid item>
                            {props.formFields}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={props.open}
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth={true}
                classes={{ paper: classes.dialogRoot }}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12} lg={2}>
                                <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Typography variant="h6" color="textPrimary">
                                    {t('forms.formHi')}
                                    <span className={classes.commonCoral}> Geraldine </span>
                                </Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogFirstT}>{props.dialogMsg}</Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogSecondT}>{t('forms.formWeAreHappy')}</Typography>
                                <Typography variant="body1" color="textPrimary">{t('forms.formIamAvailable')}</Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default MainForm;
