/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import calls from './js/calls';

/** Icons/images */
import BannerImgBlog from '../assets/images/gca-isolated-blog.png';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import { ReactComponent as LinkedinLogo } from '../assets/images/icon-linkedin.svg';
import { ReactComponent as Link } from '../assets/images/icon-link.svg';

/** Components ----------------------------------------------------------- */
import { makeStyles, withStyles, Button, Grid, Container, TextField, InputAdornment, Typography, Divider } from '@material-ui/core';
import Banner from './submodules/Banner';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const CssTextField = withStyles({
    root: {
        backgroundColor: '#F8F9F8',
        borderRadius: 50,
        marginBottom: 15,
        '& label': {
            color: '#031851',
        },
        '& label.Mui-focused': {
            color: '#031851',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#F8F9F8',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#F8F9F8',
                borderRadius: 50
            },
            '&:hover fieldset': {
                borderColor: '#031851',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#031851',
            },
        },
        '& .MuiOutlinedInput-input': {
            paddingLeft: 20,
            paddingRight: 20
        }
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    rootBanner: {
        flexGrow: 1,
        paddingTop: 100,
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            background: '#2F3190'
        },
        [theme.breakpoints.up('lg')]: {
            background: 'linear-gradient(90deg, #2F3190 50%, #ffffff 50%)'
        },
    },
    button: {
        borderRadius: 50,
        fontSize: 18,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 90
        },
    },
    button2: {
        borderRadius: 50,
        fontSize: 18,
    },
    purpleBack: {
        [theme.breakpoints.down('md')]: {
            padding: '30px !important'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    mainBack2: {
        backgroundColor: '#F8F9F8',
        [theme.breakpoints.down('md')]: {
            padding: '30px !important'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    input: {
        '&::placeholder': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    divider: {
        background: '#ff5968',
        height: 3,
        marginBottom: 10,
    },
    divider2: {
        background: '#ff5968',
        height: 3,
    },
    image: {
        borderRadius: 50,
        backgroundColor: '#E9EAE8',
        cursor: 'pointer',
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 300
        },
        [theme.breakpoints.up('lg')]: {
            width: 380
        },
        [theme.breakpoints.up('xl')]: {
            width: 500
        },
    },
    image3: {
        borderRadius: 50,
        backgroundColor: '#E9EAE8',
        width: '100%',
        height: 'auto',
        cursor: 'pointer',
    },
    spacing: {
        marginTop: 100
    },
    spacing2: {
        padding: 30
    },
    spacing3: {
        paddingRight: 5,
        float: 'left'
    },
    spacing4: {
        marginBottom: 50,
    },
    spacing5: {
        marginBottom: 30,
    },
    socialLogo: {
        '& svg': {
            padding: '0px 50px 0px 0px',
            margin: '30px 0px 30px 0px'
        },
    },
    inline: {
        display: 'inline',
    },
}));

const Blog = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const [blogDetail, setBlogDetail] = useState(false);
    const [blogContent, setBlogContent] = useState({});
    const [blogComments, setBlogComments] = useState([]);
    const [posts, setPosts] = useState([]);

    const handleBlogDetail = async (val, id) => {
        setBlogDetail(val);
        if (val) {
            await calls.getPost(id).then((response) => {
                setBlogContent(response.data);
                document.getElementById('htmlBlog').innerHTML = response.data.post_content_html;
            }).catch((err) => {
                console.log(err.error);
            });
            await calls.getComments(id).then((response) => {
                setBlogComments(response.data);
            }).catch((err) => {
                console.log(err.error);
            });
        }
    };

    const getPostbyCat = async (val) => {
        setBlogDetail(false);
        if (val !== "All") {
            await calls.getPosts({ cat: val, search: null }).then((response) => {
                setPosts(response.data);
            }).catch((err) => {
                console.log(err.error);
            });
        }
        else {
            await calls.getPosts({ cat: null, search: null }).then((response) => {
                setPosts(response.data);
            }).catch((err) => {
                console.log(err.error);
            });
        }
    };

    const searchText = async (event) => {
        setBlogDetail(false);
        await calls.getPosts({ cat: null, search: event.target.value }).then((response) => {
            setPosts(response.data);
        }).catch((err) => {
            console.log(err.error);
        });
    }

    const putCat = (val, index) => {
        if (index === 0) {
            return val;
        }
        return ' | ' + val;
    }

    function Comments() {
        const listItems = blogComments.map((comment, index) =>
            <React.Fragment key={index.toString()}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt={comment.userName} src="" />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <>
                                <Typography
                                    component="span"
                                    variant="h6"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {comment.userName}
                                </Typography>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {' — ' + moment(comment.creationDate).format(t('bookkeeping.dateFormatText'))}
                                </Typography>
                            </>
                        }
                        secondary={
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {comment.commentContent}
                            </Typography>
                        }
                    />
                </ListItem>
                {index !== blogComments.length - 1 ?
                    <Divider variant="inset" component="li" />
                    : null
                }
            </React.Fragment>
        )
        return (
            <List className={classes.root}>{listItems}</List>
        );
    }

    function Posts() {
        const listItems = posts.map((post) =>
            <Grid item xs={12} md={4} key={post._id.toString()}>
                <img alt={post.post_title} src={post.thumbnail_url} className={classes.image} onClick={() => handleBlogDetail(true, post._id)} />
                <Typography variant="h5" className={classes.spacing2}>{post.post_title}</Typography>
                <Divider classes={{ root: classes.divider }} />
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <div className={classes.spacing3}>{post.comments_count}</div> <ChatBubbleIcon fontSize="small" />
                    </Grid>
                    <Grid item>
                        <div className={classes.spacing3}>{post.likes_count}</div> <FavoriteIcon color="secondary" fontSize="small" />
                    </Grid>
                </Grid>
            </Grid>
        )
        return (
            <Grid container spacing={6}>{listItems}</Grid>
        );
    }

    function RecentPosts() {
        const listItems = posts.map((post) =>
            <Grid item xs={12} md={6} key={post._id.toString()}>
                <img alt={post.post_title} src={post.thumbnail_url} className={classes.image3} onClick={() => handleBlogDetail(true, post._id)} />
                <Typography variant="h5" className={classes.spacing2}>{post.post_title}</Typography>
                <Divider classes={{ root: classes.divider }} />
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <div className={classes.spacing3}>{post.comments_count}</div> <ChatBubbleIcon fontSize="small" />
                    </Grid>
                    <Grid item>
                        <div className={classes.spacing3}>{post.likes_count}</div> <FavoriteIcon color="secondary" fontSize="small" />
                    </Grid>
                </Grid>
            </Grid>
        )
        return (
            <Grid container spacing={6}>{listItems}</Grid>
        );
    }

    useEffect(() => {
        (async () => {
            await calls.getPosts({ cat: null, search: null }).then((response) => {
                console.log(response.data);
                setPosts(response.data);
            }).catch((err) => {
                console.log(err.error);
            });
        })();
    }, [t]);

    let buttonGetstarted = (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={7} xl={5}>
                    <CssTextField
                        placeholder={t('footer.emailInput')}
                        variant="outlined"
                        fullWidth
                        className={classes.spacing}
                        InputProps={{
                            classes: { input: classes.input }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={5} xl={7}>
                    <Button variant="contained" color="secondary" disableElevation className={classes.button}>{t('footer.joinNow')}</Button>
                </Grid>
            </Grid>
        </>
    )

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Banner
                firstT="Blog"
                secondT={t('blog.blGet')}
                secondT2={t('blog.blFina')}
                thirdT={t('blog.blFres')}
                button={buttonGetstarted}
                firstBack={classes.purpleBack}
                persona={BannerImgBlog}
                root={classes.rootBanner}
            />
            <div className={classes.mainBack2}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button className={classes.button2} onClick={() => getPostbyCat("All")}>{t('blog.blAll')}</Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.button2} onClick={() => getPostbyCat("Smarter financials")}>{t('blog.blSmart')}</Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.button2} onClick={() => getPostbyCat("Processes automation")}>{t('blog.blPro')}</Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.button2} onClick={() => getPostbyCat("Business' scalability")}>{t('blog.blBuss')}</Button>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="input-with-icon-textfield"
                                placeholder={t('blog.blSear')}
                                onChange={searchText}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {!blogDetail ?
                <Container maxWidth="xl" disableGutters className={classes.purpleBack}>
                    <Posts />
                </Container>
                :
                <Container maxWidth="xl" disableGutters className={classes.purpleBack}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                            <Typography variant="body1">{moment(blogContent.creation_date, 'DD-MM-YYYY').format(t('bookkeeping.dateFormatText'))}</Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Typography variant="h3" className={classes.spacing4}>{blogContent.post_title}</Typography>
                            <Typography variant="h5" className={classes.spacing4}>{blogContent.post_subtitle}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container id="htmlBlog">
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider classes={{ root: classes.divider2 }} />
                            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                                <Grid item>
                                    <a href="#link" rel="noreferrer" target="_blank" className={classes.socialLogo}>
                                        <Link height="30" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/gca-360/" rel="noreferrer" target="_blank" className={classes.socialLogo} >
                                        <LinkedinLogo height="30" />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">{blogContent.id_category !== undefined ? blogContent.id_category.map(putCat) : null}</Typography>
                                </Grid>
                            </Grid>
                            <Divider classes={{ root: classes.divider }} />
                            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Typography variant="body1">{blogComments.length} comments</Typography>
                                </Grid>
                                <Grid item>
                                    <div className={classes.spacing3}>{blogContent.likes_count}</div> <FavoriteIcon color="secondary" fontSize="small" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Comments />
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2} style={{ paddingTop: 100, paddingBottom: 50 }}>
                        <Grid item>
                            <Typography variant="h6">Recent posts</Typography>
                        </Grid>
                        <Grid item>
                            <Button className={classes.button2} onClick={() => getPostbyCat("All")}>See all</Button>
                        </Grid>
                    </Grid>
                    <RecentPosts />
                </Container>
            }
        </div >
    )
}

export default Blog;
