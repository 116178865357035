/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

/** Icons/images */
import BannesImgContr from '../assets/images/gca-isolated-controller-cfo.png';
import WhyController from '../assets/images/why-controller-cfo.jpg';

/** Components ----------------------------------------------------------- */
import { makeStyles, Button } from '@material-ui/core';
import Banner from './submodules/Banner';
import EsphereSection from './submodules/EsphereSection';
import FaqSection from './submodules/FaqSection';
import HowSection from './submodules/HowSection';
import SubBanner from './submodules/SubBanner';
import WhySection from './submodules/WhySection';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    rootBanner: {
        flexGrow: 1,
        paddingTop: 100,
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            background: '#2F3190'
        },
        [theme.breakpoints.up('lg')]: {
            background: 'linear-gradient(90deg, #2F3190 50%, #031851 50%)'
        },
    },
    bannerButton: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 60,
            fontSize: 18
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 150,
        },
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 60,
            fontSize: 18
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 120,
        },
    },
    purpleBack: {
        [theme.breakpoints.down('md')]: {
            padding: '30px !important'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    firstT3: {
        paddingTop: 100,
        [theme.breakpoints.up('lg')]: {
            height: 170,
        },
    },
    secondT2:{
        marginBottom: 50,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '85%',
            height: 66,
        },
        [theme.breakpoints.up('xl')]: {
            height: 'auto',
        },
    },
}));

const Controller = () => {
    const classes = useStyles();
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    useEffect(() => {

    }, [t]);

    let buttonBookDemo = (
        <Button component={Link} to="/book-demo" variant="contained" color="secondary" 
            disableElevation className={classes.bannerButton}
        >
            {t('banner.bookDemo')}
        </Button>
    );

    let buttonPersonalizeService = (
        <Button component={Link} to="/pricing" variant="contained" color="secondary" 
            disableElevation className={classes.button}
        >
            {t('howSection.howServ')}
        </Button>
    );

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Banner
                firstT={t('header.submenuCFO')}
                secondT={t('controller.contController')}
                secondT2={t('controller.contGrowth')}
                thirdT={t('controller.contOff')}
                button={buttonBookDemo}
                firstBack={classes.purpleBack}
                persona={BannesImgContr}
                root={classes.rootBanner}
            />
            <SubBanner
                firstT={t('subBanner.subWhat')}
                secondT={t('controller.contServ')}
                thirdT={t('controller.contTeam')}
                firstT2={t('subBanner.subCharge')}
                secondT2={t('controller.contAcco')}
                thirdT2={t('controller.contProv')}
                firstT3={t('subBanner.subWhere')}
                secondT3={t('controller.contAdapt')}
                thirdT3={t('controller.contProj')}
                subBannerT2Class={classes.secondT2}
            />
            <WhySection
                firstMain={t('controller.contWhy')}
                firstT={t('controller.contExten')}
                secondT={t('controller.contFinan')}
                thirdT={t('controller.contExpert')}
                firstT2={t('controller.contHouse')}
                secondT2={t('controller.contTech')}
                thirdT2={t('controller.contAble')}
                firstT3={t('controller.contReg')}
                secondT3={t('controller.contPre')}
                thirdT3={t('controller.contOur')}
                image={WhyController}
                button={t('header.getStarted')}
                firstT3Class={classes.firstT3}
            />
            <HowSection
                firstMain={t('howSection.howWork')}
                firstT={t('controller.contPer')}
                secondT={t('howSection.howBus')}
                thirdT={t('controller.contFinRep')}
                firstT2={t('controller.contPlan')}
                secondT2={t('howSection.howApp')}
                thirdT2={t('controller.contClear')}
                button={buttonPersonalizeService}
            />
            <EsphereSection
                firstMain={t('esphereSection.esphereShap')}
                firstT={t('esphereSection.esphereExp')}
                secondT={t('esphereSection.esphereTech')}
                thirdT={t('esphereSection.esphereSoft')}
                firstT2={t('esphereSection.esphereCons')}
                secondT2={t('esphereSection.esphereMan')}
                thirdT2={t('esphereSection.espherePro')}
                thirdT3={t('esphereSection.esphereAcc')}
            />
            <FaqSection
                firstMain={t('faq.faq')}
                firstT={t('controller.contVital')}
                secondT={t('controller.contDiff')}
                thirdT={t('controller.contVary')}
                firstT2={t('faq.faqPrice')}
                secondT2={t('controller.contBased')}
                thirdT2={t('faq.faqControlQ7')}
                thirdT3={t('controller.contInfo')}
                fourth1={t('faq.faqSupp')}
                fourth2={t('controller.contYes')}
                more={t('faq.faqMore')}
                section={'contr'}
            />
        </div>
    )
}

export default Controller;