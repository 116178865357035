/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/** Icons/images */
import {ReactComponent as ExpediteIcon} from '../assets/images/ico-expedite.svg';
import {ReactComponent as SpecializedIcon} from '../assets/images/ico-specialized.svg';
import {ReactComponent as UnlimitedIcon} from '../assets/images/ico-unlimited.svg';
import BannerImgAuto from '../assets/images/gca-home-01.png';
import SecondGraph from '../assets/images/secondary-graphic-version-2.svg';

/** MUI Icons */
import CheckIcon from '@material-ui/icons/Check';

/** MUI imports */
import { 
    makeStyles,
    Button,
    Card, 
    CardHeader, 
    CardContent, 
    Container, 
    Divider, 
    Grid, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    Paper, 
    Typography 
} from '@material-ui/core';

/** Components imports */
import Banner from './submodules/Banner';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    rootBanner: {
        flexGrow: 1,
        paddingTop: 100,
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            background: '#2F3190'
        },
        [theme.breakpoints.up('lg')]: {
            background: '#2F3190'
        },
    },
    commonWhite: {
        color: '#fff'
    },
    firstT1: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        }
    },
    purpleBack: {
        [theme.breakpoints.down('md')]: {
            padding: '30px !important'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    secondBack: {
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: 70,
        },
        [theme.breakpoints.up('xl')]: {
            margin: 120
        },
    },
    secondBack2: {
        backgroundColor: '#F8F9F8',
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120
        },
    },
    secondT: {
        paddingBottom: 30
    },
    firstT: {
        paddingTop: 90,
        [theme.breakpoints.up('lg')]: {
            width: 480,
        },
        [theme.breakpoints.up('xl')]: {
            width: 650,
        },
    },
    thirdT: {
        paddingBottom: 120
    },
    primaryItem: {
        width: '90%',
    },
    availableText: {
        fontWeight: '400 !important',
    },
    iconSize: {
        height: 30,
    },
    divider: {
        width: '7%',
        height: 3,
        background: '#ff5968',
        borderRadius: 40,
        marginBottom: 20,
    },
    dividerList: {
        width: '12%',
        height: 3,
        background: '#ff5968',
        borderRadius: 40,
        marginTop: 100,
        marginBottom: 20,
    },
    dividerList2: {
        width: '18%',
        height: 3,
        background: '#ff5968',
        borderRadius: 40,
        marginBottom: 20,
    },
    paper: {
        height: '100%',
        width: '300px',
        borderRadius: 30,
        background: theme.palette.text.primary,
        [theme.breakpoints.down('md')]: {
            padding: '30px !important',
            marginBottom: 30
        },
        [theme.breakpoints.up('sm')]: {
            padding: '30px !important',
        },
    },
    paper2: {
        padding: '8px 30px',
        marginBottom: 60,
        borderRadius: 40,
        boxShadow: '10px 10px 15px rgba(3,24,81,0.10)',
        background: '#F8F9F8',
    },
    cardRoot: {
        height: '100%',
        padding: '30px !important',
        borderRadius: 30,
        background: '#fff',
        boxShadow: '10px 10px 15px rgba(3,24,81,0.15)',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.up('lg')]: {
            width: "90%",
        },
    },
    cardsContainer: {
        paddingBottom: 70,
        [theme.breakpoints.down('md')]: {
            paddingTop: 60,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 70,
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: 120
        },
    },
    graphSection: {
        paddingTop: 100,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 60,
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: 0,
        },
    },
    secondGraphImg: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: 150,
            display: 'none',
            marginLeft: -30,
            zIndex: 2
        },
        [theme.breakpoints.up('md')]: {
            width: 300,
            marginLeft: -70,
        },
        [theme.breakpoints.up('lg')]: {
            width: 400,
            marginLeft: -120,
        },
        [theme.breakpoints.up('xl')]: {
            width: 500,
            marginLeft: -120,
        },
    },
    button: {
        borderRadius: 50,
        padding: '4px 16px',
        margin: '0px 20px',
        fontSize: 16,
        '&:hover': {
            backgroundColor: '#2f3190',
            color: '#fff',
        },
    },
    spacing: {
        paddingBottom: 20
    },
    spacing2: {
        paddingTop: 30,
        paddingBottom: 30,
    },
}));

const Automation = () => {
    const classes = useStyles();
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component functions */
    useEffect(() => {
    }, [t]);

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Banner
                firstT={t('header.submenuCustom')}
                secondT={t('support.suppRenewed')}
                secondT2={t('support.suppSolution')}
                thirdT={t('support.suppWeAre')}
                firstBack={classes.purpleBack}
                persona={BannerImgAuto}
                root={classes.rootBanner}
            />
            <div className={classes.secondBack}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.firstT1} dangerouslySetInnerHTML={{ __html: t('support.suppTeam') }}></Typography>
                            <Typography variant="h3" className={classes.firstT1} dangerouslySetInnerHTML={{ __html: t('support.suppBusiness') }}></Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={10} className={classes.cardsContainer}>
                        <Grid item>
                            <Paper elevation={0} className={classes.paper}>
                                <SpecializedIcon className={classes.iconSize} />
                                <Typography variant="h5" color="secondary" className={classes.spacing2} dangerouslySetInnerHTML={{ __html: t('support.suppSpecialized') }}></Typography>
                                <Typography variant="body1" className={classes.commonWhite} dangerouslySetInnerHTML={{ __html: t('support.suppSpecializedText') }}></Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={0} className={classes.paper}>
                                <UnlimitedIcon className={classes.iconSize} />
                                <Typography variant="h5" color="secondary" className={classes.spacing2} dangerouslySetInnerHTML={{ __html: t('support.suppUnlimited') }}></Typography>
                                <Typography variant="body1" className={classes.commonWhite} dangerouslySetInnerHTML={{ __html: t('support.suppUnlimitedText') }}></Typography>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <Paper elevation={0} className={classes.paper}>
                                <ExpediteIcon className={classes.iconSize} />
                                <Typography variant="h5" color="secondary" className={classes.spacing2} dangerouslySetInnerHTML={{ __html: t('support.suppExpedite') }}></Typography>
                                <Typography variant="body1" className={classes.commonWhite} dangerouslySetInnerHTML={{ __html: t('support.suppExpediteText') }}></Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider classes={{ root: classes.divider }} />
                            <Typography variant="h5" className={classes.availableText} dangerouslySetInnerHTML={{ __html: t('support.suppAvailable') }}></Typography>
                            <Typography variant="h5" dangerouslySetInnerHTML={{ __html: t('support.suppSchedule') }}></Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" className={classes.graphSection}>
                        <Grid item xs={5}>
                            <img src={SecondGraph} alt="isotype" className={classes.secondGraphImg} />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Typography variant="h3" className={classes.firstT1} dangerouslySetInnerHTML={{ __html: t('support.suppWhatDoes') }}></Typography>
                            <Typography variant="h3" className={classes.firstT1} dangerouslySetInnerHTML={{ __html: t('support.suppTeamDoFor') }}></Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={5}>

                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Paper elevation={0} className={classes.paper2}>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppIncidents')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </Paper>
                            <Paper elevation={0} className={classes.paper2}>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppProvideTechnical')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </Paper>
                            <Paper elevation={0} className={classes.paper2}>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppShares')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </Paper>
                            <Paper elevation={0} className={classes.paper2}>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppOnboard')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </Paper>
                            <Paper elevation={0} className={classes.paper2}>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppTrain')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </Paper>
                            <Paper elevation={0} className={classes.paper2}>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppInclude')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </Paper>
                            <Paper elevation={0} className={classes.paper2}>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppProvideInfo')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </Paper>
                            <Divider classes={{ root: classes.dividerList }} />
                            <Typography variant="h5" className={classes.availableText} dangerouslySetInnerHTML={{ __html: t('support.suppPleaseSend') }}></Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.secondBack2}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.firstT1} dangerouslySetInnerHTML={{ __html: t('support.suppHowWork') }}></Typography>
                            <Typography variant="h3" className={classes.firstT1} dangerouslySetInnerHTML={{ __html: t('support.suppRestTeam') }}></Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={10} className={classes.cardsContainer}>
                        <Grid item xs={12} lg={7}>
                            <Card elevation={0} className={classes.cardRoot}>
                                <CardHeader
                                    title={<Typography variant="h5" color="secondary" dangerouslySetInnerHTML={{ __html: t('support.suppSMBsAdvisor') }}></Typography>}
                                    className={classes.secondT}
                                />
                                <CardContent>
                                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t('support.suppSMBsAdvisorText') }}></Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Divider classes={{ root: classes.dividerList2 }} />
                            <List>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography component="span" variant="body1" dangerouslySetInnerHTML={{ __html: t('support.suppSMBsAdvisorList') }}></Typography>} 
                                        classes={{ primary: classes.primaryItem }} 
                                    />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppSMBsAdvisorList2')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppSMBsAdvisorList3')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppSMBsAdvisorList4')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Card elevation={0} className={classes.cardRoot}>
                                <CardHeader
                                    title={
                                        <Grid container spacing={2} justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="h5" color="secondary" dangerouslySetInnerHTML={{ __html: t('support.suppCustomerTeam') }}></Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    disableElevation 
                                                    href="mailto:support@gca.digital"
                                                    className={classes.button}
                                                >
                                                    support@gca.digital
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    className={classes.secondT}
                                />
                                <CardContent>
                                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t('support.suppCustomerTeamText') }}></Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Divider classes={{ root: classes.dividerList2 }} />
                            <List className={classes.spacing}>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppCustomerList')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppCustomerList2')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppCustomerList3')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppCustomerList4')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </List>
                            <Typography variant='body2'>{t('support.suppCustomerNewServ')}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Card elevation={0} className={classes.cardRoot}>
                                <CardHeader
                                    title={
                                        <Grid container spacing={2} justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="h5" color="secondary" dangerouslySetInnerHTML={{ __html: t('support.suppBusinessTeam') }}></Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    disableElevation 
                                                    href="mailto:admin@gca.digital"
                                                    className={classes.button}
                                                >
                                                    admin@gca.digital
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    className={classes.secondT}
                                />
                                <CardContent>
                                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t('support.suppBusinessTeamText') }}></Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Divider classes={{ root: classes.dividerList2 }} />
                            <List>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppBusinessTeamList')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('support.suppBusinessTeamList2')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div >
    )
}

export default Automation;
