/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** Icons/images */
import Background from "../assets/images/contact-get-in-touch.jpg";
import { ReactComponent as Map } from "../assets/images/graphic-map-offices.svg";
import GeraldineCallesImg from "../assets/images/our-team-geraldine-calles.jpg";

/** Components ----------------------------------------------------------- */
import {
    makeStyles,
    withStyles,
    Button,
    Grid,
    Typography,
    Paper,
    TextField,
    Divider,
    Container,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Backdrop from "./Backdrop";
import calls from "./js/calls";

const CssTextField = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 50,
        marginBottom: 15,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 50,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
})(TextField);

const CssTextField2 = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 15,
        marginBottom: 10,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 10,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 5,
            paddingRight: 5,
        },
    },
})(TextField);

const CssTextField3 = withStyles({
    root: {
        backgroundColor: "white",
        borderRadius: 50,
        marginBottom: 15,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 50,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: 100,
        "& p": {
            lineHeight: 1.2,
        },
        backgroundColor: "white",
    },
    commonWhite: {
        color: "#fff",
    },
    button: {
        borderRadius: 50,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 40,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    button2: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        marginTop: 70,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    button3: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: 30,
        },
    },
    button4: {
        borderRadius: 50,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: 30,
        },
    },
    button5: {
        borderRadius: 50,
        paddingLeft: 30,
        paddingRight: 30,
        fontSize: 18,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: 30,
        },
    },
    mainBack: {
        background: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(" + Background + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: 120,
        },
    },
    subBack: {
        backgroundColor: "#F8F9F8",
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: 70,
        },
        [theme.breakpoints.up("xl")]: {
            padding: 120,
        },
    },
    finalBack: {
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: 70,
        },
        [theme.breakpoints.up("xl")]: {
            padding: 120,
        },
    },
    firstBack: {
        paddingTop: "150px !important",
    },
    firstBack2: {
        paddingTop: "80px !important",
    },
    secondBack: {
        padding: "80px 0",
    },
    firstT: {
        [theme.breakpoints.down("lg")]: {
            fontSize: 40,
        },
    },
    firstT2: {
        paddingTop: 70,
    },
    secondT: {
        paddingTop: 100,
    },
    paperCustom: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: "10px 10px 15px rgba(2,12,15,0.60)",
        [theme.breakpoints.down("xs")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            borderRadius: 20,
            padding: "55px 30px !important",
            width: "70%",
        },
        [theme.breakpoints.up("md")]: {
            borderRadius: 40,
            padding: "55px 30px !important",
            width: "45%",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "75px !important",
        },
        [theme.breakpoints.up("xl")]: {
            width: "40%",
        },
    },
    formSpacing: {
        [theme.breakpoints.down("md")]: {
            paddingBottom: 30,
        },
        [theme.breakpoints.up("lg")]: {
            paddingBottom: 35,
        },
        [theme.breakpoints.up("xl")]: {
            paddingBottom: 45,
        },
    },
    spacing: {
        paddingBottom: 20,
    },
    spacing2: {
        marginBottom: 50,
    },
    spacing3: {
        marginTop: 30,
    },
    spacing4: {
        [theme.breakpoints.down("md")]: {
            paddingBottom: 60,
        },
        [theme.breakpoints.up("xl")]: {
            paddingBottom: 40,
        },
    },
    spacing5: {
        marginBottom: 30,
    },
    divider: {
        background: "#ff5968",
        height: 4,
        marginTop: 70,
        marginBottom: 70,
    },
    map: {
        [theme.breakpoints.down("md")]: {
            height: 400,
        },
        [theme.breakpoints.up("lg")]: {
            height: 550,
        },
        [theme.breakpoints.up("xl")]: {
            height: 550,
        },
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 80,
    },
    input: {
        "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    dialogRoot: {
        [theme.breakpoints.down("md")]: {
            borderRadius: 20,
        },
        [theme.breakpoints.up("lg")]: {
            borderRadius: 50,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("md")]: {
            padding: "60px !important",
        },
    },
}));

const Contact = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [name, setName] = useState("");

    const [last, setLast] = useState("");

    const [email, setEmail] = useState("");

    const [phone, setPhone] = useState("");

    const [message, setMessage] = useState("");

    const [open, setOpen] = useState(false);

    const [alertMess, setAlertMess] = useState("");

    const [isError, setisError] = useState(false);

    const [loading, showLoading] = useState(false);

    const [emailJoin, setemailJoin] = useState("");

    const [isErrorJoin, setisErrorJoin] = useState(false);

    const handleChangeEmailJoin = (event) => {
        setemailJoin(event.target.value);
    };

    const clearInputs = () => {
        setName("");
        setLast("");
        setEmail("");
        setPhone("");
        setMessage("");
        setemailJoin("");
        setisError(false);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeLast = (event) => {
        setLast(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };
    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sendEmail = async () => {
        if (name.trim() && last.trim() && email.trim() && /.+@.+\..+/.test(email)) {
            showLoading(true);

            const param = {
                firstName: name,
                lastName: last,
                emailAddress: email,
                phoneNumber: phone,
                message: message,
            };

            await calls
                .postContact(param)
                .then((response) => {
                    if (response.data) {
                        setAlertMess(t("forms.forMail"));
                        setOpen(true);
                        clearInputs();
                    } else {
                        setAlertMess(t("forms.forError"));
                        setOpen(true);
                    }
                })
                .catch((err) => {
                    setAlertMess(err.message);
                    setOpen(true);
                });

            showLoading(false);
        } else {
            setisError(true);
        }
    };

    const sendEmailJoin = async () => {
        if (emailJoin.trim() !== "") {
            if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(emailJoin)) {
                const param = { user_email: emailJoin };
                await calls
                    .postJoinUS(param)
                    .then((response) => {
                        if (response.data) {
                            clearInputs();
                            setAlertMess(t("forms.forMail"));
                            setOpen(true);
                        }
                    })
                    .catch((err) => {
                        setAlertMess(err.error);
                        setOpen(true);
                    });
            } else {
                setisErrorJoin(true);
            }
        } else {
            setisErrorJoin(true);
        }
    };

    useEffect(() => {}, [t]);

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <div className={classes.mainBack}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h3" className={`${classes.commonWhite} ${classes.firstT}`}>
                                {t("contact.contWou")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2} className={classes.firstBack2}>
                        <Grid item>
                            <Typography variant="body1" className={classes.commonWhite}>
                                {t("contact.contWe")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.secondBack}>
                        <Grid item>
                            <Paper elevation={3} className={classes.paperCustom}>
                                <Typography variant="h5" color="secondary" className={classes.formSpacing}>
                                    {t("contact.contDrop")}
                                </Typography>
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookFirst")}
                                    variant="outlined"
                                    value={name}
                                    onChange={handleChangeName}
                                    error={isError && !name.trim()}
                                    helperText={isError && !name.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookLast")}
                                    variant="outlined"
                                    value={last}
                                    onChange={handleChangeLast}
                                    error={isError && !last.trim()}
                                    helperText={isError && !last.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookEmail")}
                                    variant="outlined"
                                    onChange={handleChangeEmail}
                                    value={email}
                                    error={isError && (!email.trim() || !/.+@.+\..+/.test(email))}
                                    helperText={
                                        isError && !email.trim()
                                            ? t("forms.forReq")
                                            : isError && !/.+@.+\..+/.test(email)
                                            ? t("error.enterEmail")
                                            : ""
                                    }
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookPhone")}
                                    variant="outlined"
                                    className={classes.spacing2}
                                    value={phone}
                                    onChange={handleChangePhone}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <CssTextField2
                                    fullWidth
                                    placeholder={t("contact.contLeave")}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={handleChangeMessage}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.button2}
                                            onClick={sendEmail}
                                        >
                                            {t("contact.contSend")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.subBack}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h5">{t("footer.joinUs")}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} md={4} xl={3}>
                            <CssTextField3
                                fullWidth
                                value={emailJoin}
                                onChange={handleChangeEmailJoin}
                                error={
                                    isErrorJoin && emailJoin.trim() === ""
                                        ? true
                                        : isErrorJoin && !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(emailJoin)
                                        ? true
                                        : false
                                }
                                helperText={
                                    isErrorJoin && emailJoin.trim() === ""
                                        ? t("forms.forReq")
                                        : isErrorJoin && !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(emailJoin)
                                        ? t("error.enterEmail")
                                        : ""
                                }
                                placeholder={t("footer.emailInput")}
                                variant="outlined"
                                className={classes.spacing3}
                                InputProps={{
                                    classes: { input: classes.input },
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.button}
                                onClick={sendEmailJoin}
                            >
                                {t("footer.joinNow")}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth="xl" className={classes.finalBack}>
                <Grid container spacing={2} className={classes.spacing4}>
                    <Grid item>
                        <Typography variant="h3" className={classes.firstT}>
                            {t("contact.contAdd")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5" color="secondary" className={classes.spacing5}>
                                    {t("banner.bookDemo")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    component={Link}
                                    to="/book-demo"
                                    variant="contained"
                                    color="secondary"
                                    disableElevation
                                    className={classes.button3}
                                >
                                    {t("bookkeeping.bookBook")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Typography variant="body1">{t("contact.contReq")}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider classes={{ root: classes.divider }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5" color="secondary" className={classes.spacing5}>
                                    {t("footer.becomePartner")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    component={Link}
                                    to="/become-partner"
                                    variant="contained"
                                    color="secondary"
                                    disableElevation
                                    className={classes.button4}
                                >
                                    {t("contact.contPart")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Typography variant="body1">{t("contact.contDis")}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider classes={{ root: classes.divider }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h5" color="secondary" className={classes.spacing5}>
                                    {t("contact.contBec")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    component={Link}
                                    to="/become-ambassador"
                                    variant="contained"
                                    color="secondary"
                                    disableElevation
                                    className={classes.button5}
                                >
                                    {t("contact.contBeco")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Typography variant="body1">{t("contact.contJoin")}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Map className={classes.map} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" color="secondary" className={classes.secondT}>
                                    hola@gca.digital
                                </Typography>
                                <Typography variant="h5" className={classes.firstT2}>
                                    Miami
                                </Typography>
                                <Typography variant="body1" className={classes.spacing}>
                                    {t("contact.contUSA")}
                                </Typography>
                                <Typography variant="body1">+1(786)538-7649</Typography>
                                <Typography variant="h5" className={classes.firstT2}>
                                    {t("contact.contCity")}
                                </Typography>
                                <Typography variant="body1" className={classes.spacing}>
                                    {t("contact.contMex")}
                                </Typography>
                                <Typography variant="body1">+52(55)5213-4498</Typography>
                                <Typography variant="h5" className={classes.firstT2}>
                                    Caracas
                                </Typography>
                                <Typography variant="body1" className={classes.spacing}>
                                    Venezuela
                                </Typography>
                                <Typography variant="body1">+58(212)953-6051</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
                fullWidth={true}
                classes={{ paper: classes.dialogRoot }}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12} lg={2}>
                                <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Typography variant="h6" color="textPrimary">
                                    {t("forms.formHi")}
                                    <span className={classes.commonCoral}> Geraldine </span>
                                </Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogFirstT}>
                                    {alertMess}
                                </Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogSecondT}>
                                    {t("forms.formWeAreHappy")}
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {t("forms.formIamAvailable")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Backdrop open={loading} />
        </div>
    );
};

export default Contact;
