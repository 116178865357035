/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/** Icons/images */
import CheckIcon from '@material-ui/icons/Check';
import RecordIcon from '@material-ui/icons/FiberManualRecord';
import GeraldineCallesImg from '../../assets/images/our-team-geraldine-calles.jpg';

/** Components ----------------------------------------------------------- */
import { 
    makeStyles, Container, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText, Typography,
    Table, TableBody, TableCell, TableContainer, TableRow 
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const createData = (name, average) => {
    return { name, average };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 100,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70,
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120,
        },
    },
    secondBack: {
        [theme.breakpoints.down('md')]: {
            paddingTop: 60,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 70,
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: 120,
        },
    },
    greyBack1: {
        height: '100%',
        backgroundColor: '#E9EAE8',
        borderRadius: '50px 0 0 50px',
        padding: 40,
    },
    greyBack2: {
        height: '100%',
        backgroundColor: '#F2F2F1',
        borderRadius: '50px 0 0 50px',
        padding: 40,
        [theme.breakpoints.up('lg')]: {
            marginLeft: -45,
        },
    },
    greyBack3: {
        height: '100%',
        backgroundColor: '#F8F9F8',
        borderRadius: 50,
        padding: 40,
        [theme.breakpoints.up('lg')]: {
            marginLeft: -45,
        },
    },
    oval: {
        width: 30,
        height: '115%',
        borderRadius: 50,
        backgroundColor: '#E9EAE8',
    },
    oval2: {
        width: 30,
        height: '115%',
        borderRadius: 50,
        backgroundColor: '#F2F2F1',
    },
    oval3: {
        width: 30,
        height: '100%',
        borderRadius: 50,
        backgroundColor: '#F8F9F8',
    },
    ovalItemMobile: {
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0 40px 30px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '40px 0',
        },
    },
    ovalItemTitle: {
        color: '#FFFFFF',
        fontSize: '20px !important',
        paddingBottom: 30,
    },
    backTitle: {
        paddingBottom: 10,
        [theme.breakpoints.down('lg')]: {
            fontSize: 20,
        },
    },
    commonWhite: {
        color: 'white',
    },
    commonCoral: {
        color: theme.palette.secondary.main,
    },
    firstT: {
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        },
    },
    secondT: {
        color: '#FFFFFF',
        paddingTop: 90,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 40
        },
        [theme.breakpoints.up('md')]: {
            width: 760,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 50
        },
    },
    secondT2: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 40
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 50
        },
    },
    thirdT: {
        color: '#FFFFFF',
        paddingTop: 70,
    },
    greetingText: {
        color: 'white',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '85%',
        },
    },
    teamImageContainer: {
        width: '150px',
    },
    teamImage: {
        borderRadius: 10,
        height: 100,
        width: 100,
    },
    ambassadorItems: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 70,
            borderLeft: '4px solid #ff5968'
        },
    },
    table: {
        margin: '8px 30px 16px',
        [theme.breakpoints.up('xs')]: {
            width: '85%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '495px',
        },
    },
    tableCell: {
        padding: '8px 0 !important',
        borderBottom: 'none',
        color: 'white'
    },
    dialogRoot: {
        [theme.breakpoints.down('md')]: {
            borderRadius: 20,
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: 50,
        },
    },
    dialogContent: {
        [theme.breakpoints.down('sm')]: {
            padding: '30px !important',
        },
        [theme.breakpoints.up('md')]: {
            padding: '60px !important',
        },
    },
    dialogFirstT: {
        paddingTop: 10,
    },
    dialogSecondT: {
        paddingTop: 10,
    },
    dialogThirdT: {
        paddingTop: 40,
    },
    spacing: {
        [theme.breakpoints.down('md')]: {
            paddingTop: 70,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 90,
        },
    },
    spacing2: {
        width: '90%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 70,
    },
    spacing3: {
        paddingBottom: 70,
    },
}));

const BasicForm = (props) => {

    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const rows = [
        createData(t('forms.formAccIndp'), 50),
        createData(t('header.submenuCFO'), 100),
        createData(t('header.submenuProcess'), 30),
        createData(t('header.submenuFirms'), 10),
    ];

    const handleClose = () => {
        props.onChangeOpen(false);
    };

    useEffect(() => {

    }, [t]);

    return (
        <div className={classes.root}>
            <Container maxWidth="xl" disableGutters>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.firstT} dangerouslySetInnerHTML={{ __html: props.firstT }}></Typography>
                        <Typography variant="h3" className={classes.secondT}>{props.secondT}</Typography>
                        <Typography variant="h3" color="secondary" className={classes.secondT2}>{props.secondT2}</Typography>
                        <Typography variant="body1" className={classes.thirdT} dangerouslySetInnerHTML={{ __html: props.thirdT }}></Typography>
                        {props.name === 'ambassador' ? (
                            /** Become an ambassador form frame */
                            <Container maxWidth="xl" disableGutters className={classes.spacing}>
                                <Grid container spacing={2}>
                                    <Grid item className={classes.teamImageContainer}>
                                        <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                                    </Grid>
                                    <Grid item container xs={12} lg={10} direction="column" justifyContent="space-between" alignItems="flex-start">
                                        <Typography variant="h6" className={classes.commonWhite}>
                                            {t('forms.formHi')}
                                            <span className={classes.commonCoral}> Geraldine </span>
                                        </Typography>
                                        <Typography variant="body1" className={classes.greetingText} dangerouslySetInnerHTML={{ __html: props.firstT2 }}></Typography>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.spacing}>
                                    <Hidden only={['xs', 'sm', 'md']}>
                                        <div className={classes.spacing2}>
                                            <Grid container>
                                                <Grid item xs={12} lg={4}>
                                                    <div className={classes.greyBack1}>
                                                        <Typography variant="h5" className={classes.backTitle} dangerouslySetInnerHTML={{ __html: t('forms.formPerfomer') }}></Typography>
                                                        <List>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.form100points')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formComission5')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formPaidQueterly')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formSalesResources')} />
                                                            </ListItem>
                                                        </List>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <div className={classes.greyBack2}>
                                                        <Typography variant="h5" className={classes.backTitle} dangerouslySetInnerHTML={{ __html: t('forms.formNetworker') }}></Typography>
                                                        <List>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.form500points')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formComission10')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formPaidEvery')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formSalesResources')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formHandsTraining')} />
                                                            </ListItem>
                                                        </List>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                    <div className={classes.greyBack3}>
                                                        <Typography variant="h5" className={classes.backTitle} dangerouslySetInnerHTML={{ __html: t('forms.formInfluencer') }}></Typography>
                                                        <List>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formMore500points')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formComission18')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formPaidMonthly')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formSalesResources')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formHandsTraining')} />
                                                            </ListItem>
                                                            <ListItem disableGutters alignItems="flex-start">
                                                                <ListItemIcon style={{ minWidth: 30 }}>
                                                                    <CheckIcon fontSize="small" style={{ color: '#031851', fontSize: 18 }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={t('forms.formDedicatedManager')} />
                                                            </ListItem>
                                                        </List>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Hidden>
                                    <Hidden only={['lg', 'xl']}>
                                        <Grid container item xs={12} className={classes.spacing3}>
                                            <Grid item xs={1}>
                                                <div className={classes.oval}></div>
                                            </Grid>
                                            <Grid item xs={11} className={classes.ovalItemMobile}>
                                                <Typography variant="h5" className={classes.ovalItemTitle} dangerouslySetInnerHTML={{ __html: t('forms.formPerfomer') }}></Typography>
                                                <List>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.form100points')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formComission5')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formPaidQueterly')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formSalesResources')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <div className={classes.oval2}></div>
                                            </Grid>
                                            <Grid item xs={11} className={classes.ovalItemMobile}>
                                                <Typography variant="h5" className={classes.ovalItemTitle} dangerouslySetInnerHTML={{ __html: t('forms.formNetworker') }}></Typography>
                                                <List>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.form500points')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formComission10')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formPaidEvery')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formSalesResources')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formHandsTraining')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <div className={classes.oval3}></div>
                                            </Grid>
                                            <Grid item xs={11} className={classes.ovalItemMobile}>
                                                <Typography variant="h5" className={classes.ovalItemTitle} dangerouslySetInnerHTML={{ __html: t('forms.formInfluencer') }}></Typography>
                                                <List>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formMore500points')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formComission18')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formPaidMonthly')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formSalesResources')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formHandsTraining')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                    <ListItem disableGutters alignItems="flex-start">
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            <CheckIcon fontSize="small" style={{ color: '#fff', fontSize: 18 }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('forms.formDedicatedManager')} classes={{ primary: classes.commonWhite }} />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12} className={classes.ambassadorItems}>
                                        <List>
                                            <ListItem disableGutters alignItems="flex-start">
                                                <ListItemIcon style={{ minWidth: 25, marginTop: 14 }}>
                                                    <RecordIcon fontSize="small" style={{ color: '#FFFFFF', fontSize: 8 }} />
                                                </ListItemIcon>
                                                <ListItemText primary={props.firstT4} classes={{ primary: classes.commonWhite }} />
                                            </ListItem>
                                            <ListItem disableGutters alignItems="flex-start">
                                                <ListItemIcon style={{ minWidth: 25, marginTop: 14 }}>
                                                    <RecordIcon fontSize="small" style={{ color: '#FFFFFF', fontSize: 8 }} />
                                                </ListItemIcon>
                                                <ListItemText primary={props.firstT5} classes={{ primary: classes.commonWhite }} />
                                            </ListItem>
                                            <ListItem disableGutters alignItems="flex-start">
                                                <ListItemIcon style={{ minWidth: 25, marginTop: 14 }}>
                                                    <RecordIcon fontSize="small" style={{ color: '#FFFFFF', fontSize: 8 }} />
                                                </ListItemIcon>
                                                <ListItemText primary={props.firstT6} classes={{ primary: classes.commonWhite }} />
                                            </ListItem>
                                        </List>
                                        <TableContainer>
                                            <Table className={classes.table} size="small" aria-label="average table">
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <TableRow key={row.name}>
                                                            <TableCell component="th" scope="row" classes={{ root: classes.tableCell }} 
                                                                dangerouslySetInnerHTML={{ __html: row.name }}
                                                            >
                                                            </TableCell>
                                                            <TableCell align="right" classes={{ root: classes.tableCell }}>
                                                                {row.average}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Container>
                        ): null}
                    </Grid>
                    <Grid item container xs={12} justifyContent="center" className={classes.secondBack}>
                        <Grid item>
                            {props.formFields}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={props.open}
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth={true}
                classes={{ paper: classes.dialogRoot }}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText>
                        <Grid container>
                            <Grid item xs={12} lg={2}>
                                <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage}/>
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <Typography variant="h6" color="textPrimary">
                                    {t('forms.formHi')}
                                    <span className={classes.commonCoral}> Geraldine </span>
                                </Typography>
                                <Typography variant="body1" color="textPrimary" className={classes.dialogFirstT}>{props.dialogMsg}</Typography>
                                {props.name === 'autoQuote' ? (
                                    <Typography variant="body1" color="textPrimary" className={classes.dialogSecondT}>{t('forms.formWeAreHappy')}</Typography>
                                ): null}
                                <Typography variant="body1" color="textPrimary" className={classes.dialogThirdT} dangerouslySetInnerHTML={{ __html: props.dialogMsg2 }}></Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default BasicForm;
