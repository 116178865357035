/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

/** Components ----------------------------------------------------------- */
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './App';
import theme from './themeSettings';
import calls from './components/js/calls';

/** Global variables ----------------------------------------------------------- */
import global_es from './i18n/es/global.json';
import global_en from './i18n/en/global.json';

i18next
    .init({
        interpolation: { escapeValue: false },
        lng: calls.getSessionStorageLanguage(),
        fallbackLng: calls.getSessionStorageLanguage(),
        resources: {
            es: {
                global: global_es
            },
            en: {
                global: global_en
            }
        }
    });

sessionStorage.setItem('section', 'book');

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <I18nextProvider i18n={i18next}>
                <Router>
                    <App />
                </Router>
            </I18nextProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
