/** React imports */
import React from 'react';

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
  },
}));

const SimpleBackdrop = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.open}>
          <CircularProgress color="secondary" />
      </Backdrop>
    </div>
  );
}

export default SimpleBackdrop;