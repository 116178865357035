/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from 'react';

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: 'auto !important',
        backgroundColor: '#F8F9F8',
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120
        },
    },
    subBannerT:{
        [theme.breakpoints.down('md')]: {
            marginBottom: 50,
        },
        [theme.breakpoints.up('lg')]: {
            height: 100,
            marginBottom: 50,
        },
        [theme.breakpoints.up('xl')]: {
            height: 'auto',
        },
    },
    subBannerT3:{
        [theme.breakpoints.down('md')]: {
            marginBottom: 50,
        },
        [theme.breakpoints.up('lg')]: {
            width: '85%',
        },
    },
    firstT: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40
        },
    },
}));

const SubBanner = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="xl" disableGutters>
                <Grid container spacing={6}>
                    <Grid item container xs={12} lg={4} direction="column" alignItems="flex-start" justifyContent="flex-start">
                        <Grid item className={classes.subBannerT}>
                            <Typography variant="h3" className={classes.firstT}>{props.firstT}</Typography>
                        </Grid>
                        <Grid item className={props.subBannerT2Class}>
                            <Typography variant="h5" color="secondary" dangerouslySetInnerHTML={{ __html: props.secondT }}></Typography>
                        </Grid>
                        <Grid item className={classes.subBannerT3}>
                            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.thirdT }}></Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={4} direction="column" alignItems="flex-start" justifyContent="flex-start">
                        <Grid item className={classes.subBannerT}>
                            <Typography variant="h3" className={classes.firstT}>{props.firstT2}</Typography>
                        </Grid>
                        <Grid item className={props.subBannerT2Class}>
                            <Typography variant="h5" color="secondary">{props.secondT2}</Typography>
                        </Grid>
                        <Grid item className={classes.subBannerT3}>
                            <Typography variant="body1">{props.thirdT2}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} lg={4} direction="column" alignItems="flex-start" justifyContent="flex-start">
                        <Grid item className={classes.subBannerT}>
                            <Typography variant="h3" className={classes.firstT}>{props.firstT3}</Typography>
                        </Grid>
                        <Grid item className={props.subBannerT2Class}>
                            <Typography variant="h5" color="secondary">{props.secondT3}</Typography>
                        </Grid>
                        <Grid item className={classes.subBannerT3}>
                            <Typography variant="body1">{props.thirdT3}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default SubBanner;
