/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";

/** Icons/images */
import { ReactComponent as GcaLogo } from '../assets/images/gca-logo-desktop.svg';
import { ReactComponent as GcaLogoMobile } from '../assets/images/gca-logo-mobile.svg';
import MenuSolutionsImg from '../assets/images/gca-menu-solutions.png';
import MenuCompanyImg from '../assets/images/gca-menu-company.png';
import MenuResourcesImg from '../assets/images/gca-menu-resources.png';
import ClearIcon from '@material-ui/icons/Clear';
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import MenuIcon from '@material-ui/icons/Menu';

/** Components ----------------------------------------------------------- */
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from "@material-ui/core/Button"
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import SubMenu from "./submodules/SubMenu";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
        position: 'fixed',
        top: 0,
        zIndex: 100,
    },
    appBar: {
        boxShadow: '0px 10px 15px rgba(0,0,0,0.2)',
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        backgroundColor: theme.palette.text.primary,
        [theme.breakpoints.down('md')]: {
            padding: '0 30px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 70px',
        },
    },
    headerLogo: {
        display: 'block',
        height: 50,
    },
    languageBtn: {
        cursor: 'pointer',
        padding: '10px 20px',
        fontWeight: 700,
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    btnRoot: {
        borderRadius: 50,
        padding: '4px 16px',
        margin: '0px 20px',
        fontSize: 16,
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: theme.palette.text.primary,
        },
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    desktopSection: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    mobileSection: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    listItemGutters: {
        padding: '8px 20px',
    },
    drawerListItem: {
        color: theme.palette.text.primary,
        "& [class*='MuiListItemText']": {
            fontWeight: 700,
        },
    },
    drawerListItemActive: {
        color: "#ff5968",
    },
    drawerListInset: {
        paddingLeft: '30px',
    },
    divider: {
        background: '#4F5D85',
        height: 20,
        margin: ''
    },
    dividerMobile: {
        background: '#4F5D85',
        margin: 20,
    },
}));

const Header = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    /** Assigning location variable */
    const location = useLocation();

    /** Destructuring pathname from location */
    const { pathname } = location;

    /** Javascript split method to get the name of the path in array */
    const splitLocation = pathname.split("/");

    /** Component states */
    const [language, setLanguage] = useState(sessionStorage.getItem('lng') === 'es' ? 'ES' : 'EN');

    const [open, setOpen] = useState(false);

    const [openSolutions, setOpenSolutions] = useState(false);

    const [openCompany, setOpenCompany] = useState(false);

    const [openResources, setOpenResources] = useState(false);

    const [openBack, setopenBack] = useState(false);

    const [openBackComp, setopenBackComp] = useState(false);

    const [openBackRes, setopenBackRes] = useState(false);

    const [soluMenu, setsoluMenu] = useState(false);

    const [pricingMenu, setpricingMenu] = useState(false);

    const [companyMenu, setcompanyMenu] = useState(false);

    const [resourcesMenu, setresourcesMenu] = useState(false);

    /** Component references */
    const anchorRef = useRef(null);

    const anchorCompRef = useRef(null);

    const anchorResRef = useRef(null);

    const handleToggleBack = () => {
        setopenBack(!openBack);
    };

    const handleToggleBackCompany = () => {
        setopenBackComp(!openBackComp);
    };

    const handleToggleBackRes = () => {
        setopenBackRes(!openBackRes);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpenSolutions(false);
        setOpenCompany(false)
        setOpenResources(false);
        setOpen(false);
    };

    const handleClickAway = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setsoluMenu(false);
        setopenBack(false);
    };

    const handleClickAwayComp = (event) => {
        if (anchorCompRef.current && anchorCompRef.current.contains(event.target)) {
            return;
        }
        setcompanyMenu(false);
        setopenBackComp(false);
    };

    const handleClickAwayRes = (event) => {
        if (anchorResRef.current && anchorResRef.current.contains(event.target)) {
            return;
        }
        setresourcesMenu(false);
        setopenBackRes(false);
    };

    const handleClickMain = (list) => {
        setpricingMenu(false);
        switch (list) {
            case 0:
                setsoluMenu((prev) => !prev);
                handleToggleBack();
                break;
            case 2:
                setcompanyMenu((prev) => !prev);
                handleToggleBackCompany();
                break;
            case 3:
                setresourcesMenu((prev) => !prev);
                handleToggleBackRes();
                break;
            default:
                break;
        }
    };

    const handleClick = (list) => {
        switch (list) {
            case 0:
                setOpenSolutions(!openSolutions);
                break;
            case 1:
                setOpenCompany(!openCompany);
                break;
            case 2:
                setOpenResources(!openResources);
                break;
            default:
        }
    };

    const updateLanguage = (lng) => {
        if (lng === 'EN') {
            setLanguage(lng);
            sessionStorage.setItem('lng', 'en');
            i18n.changeLanguage("en");
        } else {
            setLanguage(lng);
            sessionStorage.setItem('lng', 'es');
            i18n.changeLanguage("es");
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    useEffect(() => {
        if(document.getElementById('bookMenu') !== null){
            let checkBox = document.getElementById('bookMenu').childNodes;
            checkBox[0].innerHTML = t('header.submenuAcc');
            let checkBox2 = document.getElementById('ContMenu').childNodes;
            checkBox2[0].innerHTML = t('header.submenuCFO');
            let checkBox3 = document.getElementById('FirmMenu').childNodes;
            checkBox3[0].innerHTML = t('header.submenuFirms');
        }
        if(document.getElementById('faqMenu') !== null){
            let checkBox = document.getElementById('faqMenu').childNodes;
            checkBox[0].innerHTML = t('header.submenuFaq');
            let checkBox2 = document.getElementById('CustMenu').childNodes;
            checkBox2[0].innerHTML = t('header.submenuCustom');
        }
    }, [t, openSolutions, openResources]);

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Hidden only={['sm', 'xs']}>
                        <Link to='/'>
                            <GcaLogo className={classes.headerLogo} />
                        </Link>
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Link to='/'>
                            <GcaLogoMobile className={classes.headerLogo} />
                        </Link>
                    </Hidden>

                    <div className={classes.desktopSection}>
                        <ul className="navbar">
                            <li ref={anchorRef} onClick={() => handleClickMain(0)}>
                                <div className={soluMenu || splitLocation[1] === "solutions" ? "active" : pricingMenu || companyMenu || resourcesMenu ? "notActive" : ""}>
                                    {t('header.solutions')}
                                </div>
                            </li>
                            <li>
                                <Link to='/pricing'>
                                    <div className={pricingMenu || splitLocation[1] === "pricing" ? "active" : soluMenu || companyMenu || resourcesMenu ? "notActive" : ""}>
                                        {t('header.pricing')}
                                    </div>
                                </Link>
                            </li>
                            <li ref={anchorCompRef} onClick={() => handleClickMain(2)}>
                                <div className={companyMenu || splitLocation[1] === "company" ? "active" : soluMenu || pricingMenu || resourcesMenu ? "notActive" : ""}>
                                    {t('header.company')}
                                </div>
                            </li>
                            <li ref={anchorResRef} onClick={() => handleClickMain(3)}>
                                <div className={resourcesMenu || splitLocation[1] === "resources" ? "active" : soluMenu || pricingMenu || companyMenu ? "notActive" : ""}>
                                    {t('header.resources')}
                                </div>
                            </li>
                            <li>
                                <Button 
                                    component={Link}
                                    to="/company/contact"
                                    variant="contained" 
                                    color="secondary" 
                                    disableElevation 
                                    className={classes.btnRoot}
                                >
                                    {t('header.getStarted')}
                                </Button>
                            </li>

                            <li className={language === "EN" ? "active" : "notActive"}>
                                <Typography variant="body2" onClick={() => updateLanguage('EN')}
                                    className={classes.languageBtn}>
                                    EN
                                </Typography>
                            </li>
                            <Divider classes={{ root: classes.divider }} orientation="vertical" />
                            <li className={language === "ES" ? "active" : "notActive"}>
                                <Typography variant="body2" onClick={() => updateLanguage('ES')}
                                    className={classes.languageBtn}>
                                    ES
                                </Typography>
                            </li>
                        </ul>
                    </div>
                    <div className={classes.mobileSection}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={clsx(open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <SubMenu
                openBack={openBack}
                handleClickAway={handleClickAway}
                menu={soluMenu}
                persona={MenuSolutionsImg}
                background={1}
                submenutext1={t('header.submenuSol')}
                submenutext2={t('header.submenuBusi')}
                submenutext3={<Link to="/solutions/accounting" onClick={handleClickAway} dangerouslySetInnerHTML={{ __html: t('header.submenuAcc') }}></Link>}
                submenutext4={<Link to="/solutions/controller" onClick={handleClickAway} dangerouslySetInnerHTML={{ __html: t('header.submenuCFO') }}></Link>}
                submenutext5={t('header.submenuPersonalized')}
                submenutext1var={t('header.submenuBusiType')}
                submenutext7={<Link to="/solutions/automation" onClick={handleClickAway}>{t('header.submenuProcess')}</Link>}
                submenutext8={<Link to="/solutions/firms" onClick={handleClickAway} dangerouslySetInnerHTML={{ __html: t('header.submenuFirms') }}></Link>}
            />
            <SubMenu
                openBack={openBackComp}
                handleClickAway={handleClickAwayComp}
                menu={companyMenu}
                persona={MenuCompanyImg}
                background={2}
                submenutext1={t('header.submenuHist')}
                submenutext2={t('header.submenuGCA')}
                submenutext3={<Link to="/company/about" onClick={handleClickAwayComp}>{t('header.submenuAbout')}</Link>}
                submenutext5={t('header.submenuQuestion')}
                submenutext1var={t('header.submenuRight')}
                submenutext7={<Link to="/company/contact" onClick={handleClickAwayComp}>{t('header.submenuContact')}</Link>}
            />
            <SubMenu
                openBack={openBackRes}
                handleClickAway={handleClickAwayRes}
                menu={resourcesMenu}
                persona={MenuResourcesImg}
                background={3}
                submenutext1={t('header.submenuSolut')}
                submenutext2={t('header.submenuDrop')}
                submenutext3={<Link to="/resources/faq" onClick={handleClickAwayRes} dangerouslySetInnerHTML={{ __html: t('header.submenuFaq') }}></Link>}
                submenutext4={<Link to="/resources/customer-support" onClick={handleClickAwayRes} dangerouslySetInnerHTML={{ __html: t('header.submenuCustom') }}></Link>}
                // submenutext5={t('header.submenuStrat')}
                // submenutext1var={t('header.submenuSmart')}
                // submenutext7={<Link to="/resources/blog" onClick={handleClickAwayRes}>Blog</Link>}
            />
            <div className={classes.offset} />
            <Drawer
                className={classes.drawer}
                anchor="right"
                open={open}
                onClose={handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <ClearIcon color="action" />
                    </IconButton>
                </div>
                <List>
                    <ListItem component={Link} to="/" classes={{ gutters: classes.listItemGutters }} onClick={handleDrawerClose}>
                        <ListItemText primary={t('header.home')}
                            className={clsx(classes.drawerListItem, {
                                [classes.drawerListItemActive]: splitLocation[1] === "",
                            })}
                        />
                    </ListItem>

                    <ListItem button classes={{ gutters: classes.listItemGutters }} onClick={() => handleClick(0)}>
                        <ListItemText primary={t('header.solutions')}
                            className={clsx(classes.drawerListItem, {
                                [classes.drawerListItemActive]: splitLocation[1] === "solutions",
                            })}
                        />
                        {openSolutions ? <IconExpandLess color="action" /> : <IconExpandMore color="action" />}
                    </ListItem>
                    <Collapse in={openSolutions} timeout="auto">
                        <List component="ul" disablePadding>
                            <ListItem component={Link} to="/solutions/accounting" onClick={handleDrawerClose}>
                                <ListItemText inset id="bookMenu" primary=""
                                    className={clsx(classes.drawerListItem, classes.drawerListInset, {
                                        [classes.drawerListItemActive]: splitLocation[2] === "accounting",
                                    })}
                                />
                            </ListItem>
                            <ListItem component={Link} to="/solutions/controller" onClick={handleDrawerClose}>
                                <ListItemText inset id="ContMenu" primary=""
                                    className={clsx(classes.drawerListItem, classes.drawerListInset, {
                                        [classes.drawerListItemActive]: splitLocation[2] === "controller",
                                    })}
                                />
                            </ListItem>
                            <ListItem component={Link} to="/solutions/automation" onClick={handleDrawerClose}>
                                <ListItemText inset primary={t('header.submenuProcess')}
                                    className={clsx(classes.drawerListItem, classes.drawerListInset, {
                                        [classes.drawerListItemActive]: splitLocation[2] === "automation",
                                    })}
                                />
                            </ListItem>
                            <ListItem component={Link} to="/solutions/firms" onClick={handleDrawerClose}>
                                <ListItemText inset id="FirmMenu" primary=""
                                    className={clsx(classes.drawerListItem, classes.drawerListInset, {
                                        [classes.drawerListItemActive]: splitLocation[2] === "firms",
                                    })}
                                />
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem component={Link} to="/pricing" classes={{ gutters: classes.listItemGutters }} onClick={handleDrawerClose}>
                        <ListItemText primary={t('header.pricing')}
                            className={clsx(classes.drawerListItem, {
                                [classes.drawerListItemActive]: splitLocation[1] === "pricing",
                            })}
                        />
                    </ListItem>

                    <ListItem button classes={{ gutters: classes.listItemGutters }} onClick={() => handleClick(1)}>
                        <ListItemText primary={t('header.company')}
                            className={clsx(classes.drawerListItem, {
                                [classes.drawerListItemActive]: splitLocation[1] === "company",
                            })}
                        />
                        {openCompany ? <IconExpandLess color="action" /> : <IconExpandMore color="action" />}
                    </ListItem>
                    <Collapse in={openCompany} timeout="auto">
                        <List component="ul" disablePadding>
                            <ListItem component={Link} to="/company/about" onClick={handleDrawerClose}>
                                <ListItemText inset primary={t('header.submenuAbout')}
                                    className={clsx(classes.drawerListItem, classes.drawerListInset, {
                                        [classes.drawerListItemActive]: splitLocation[2] === "about",
                                    })}
                                />
                            </ListItem>
                            <ListItem component={Link} to="/company/contact" onClick={handleDrawerClose}>
                                <ListItemText inset primary={t('header.submenuContact')}
                                    className={clsx(classes.drawerListItem, classes.drawerListInset, {
                                        [classes.drawerListItemActive]: splitLocation[2] === "contact",
                                    })}
                                />
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button classes={{ gutters: classes.listItemGutters }} onClick={() => handleClick(2)}>
                        <ListItemText primary={t('header.resources')}
                            className={clsx(classes.drawerListItem, {
                                [classes.drawerListItemActive]: splitLocation[1] === "resources",
                            })}
                        />
                        {openResources ? <IconExpandLess color="action" /> : <IconExpandMore color="action" />}
                    </ListItem>
                    <Collapse in={openResources} timeout="auto">
                        <List component="ul" disablePadding>
                            <ListItem component={Link} to="/resources/faq" onClick={handleDrawerClose}>
                                <ListItemText inset id="faqMenu" primary=""
                                    className={clsx(classes.drawerListItem, classes.drawerListInset, {
                                        [classes.drawerListItemActive]: splitLocation[2] === "faq",
                                    })}
                                />
                            </ListItem>
                            <ListItem component={Link} to="/resources/customer-support" onClick={handleDrawerClose}>
                                <ListItemText inset id="CustMenu" primary=""
                                    className={clsx(classes.drawerListItem, classes.drawerListInset)}
                                />
                            </ListItem>
                            {/* <ListItem component={Link} to="/resources/blog" onClick={handleDrawerClose}>
                                <ListItemText inset primary="Blog"
                                    className={clsx(classes.drawerListItem, classes.drawerListInset)}
                                />
                            </ListItem> */}
                        </List>
                    </Collapse>

                    <Divider classes={{ root: classes.dividerMobile }} />

                    <ListItem button classes={{ gutters: classes.listItemGutters }}>
                        <ListItemText primary="EN" onClick={() => {updateLanguage('EN'); handleDrawerClose()}}
                            className={clsx(classes.drawerListItem, {
                                [classes.drawerListItemActive]: language === 'EN'
                            })} />
                    </ListItem>

                    <ListItem button classes={{ gutters: classes.listItemGutters }}>
                        <ListItemText primary="ES" onClick={() => {updateLanguage('ES'); handleDrawerClose()}}
                            className={clsx(classes.drawerListItem, {
                                [classes.drawerListItemActive]: language === 'ES'
                            })} />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    )
}

export default Header;