/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from 'react';

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120
        },
    },
    firstT: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        },
        paddingBottom: 90,
    },
    firstT2: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        },
        color: "#ff5968"
    },
    marginDiv: {
        paddingBottom: 70,
    },
    circle: {
        width: '100%',
        background: 'rgba(242,242,241, 0.6)',
        borderRadius: '50%',
        position: 'relative',
        lineHeight: 0,
        '&::after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%',
        },
        '&:hover': {
            animation: '$pulse 2s infinite',
        },
    },
    circle2: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: -40,
            marginTop: -40,
        },
        [theme.breakpoints.up('md')]: {
            marginRight: -40,
            marginLeft: -40,
        },
        background: 'rgba(242,242,241, 0.6)',
        borderRadius: '50%',
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%',
        },
        '&:hover': {
            animation: '$pulse 2s infinite',
        },
    },
    circle3: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: -40,
            marginTop: -40,
        },
        [theme.breakpoints.between('960', '1280')]: {
            marginLeft: -40,
            marginTop: -85,
        },
        [theme.breakpoints.up('1280')]: {
            marginRight: -40,
            marginLeft: -40,
        },
        background: 'rgba(242,242,241, 0.6)',
        borderRadius: '50%',
        position: 'relative',
        '&::after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%',
        },
        '&:hover': {
            animation: '$pulse 2s infinite',
        },
    },
    circleTitle: {
        position: 'absolute',
        top: '40%',
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 25,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 25,
        },
    },
    circleText: {
        position: 'absolute',
        top: '50%',
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 16
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 18,
        },
    },
    '@keyframes pulse': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255, 89, 104, 1)',
        },
        '70%': {
            boxShadow: '0 0 0 10px rgba(33, 131, 221, 0)',
        },
        '100%': {
            boxShadow: '0 0 0 0 rgba(33, 131, 221, 0)',
        },
    },
}));

const EsphereSection = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="xl" disableGutters>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h3" className={classes.firstT}>{props.firstMain} <span className={classes.firstT2}>{props.firstT}</span></Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center" className={classes.marginDiv}>
                    <Grid item xs={12} sm={8} md={6} lg={4} className={classes.circle2}>
                        <Typography variant="h5" color="secondary" className={classes.circleTitle}>{props.secondT}</Typography>
                        <Typography variant="body1" className={classes.circleText}>{props.thirdT}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} className={classes.circle}>
                        <Typography variant="h5" color="secondary" className={classes.circleTitle}>{props.firstT2}</Typography>
                        <Typography variant="body1" className={classes.circleText}>{props.secondT2}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} lg={4} className={classes.circle3}>
                        <Typography variant="h5" color="secondary" className={classes.circleTitle}>{props.thirdT2}</Typography>
                        <Typography variant="body1" className={classes.circleText}>{props.thirdT3}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default EsphereSection;