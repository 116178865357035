/** Imports ---------------------------------------------------------------- */

/** Icons/images */
import SecGraph from '../../assets/images/secondary-graphic-version-1.svg';

/** Components ----------------------------------------------------------- */
import { makeStyles, Backdrop, ClickAwayListener, Divider, Grid, Grow, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    root: {
        flexGrow: 1,
    },
    subMenuBack: {
        backgroundColor: theme.palette.text.primary,
        color: '#FFFFFF',
        top: 82,
        left: "2vw",
        width: "94vw",
        position: "fixed",
        margin: theme.spacing(1),
        borderBottomRightRadius: 50,
        borderBottomLeftRadius: 50,
        zIndex: 1000,
    },
    subMenuBack2: {
        backgroundColor: '#2F3190',
        color: '#FFFFFF',
        top: 82,
        left: "2vw",
        width: "94vw",
        position: "fixed",
        margin: theme.spacing(1),
        borderBottomRightRadius: 50,
        borderBottomLeftRadius: 50,
        zIndex: 1000,
    },
    subMenuBack3: {
        backgroundColor: '#e9eae8',
        color: '#FFFFFF',
        top: 82,
        left: "2vw",
        width: "94vw",
        position: "fixed",
        margin: theme.spacing(1),
        borderBottomRightRadius: 50,
        borderBottomLeftRadius: 50,
        zIndex: 1000,
    },
    mainBack: {
        background: 'url(' + SecGraph + ')',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('md')]: {
            backgroundSize: 300,
        },
        [theme.breakpoints.up('lg')]: {
            backgroundSize: 300,
        },
        [theme.breakpoints.up('xl')]: {
            backgroundSize: 380,
        },
        backgroundPosition: 'center',
    },
    subMenuTitles: {
        [theme.breakpoints.up('lg')]: {
            width: 320,
            height: 70,
            marginTop: 30,
        },
        [theme.breakpoints.up('xl')]: {
            width: 300,
            height: 70,
            marginTop: 40,
        },
    },
    subMenuTitles1: {
        [theme.breakpoints.up('lg')]: {
            width: 320,
            height: 100,
            marginTop: 30,
        },
        [theme.breakpoints.up('xl')]: {
            width: 450,
            height: 70,
            marginTop: 40,
        },
    },
    subMenuTitles2: {
        [theme.breakpoints.up('lg')]: {
            width: 330,
            height: 100,
            marginTop: 30,
        },
        [theme.breakpoints.up('xl')]: {
            width: 340,
            marginTop: 40,
        },
    },
    menutext1: {
        color: '#FFFFFF',
    },
    menutext2: {
        color: '#ff5968',
    },
    menutext2var: {
        color: theme.palette.text.primary,
    },
    menutext3: {
        marginTop: 30,
        marginBottom: 30,
        '& a': {
            textDecoration: 'none',
            color: '#FFFFFF'
        },
        '& a:hover': {
            color: '#ff5968'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 30,
            marginBottom: 30,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 40,
            marginBottom: 40,
        },
    },
    menutext3var: {
        '& a': {
            textDecoration: 'none',
            color: theme.palette.text.primary
        },
        '& a:hover': {
            color: '#ff5968'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 30,
            marginBottom: 30,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 40,
            marginBottom: 40,
        },
    },
    personaImg: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 100
        },
        [theme.breakpoints.up('lg')]: {
            width: 350
        },
        [theme.breakpoints.up('xl')]: {
            width: 410,
        },
    },
    divider2: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 4,
        background: '#ff5968',
    },
    backdrop: {
        zIndex: 1000,
    },
}));

const SubMenu = (props) => {
    
    const classes = useStyles();

    return (
        <>
            <Backdrop className={classes.backdrop} open={props.openBack}>
            </Backdrop>
            <ClickAwayListener mouseEvent="onMouseUp" onClickAway={props.handleClickAway}>
                <Grow in={props.menu}>
                    <Paper elevation={4} className={props.background === 1 ? classes.subMenuBack : props.background === 2 ? classes.subMenuBack2 : classes.subMenuBack3}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} lg={4}>
                                <img src={props.persona} alt="subMenuPerson" className={classes.personaImg}/>
                            </Grid>
                            <Grid item container xs={12} lg={8} spacing={4}>
                                <Grid item container xs={12} lg={5} justifyContent="center">
                                    <Grid item>
                                        <div className={props.background === 1 ? classes.subMenuTitles : props.background === 2 ? classes.subMenuTitles1 : classes.subMenuTitles2}>
                                            <Typography variant="h5" className={props.background === 3 ? classes.menutext2 : classes.menutext1}>
                                                {props.submenutext1}
                                                <span className={props.background === 3 ? classes.menutext2var : classes.menutext2}> {props.submenutext2} </span>
                                            </Typography>
                                        </div>
                                        <Typography variant="body1" className={props.background === 3 ? classes.menutext3var : classes.menutext3}>
                                            {props.submenutext3}
                                        </Typography>
                                        <Typography variant="body1" className={props.background === 3 ? classes.menutext3var : classes.menutext3}>
                                            {props.submenutext4}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    {props.background !== 3 ? (
                                        <Divider classes={{ root: classes.divider2 }} orientation="vertical" />
                                    ) : null} 
                                </Grid>
                                <Grid item container xs={12} lg={5} justifyContent="center">
                                    <Grid item>
                                        <div className={props.background === 1 ? classes.subMenuTitles : props.background === 2 ? classes.subMenuTitles1 : classes.subMenuTitles2}>
                                            <Typography variant="h5" className={props.background === 3 ? classes.menutext2 : classes.menutext1}>
                                                {props.submenutext5} 
                                                <span className={props.background === 3 ? classes.menutext2var : classes.menutext2}> {props.submenutext1var} </span>
                                            </Typography>
                                        </div>
                                        <Typography variant="body1" className={props.background === 3 ? classes.menutext3var : classes.menutext3}>
                                            {props.submenutext7}
                                        </Typography>
                                        <Typography variant="body1" className={props.background === 3 ? classes.menutext3var : classes.menutext3}>
                                            {props.submenutext8}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grow>
            </ClickAwayListener>
        </>
    )
}

export default SubMenu;