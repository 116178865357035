/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

/** Icons/images */
import CheckIcon from '@material-ui/icons/Check';
import BannerImgAuto from '../assets/images/gca-isolated-processes-automation.png';
import KiiperBank from '../assets/images/logo-kiiper-banking.svg';
import KiiperTax from '../assets/images/logo-kiiper-taxes.svg';
import WhyAutomation from '../assets/images/why-process-automation.jpg';
import WhyXero from '../assets/images/process-automation-why-xero.jpg';
import XeroMarketplace from '../assets/videos/xero-marketplace.mp4';

/** Components ----------------------------------------------------------- */
import { makeStyles, withStyles, Badge, Button, Container, Grid, Hidden, List, ListItem, ListItemIcon, ListItemText, 
    Paper, Typography 
} from '@material-ui/core';
import Banner from './submodules/Banner';
import FaqSection from './submodules/FaqSection';
import HowSection from './submodules/HowSection';
import WhySection from './submodules/WhySection';

const StyledBadge = withStyles((theme) => ({
    badge: {
        width: 210,
        top: -30,
        right: 80,
        padding: 13,
        borderRadius: 50
    },
}))(Badge);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
    },
    rootBanner: {
        flexGrow: 1,
        paddingTop: 100,
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            background: '#2F3190'
        },
        [theme.breakpoints.up('lg')]: {
            background: 'linear-gradient(90deg, #2F3190 50%, #ffffff 50%)'
        },
    },
    bannerButton: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 60,
            fontSize: 18
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 150,
        },
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: '#FFFFFF',
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 60,
            fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 120,
        },
    },
    button2: {
        borderRadius: 50,
        paddingLeft: 60,
        paddingRight: 60,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 80,
            fontSize: 18
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 80
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: 100,
        },
    },
    button3: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 60,
            fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 70,
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: 120,
        },
    },
    firstT1: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        }
    },
    firstT3: {
        paddingTop: 100,
        [theme.breakpoints.up('lg')]: {
            height: 170,
        },
    },
    purpleBack: {
        [theme.breakpoints.down('md')]: {
            padding: '30px !important'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '70px !important'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '120px !important'
        },
    },
    mainBack2: {
        backgroundColor: '#F2F2F1',
        [theme.breakpoints.down('md')]: {
            margin: '60px 30px'
        },
        [theme.breakpoints.up('lg')]: {
            margin: 70
        },
        [theme.breakpoints.up('xl')]: {
            margin: 120
        },
        borderRadius: 50
    },
    mainBack3: {
        backgroundColor: theme.palette.text.primary,
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120
        },
    },
    secondBack: {
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px 0',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '60px 60px 0',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '80px 80px 0'
        },
    },
    secondBack2: {
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '30px 30px 80px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 60
        },
        [theme.breakpoints.up('xl')]: {
            padding: 80
        },
    },
    secondBack3: {
        backgroundColor: '#F8F9F8',
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120
        },
    },
    secondBack4: {
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 40,
            margin: 70,
            borderLeft: '4px solid #ff5968'
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: 100,
            margin: 120
        },
    },
    paper: {
        [theme.breakpoints.down('md')]: {
            padding: '30px !important',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '30px !important',
            marginTop: '-30px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '30px !important',
            marginTop: '-30px',
        },
        borderRadius: 20,
    },
    secondT: {
        paddingBottom: 30
    },
    firstT: {
        paddingTop: 90,
        [theme.breakpoints.up('lg')]: {
            width: 480,
        },
        [theme.breakpoints.up('xl')]: {
            width: 650,
        },
    },
    firstT2: {
        paddingTop: 50,
        paddingBottom: 50,
        [theme.breakpoints.down('md')]: {
            paddingTop: 50,
            paddingBottom: 50,
        },
        [theme.breakpoints.up('lg')]: {
            width: 350,
            paddingTop: 50,
            paddingBottom: 0,
        },
        [theme.breakpoints.up('xl')]: {
            width: 520,
            paddingTop: 50,
            paddingBottom: 0,
        },
    },
    thirdT: {
        paddingBottom: 120
    },
    fourthT: {
        paddingTop: 100,
        marginBottom: 25,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '85%',
            height: 170,
        },
    },
    fourthT2: {
        [theme.breakpoints.up('lg')]: {
            width: '85%',
            height: 85,
        },
    },
    fourthT3: {
        [theme.breakpoints.up('lg')]: {
            width: 480,
        },
    },
    fifthT: {
        color: '#FFFFFF',
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        }
    },
    fifthT2: {
        color: '#FFFFFF',
        fontWeight: '400 !important',
        [theme.breakpoints.down('lg')]: {
            fontSize: 20,
        },
    },
    primaryItem: {
        width: '90%',
    },
    addonsPrice: {
        [theme.breakpoints.down('md')]: {
            marginLeft: 80,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 30,
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft: 50,
        },
    },
    whyXeroImg: {
        borderRadius: 30,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 250,
            marginTop: 60,
        },
        [theme.breakpoints.up('lg')]: {
            width: 350,
            marginTop: 90,
        },
        [theme.breakpoints.up('xl')]: {
            width: 400,
            marginTop: 50,
        },
    },
    bankingImg: {
        [theme.breakpoints.down('md')]: {
            width: 250,
        },
        [theme.breakpoints.up('lg')]: {
            width: 450,
            height: 73,
        },
    },
    taxesImg: {
        [theme.breakpoints.down('md')]: {
            width: 250,
            marginTop: 120,
        },
        [theme.breakpoints.up('lg')]: {
            width: 450,
            height: 73,
        },
    },
    xeroVideo:{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 250,
        },
        [theme.breakpoints.up('md')]: {
            width: 480,
        },
        [theme.breakpoints.up('lg')]: {
            width: 580,
        },
        [theme.breakpoints.up('xl')]: {
            width: 980,
        },
    },
    spacing: {
        paddingBottom: 20
    },
    spacing2: {
        paddingBottom: 15,
    },
    spacing3: {
        paddingTop: 60,
    },
    spacing4: {
        paddingTop: 120,
    },
    spacing5: {
        paddingBottom: 20,
        [theme.breakpoints.down('md')]: {
            paddingTop: 30,
        },
        [theme.breakpoints.up('lg')]: {
            height: 86,
        },
    },
}));

const Automation = () => {
    const classes = useStyles();
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    useEffect(() => {
    }, [t]);

    let buttonBookDemo = (
        <Button component={Link} to="/book-demo" variant="contained" color="secondary" 
            disableElevation className={classes.bannerButton}
        >
            {t('banner.bookDemo')}
        </Button>
    );

    let buttonTalkSpecialist = (
        <Button component={Link} to="/book-demo" variant="contained" color="secondary" 
            disableElevation className={classes.button}
        >
            {t('firms.firmTalk')}
        </Button>
    );

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Banner
                firstT={t('automation.autoPro')}
                secondT={t('automation.autoClo')}
                secondT2={t('automation.autoFor')}
                thirdT={t('automation.autoWe')}
                button={buttonBookDemo}
                firstBack={classes.purpleBack}
                persona={BannerImgAuto}
                root={classes.rootBanner}
            />
            <div className={classes.secondBack3}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" color="secondary" className={classes.firstT1}>{t('automation.autoXero')}</Typography>
                            <Typography variant="h3" className={classes.firstT1}>{t('automation.autoSoft')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <Typography variant="h5" className={classes.firstT}>{t('automation.autoCore')}</Typography>
                            <Typography variant="body1" className={classes.firstT2}>{t('automation.autoTo')}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <video autoPlay loop className={classes.xeroVideo}>
                                <source src={XeroMarketplace} type="video/mp4"/> 
                            </video>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.secondBack4}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.firstT1}>{t('automation.autoWhy')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoOnli')}</Typography>
                            <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoKeep')}</Typography>
                            <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoBank')}</Typography>
                            <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoTrack')}</Typography>
                            <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoShort')}</Typography>
                            <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoSee')}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoBills')}</Typography>
                            <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoPay')}</Typography>
                            <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoMult')}</Typography>
                            <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoGains')}</Typography>
                            <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoMob')}</Typography>
                            <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoInv')}</Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <img src={WhyXero} alt="whyXero" className={classes.whyXeroImg} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Grid container justifyContent="center">
                <Grid item>
                    <Button target="_blank" href="https://www.xero.com/" variant="contained" color="secondary" disableElevation className={classes.button3}>
                        {t('automation.autoExpXero')}
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.mainBack3}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.fifthT}>{t('automation.autoAdd')}</Typography>
                            <Typography variant="h3" color="secondary" className={classes.firstT1}>{t('automation.autoAcco')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.spacing3}>
                        <Grid item xs={12} md={9}>
                            <Typography variant="h5" className={classes.fifthT2}>{t('automation.autoKiip')}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.secondBack4}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.firstT1}>{t('automation.autoWhyK')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.spacing4}>
                        <Grid item container xs={12} lg={6}>
                            <Grid item container>
                                <StyledBadge badgeContent={t('automation.autoLim')} color="secondary">
                                    <img src={KiiperBank} alt="kiiperBanking" className={classes.bankingImg} />
                                </StyledBadge>
                            </Grid>
                            <Grid item container>
                                <Grid item>
                                    <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoState')}</Typography>
                                    <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoExt')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoMon')}</Typography>
                                    <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoTra')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoStat')}</Typography>
                                    <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoAlert')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} lg={6}>
                            <Grid item container>
                                <img src={KiiperTax} alt="kiiperTaxes" className={classes.taxesImg} />
                            </Grid>
                            <Grid item container>
                                <Grid item>
                                    <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoWith')}</Typography>
                                    <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoGen')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoTax')}</Typography>
                                    <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoInc')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" color="secondary" className={classes.fourthT}>{t('automation.autoRep')}</Typography>
                                    <Typography variant="body1" className={classes.fourthT2}>{t('automation.autoSales')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Grid container justifyContent="center">
                <Grid item>
                    <Button target="_blank" href="https://kiiper.app/" variant="contained" color="secondary" disableElevation className={classes.button3}>
                        {t('automation.autoExpKiiper')}
                    </Button>
                </Grid>
            </Grid>
            <HowSection
                firstMain={t('howSection.howWork')}
                firstT={t('automation.autoDesig')}
                secondT={t('automation.autoSett')}
                thirdT={t('automation.autoBook')}
                firstT2={t('automation.autoClose')}
                secondT2={t('automation.autoMain')}
                thirdT2={t('automation.autoPass')}
                button={buttonTalkSpecialist}
            />
            <div className={classes.mainBack2}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2} className={classes.secondBack}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.firstT1}>{t('faq.faqPrice')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.secondBack2} spacing={4}>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.spacing5}>{t('automation.autoImp')}</Typography>
                            <List>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoQ')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoPur')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoRec')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoA')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoT')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoR')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Typography variant="h5" className={classes.spacing5}>{t('automation.autoDeli')}</Typography>
                            <List>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoTrai')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoUser')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="h5" className={classes.spacing5}>
                                        {t('automation.autoCustom')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <List>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoInt')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                                <ListItem disableGutters alignItems="flex-start">
                                    <ListItemIcon style={{ minWidth: 35 }}>
                                        <CheckIcon fontSize="small" style={{ color: '#031851' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('automation.autoAddi')} classes={{ primary: classes.primaryItem }} />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item container xs={12} lg={3} justifyContent="flex-end">
                            <Hidden only={['sm', 'xs', 'md']}>
                                <Grid item>
                                    <Paper elevation={0} className={classes.paper}>
                                        <Typography variant="h5" color="secondary" className={classes.spacing}>{t('automation.autoSta')}</Typography>
                                        <Typography variant="h5">{t('automation.autoProj')}</Typography>
                                        <Typography variant="h5">+</Typography>
                                        <Typography variant="h5">{t('automation.autoM')}</Typography>
                                    </Paper>
                                </Grid>
                            </Hidden>
                            <Hidden only={['lg', 'xl']}>
                                <Grid item xs={12}>
                                    <Paper elevation={0} className={classes.paper}>
                                        <Typography variant="h5" color="secondary" className={classes.spacing}>{t('automation.autoSta')}</Typography>
                                        <Typography variant="h5">{t('automation.autoProj')}</Typography>
                                        <Typography variant="h5">+</Typography>
                                        <Typography variant="h5">{t('automation.autoM')}</Typography>
                                    </Paper>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button component={Link} to="/automation-quote" variant="contained" color="secondary"
                                disableElevation className={classes.button2}
                            >
                                {t('automation.autoGet')}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <WhySection
                firstMain={t('automation.autoWhy2')}
                secondT={t('automation.autoDev')}
                thirdT={t('automation.autoBus')}
                secondT2={t('automation.autoFin')}
                thirdT2={t('automation.autoIf')}
                secondT3={t('automation.autoGlo')}
                thirdT3={t('automation.autoYou')}
                image={WhyAutomation}
                button={t('header.getStarted')}
                firstT3Class={classes.firstT3}
            />
            <FaqSection
                firstMain={t('faq.faq')}
                firstT={t('faq.faqSel')}
                secondT={t('faq.faqHow')}
                thirdT={t('faq.faqTo')}
                firstT2={t('faq.faqPrice')}
                secondT2={t('faq.faqXero')}
                thirdT2={t('faq.faqWhat')}
                thirdT3={t('faq.faqD')}
                fourth1={t('faq.faqSu')}
                fourth2={t('faq.faqImp')}
                more={t('faq.faqMore')}
                section={'auto'}
            />
        </div >
    )
}

export default Automation;
