/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

/** Components ----------------------------------------------------------- */
import { Grid, Typography, Button, withStyles, makeStyles, Container } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        '& p': {
            lineHeight: 1.2
        },
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 120
        },
    },
    firstT1: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        },
    },
    firstT: {
        [theme.breakpoints.down('md')]: {
            width: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
            width: 700,
        },
        paddingTop: 40,
    },
    button: {
        borderRadius: 50,
        border: '2px solid',
        paddingLeft: 40,
        paddingRight: 40,
        marginTop: 90,
        marginBottom: 30,
        fontSize: 18,
        '&:hover': {
            border: '2px solid #2f3190',
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
        },
    },
}));

const Accordion = withStyles({
    root: {
        borderBottom: '3px solid #ff5968',
        borderTop: '3px solid #ff5968',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:first-child': {
            borderTop: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 56,
        padding: '50px 0px',
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '0px 0px 80px 0px',
    },
}))(MuiAccordionDetails);

const FaqSection = (props) => {

    const classes = useStyles();

    /** Component states */
    const [expanded, setExpanded] = useState('panel1');

    useEffect(() => {
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <Container maxWidth="xl" disableGutters>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.firstT1}>{props.firstMain}</Typography>
                        <Typography variant="body1" className={classes.firstT} dangerouslySetInnerHTML={{ __html: props.firstT }}></Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h5" dangerouslySetInnerHTML={{ __html: props.secondT }}></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.thirdT }}></Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h5">{props.firstT2}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.secondT2 }}></Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography variant="h5" dangerouslySetInnerHTML={{ __html: props.thirdT2 }}></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.thirdT3 }}></Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4a-content"
                                id="panel4a-header"
                            >
                                <Typography variant="h5">{props.fourth1}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.fourth2 }}></Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Button component={Link} to="/resources/faq" variant="outlined" color="secondary"
                            disableElevation className={classes.button} onClick={() => sessionStorage.setItem('section', props.section)}
                        >
                            {props.more}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default FaqSection;