/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

/** Icons/images */
import Error404 from '../assets/images/404.png';

/** Components ----------------------------------------------------------- */
import { makeStyles, Button, Typography, Container, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 100,
        backgroundColor: '#031851',
        [theme.breakpoints.down('md')]: {
            padding: '60px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: 70
        },
        [theme.breakpoints.up('xl')]: {
            padding: 115
        },
    },
    commonWhite: {
        color: 'white',
    },
    errorImg: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            height: 100,
        },
        [theme.breakpoints.up('md')]: {
            height: 198,
        },
        [theme.breakpoints.up('lg')]: {
            height: 280,
        },
        [theme.breakpoints.up('xl')]: {
            height: 396,
        },
    },
    oopsText: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 40,
        }
    },
    backBtn: {
        marginTop: 60,
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        '&:hover': {
            backgroundColor: 'white',
            color: theme.palette.text.primary,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 18
        },
    },
}));

const Error = () => {

    const classes = useStyles();
    
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    useEffect(() => {

    }, [t]);

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <img src={Error404} alt="404" className={classes.errorImg} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h3" color="secondary" align="center" className={classes.oopsText}>
                            Oops
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" color="secondary" align="center" className={classes.commonWhite}>
                            {t('error.notFound')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} justifyContent="center">
                        <Button component={Link} to="/" variant="contained" color="secondary"
                            disableElevation className={classes.backBtn}
                        >
                            {t('error.back')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Error;
