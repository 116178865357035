/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** Components ----------------------------------------------------------- */
import {
    makeStyles,
    Grid,
    Typography,
    Container,
    Paper,
    Divider,
    Switch,
    withStyles,
    Button,
    Slider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    TextField,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Backdrop from "./Backdrop";
import calls from "./js/calls";

/** Icons/images */
import CheckIcon from "@material-ui/icons/Check";
import PriGif from "../assets/images/Pricing.gif";

const CssTextField = withStyles({
    root: {
        backgroundColor: "#F8F9F8",
        borderRadius: 50,
        marginBottom: 15,
        "& label": {
            color: "#031851",
        },
        "& label.Mui-focused": {
            color: "#031851",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F8F9F8",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F8F9F8",
                borderRadius: 50,
            },
            "&:hover fieldset": {
                borderColor: "#031851",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#031851",
            },
        },
        "& .MuiOutlinedInput-input": {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
})(TextField);

const CustomSwitch = withStyles((theme) => ({
    root: {
        width: 40,
        height: 20,
        padding: 0,
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: "#031851",
                border: "2px solid #031851",
                opacity: 1,
            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
        border: "2px solid #031851",
    },
    track: {
        borderRadius: 10,
        border: "2px solid #031851",
        backgroundColor: "#fff",
        transition: theme.transitions.create(["background-color", "border"]),
        opacity: 1,
    },
    checked: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: 100,
        background: "white",
        "& p, h6": {
            lineHeight: 1.2,
        },
    },
    dialogRoot: {
        [theme.breakpoints.down("md")]: {
            borderRadius: 20,
        },
        [theme.breakpoints.up("lg")]: {
            borderRadius: 50,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("md")]: {
            padding: "60px !important",
        },
    },
    dialogTitle: {
        [theme.breakpoints.down("sm")]: {
            padding: "30px 30px 0px 30px !important",
        },
        [theme.breakpoints.up("md")]: {
            padding: "60px 60px 0px 60px !important",
        },
        color: "#ff5968",
        "& h2": {
            fontSize: 25,
            fontWeight: 700,
        },
    },
    dialogButtons: {
        [theme.breakpoints.down("sm")]: {
            padding: "00px 30px 30px 30px !important",
        },
        [theme.breakpoints.up("md")]: {
            padding: "0px 60px 60px 60px !important",
        },
    },
    purpleBack: {
        background: "#2F3190",
    },
    purpleBackR: {
        background: "#2F3190",
        borderRadius: "50px",
        boxShadow: "10px 10px 15px rgba(3,24,81,0.25)",
        marginTop: 60,
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("lg")]: {
            padding: "10px 20px 10px 20px !important",
        },
    },
    normalBack: {
        [theme.breakpoints.down("md")]: {
            padding: 30,
        },
        [theme.breakpoints.up("lg")]: {
            padding: "70px 0 70px 70px",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "120px 0 120px 120px",
        },
    },
    normalBack2: {
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: 70,
        },
        [theme.breakpoints.up("xl")]: {
            padding: 120,
        },
    },
    firstT: {
        color: "#F8F9F8",
    },
    secondT: {
        paddingTop: 90,
        color: "#F8F9F8",
        [theme.breakpoints.down("lg")]: {
            fontSize: 40,
        },
    },
    secondT2: {
        [theme.breakpoints.down("lg")]: {
            fontSize: 40,
        },
    },
    thirdT: {
        paddingBottom: 30,
    },
    paper: {
        borderRadius: 20,
        background: "#f8f9f8",
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            padding: "30px !important",
            marginBottom: 30,
        },
        [theme.breakpoints.up("sm")]: {
            width: 325,
            padding: "30px !important",
        },
    },
    paperFB: {
        borderRadius: 20,
        border: "2px solid #ff5968",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            padding: "30px !important",
            marginBottom: 30,
        },
        [theme.breakpoints.up("sm")]: {
            width: 330,
            padding: "30px !important",
        },
    },
    paperF: {
        borderRadius: 20,
        background: "#f8f9f8",
        [theme.breakpoints.down("sm")]: {
            padding: "30px !important",
            marginBottom: 30,
        },
        [theme.breakpoints.up("sm")]: {
            padding: "40px 30px !important",
        },
        [theme.breakpoints.up("md")]: {
            float: "right",
        },
        [theme.breakpoints.up("xl")]: {
            width: 310,
        },
    },
    paperCustom: {
        borderRadius: 10,
        border: "2px solid #031851",
        [theme.breakpoints.down("md")]: {
            padding: "30px !important",
            marginBottom: 30,
        },
        [theme.breakpoints.up("lg")]: {
            padding: "15px !important",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "20px !important",
        },
        marginBottom: 20,
        marginTop: 30,
    },
    paperCustom2: {
        borderRadius: 10,
        border: "2px solid #031851",
        [theme.breakpoints.down("md")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "15px !important",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "20px !important",
        },
        marginTop: 30,
    },
    spacing: {
        paddingBottom: 30,
    },
    spacing2: {
        paddingBottom: 90,
    },
    spacing3: {
        [theme.breakpoints.down("md")]: {
            paddingBottom: 30,
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: 70,
        },
    },
    spacing4: {
        paddingBottom: 50,
    },
    spacingR: {
        [theme.breakpoints.down("md")]: {
            paddingTop: 30,
        },
        paddingBottom: 15,
        paddingTop: 70,
    },
    spacingS: {
        paddingTop: 50,
    },
    spacingAdd: {
        paddingLeft: 15,
        paddingTop: 15,
    },
    spacingO: {
        paddingTop: 30,
        paddingBottom: 15,
    },
    divider: {
        background: "#ff5968",
        height: 3,
        marginTop: 30,
        marginBottom: 30,
    },
    button: {
        borderRadius: "50px",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: 12,
        },
        [theme.breakpoints.up("xl")]: {
            fontSize: 14,
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
        },
    },
    buttonMore: {
        borderRadius: "50px",
        padding: "4px 16px",
        fontSize: 16,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
        },
    },
    buttonDis: {
        borderRadius: "50px",
        padding: "4px 16px",
        backgroundColor: "#FFEEF0 !important",
        color: "#fff !important",
        fontSize: 16,
        "&:hover": {
            backgroundColor: "#FFEEF0 !important",
            color: "white",
        },
    },
    button2: {
        borderRadius: 50,
        fontSize: 16,
        [theme.breakpoints.up("lg")]: {
            marginLeft: 15,
        },
        "&:hover": {
            backgroundColor: "#fff",
            color: theme.palette.primary.main,
        },
    },
    Taxdiv: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            textAlign: "center",
            paddingTop: 15,
        },
    },
    purpleText: {
        color: "white",
        fontSize: 18,
        padding: 5,
        float: "left",
    },
    spacingText: {
        paddingBottom: 30,
        textAlign: "center",
    },
    pricingImg: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.only("xs")]: {
            width: "70%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "50%",
        },
        [theme.breakpoints.up("md")]: {
            width: "70%",
        },
    },
    formlabel: {
        marginLeft: "auto",
        "& span:nth-child(2)": {
            fontWeight: "bold",
        },
        "& span:nth-child(1)": {
            marginRight: 15,
        },
    },
    input: {
        "&::placeholder": {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    textDisabled: {
        color: "rgba(0, 0, 0, 0.38)",
        pointerEvents: "none",
    },
}));

const PrettoSlider = withStyles((theme) => ({
    root: {
        color: "#d9dce5",
        height: 10,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#031851",
        border: "2px solid #031851",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    mark: {
        display: "none",
    },
    markLabel: {
        [theme.breakpoints.down("md")]: {
            fontSize: 14,
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: 20,
        },
        fontWeight: 700,
        top: 50,
    },
    valueLabel: {
        left: "calc(-50% + 4px)",
        color: "#031851",
    },
    track: {
        height: 10,
        borderRadius: 10,
    },
    rail: {
        height: 10,
        borderRadius: 10,
    },
}))(Slider);

const marks = [
    {
        value: 0,
        label: "$0K",
    },
    {
        value: 17,
        label: "$5K",
    },
    {
        value: 33,
        label: "$10K",
    },
    {
        value: 49,
        label: "$25K",
    },
    {
        value: 65,
        label: "$50K",
    },
    {
        value: 81,
        label: "$75K",
    },
    {
        value: 100,
        label: "$100K",
    },
];

const Pricing = ({ history }) => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const [accountant, setAcco] = useState(false);
    const [controller, setCont] = useState(false);
    const [cfo, setCFO] = useState(false);

    const [value, setValue] = useState(0);
    const [monthAccPriceO, setMonthAccPriceO] = useState(0);
    const [monthContPriceO, setMonthContPriceO] = useState(0);
    const [monthCFOPriceO, setMonthCFOPriceO] = useState(0);
    const [monthAccPrice, setMonthAccPrice] = useState(0);
    const [monthContPrice, setMonthContPrice] = useState(0);
    const [monthCFOPrice, setMonthCFOPrice] = useState(0);
    const [monthTaxPrice, setMonthTaxPrice] = useState(0);

    const [checked1Acc, setChecked1Acc] = useState(false);
    const [checked2Acc, setChecked2Acc] = useState(false);
    const [checked3Acc, setChecked3Acc] = useState(false);
    const [checked4Acc, setChecked4Acc] = useState(false);
    const [checked5Acc, setChecked5Acc] = useState(false);

    const [checked1Cont, setChecked1Cont] = useState(false);
    const [checked2Cont, setChecked2Cont] = useState(false);
    const [checked3Cont, setChecked3Cont] = useState(false);

    const [checked1CFO, setChecked1CFO] = useState(false);
    const [checked2CFO, setChecked2CFO] = useState(false);
    const [checked3CFO, setChecked3CFO] = useState(false);

    const [checked1Tax, setChecked1Tax] = useState(false);
    const [checked2Tax, setChecked2Tax] = useState(false);
    const [checked3Tax, setChecked3Tax] = useState(false);
    const [checked4Tax, setChecked4Tax] = useState(false);
    const [checked5Tax, setChecked5Tax] = useState(false);

    const [isDisabled, setisDisabled] = useState(true);

    const [checkedPay, setCheckedPay] = useState(false);

    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [last, setLast] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setcompanyName] = useState("");
    const [phone, setPhone] = useState("");
    const [isError, setisError] = useState(false);
    const [loading, showLoading] = useState(false);

    const myRef = useRef(null);
    const myRefService = useRef(null);
    const customTextRef = useRef(null);

    const clearInputs = () => {
        setName("");
        setLast("");
        setEmail("");
        setPhone("");
        setcompanyName("");
        setisError(false);
        setOpen(false);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };
    const handleChangeLast = (event) => {
        setLast(event.target.value);
    };
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };
    const handleChangeCompany = (event) => {
        setcompanyName(event.target.value);
    };

    const handleChangeCheck1Acc = (event) => {
        setChecked1Acc(event.target.checked);
    };
    const handleChangeCheck2Acc = (event) => {
        setChecked2Acc(event.target.checked);
    };
    const handleChangeCheck3Acc = (event) => {
        setChecked3Acc(event.target.checked);
    };
    const handleChangeCheck4Acc = (event) => {
        setChecked4Acc(event.target.checked);
    };
    const handleChangeCheck5Acc = (event) => {
        setChecked5Acc(event.target.checked);
    };

    const handleChangeCheck1Cont = (event) => {
        setChecked1Cont(event.target.checked);
    };
    const handleChangeCheck2Cont = (event) => {
        setChecked2Cont(event.target.checked);
    };
    const handleChangeCheck3Cont = (event) => {
        setChecked3Cont(event.target.checked);
    };

    const handleChangeCheck1CFO = (event) => {
        setChecked1CFO(event.target.checked);
    };
    const handleChangeCheck2CFO = (event) => {
        setChecked2CFO(event.target.checked);
    };
    const handleChangeCheck3CFO = (event) => {
        setChecked3CFO(event.target.checked);
    };

    const handleChangeCheck1Tax = (event) => {
        setChecked1Tax(event.target.checked);
    };
    const handleChangeCheck2Tax = (event) => {
        setChecked2Tax(event.target.checked);
    };
    const handleChangeCheck3Tax = (event) => {
        setChecked3Tax(event.target.checked);
    };
    const handleChangeCheck4Tax = (event) => {
        setChecked4Tax(event.target.checked);
    };
    const handleChangeCheck5Tax = (event) => {
        setChecked5Tax(event.target.checked);
    };

    const handleChangeSwitch = (event) => {
        setCheckedPay(event.target.checked);
        if (event.target.checked) {
            if (!isDisabled) {
                setMonthAccPrice(monthAccPriceO - 0.15 * monthAccPriceO + monthTaxPrice);
                setMonthContPrice(monthContPriceO - 0.15 * monthContPriceO + monthTaxPrice);
                setMonthCFOPrice(monthCFOPriceO - 0.15 * monthCFOPriceO + monthTaxPrice);
            } else {
                setMonthAccPrice(monthAccPriceO - 0.15 * monthAccPriceO);
                setMonthContPrice(monthContPriceO - 0.15 * monthContPriceO);
                setMonthCFOPrice(monthCFOPriceO - 0.15 * monthCFOPriceO);
            }
        } else {
            if (!isDisabled) {
                setMonthAccPrice(monthAccPriceO + monthTaxPrice);
                setMonthContPrice(monthContPriceO + monthTaxPrice);
                setMonthCFOPrice(monthCFOPriceO + monthTaxPrice);
            } else {
                setMonthAccPrice(monthAccPriceO);
                setMonthContPrice(monthContPriceO);
                setMonthCFOPrice(monthCFOPriceO);
            }
        }
    };

    const handleChange = (event, newValue) => {
        switch (newValue) {
            case 0:
                setMonthAccPrice(0);
                setMonthContPrice(0);
                setMonthCFOPrice(0);
                setMonthAccPriceO(0);
                setMonthContPriceO(0);
                setMonthCFOPriceO(0);
                setMonthTaxPrice(0);
                break;
            case 17:
                if (!isDisabled) {
                    if (checkedPay) {
                        setMonthAccPrice(199 - 0.15 * 199 + 69);
                        setMonthContPrice(399 - 0.15 * 399 + 69);
                        setMonthCFOPrice(499 - 0.15 * 499 + 69);
                    } else {
                        setMonthAccPrice(199 + 69);
                        setMonthContPrice(399 + 69);
                        setMonthCFOPrice(499 + 69);
                    }
                } else {
                    if (checkedPay) {
                        setMonthAccPrice(199 - 0.15 * 199);
                        setMonthContPrice(399 - 0.15 * 399);
                        setMonthCFOPrice(499 - 0.15 * 499);
                    } else {
                        setMonthAccPrice(199);
                        setMonthContPrice(399);
                        setMonthCFOPrice(499);
                    }
                }
                setMonthAccPriceO(199);
                setMonthContPriceO(399);
                setMonthCFOPriceO(499);
                setMonthTaxPrice(69);
                break;
            case 33:
                if (!isDisabled) {
                    if (checkedPay) {
                        setMonthAccPrice(299 - 0.15 * 299 + 79);
                        setMonthContPrice(549 - 0.15 * 549 + 79);
                        setMonthCFOPrice(699 - 0.15 * 699 + 79);
                    } else {
                        setMonthAccPrice(299 + 79);
                        setMonthContPrice(549 + 79);
                        setMonthCFOPrice(699 + 79);
                    }
                } else {
                    if (checkedPay) {
                        setMonthAccPrice(299 - 0.15 * 299);
                        setMonthContPrice(549 - 0.15 * 549);
                        setMonthCFOPrice(699 - 0.15 * 699);
                    } else {
                        setMonthAccPrice(299);
                        setMonthContPrice(549);
                        setMonthCFOPrice(699);
                    }
                }
                setMonthAccPriceO(299);
                setMonthContPriceO(549);
                setMonthCFOPriceO(699);
                setMonthTaxPrice(79);
                break;
            case 49:
                if (!isDisabled) {
                    if (checkedPay) {
                        setMonthAccPrice(399 - 0.15 * 399 + 89);
                        setMonthContPrice(699 - 0.15 * 699 + 89);
                        setMonthCFOPrice(899 - 0.15 * 899 + 89);
                    } else {
                        setMonthAccPrice(399 + 89);
                        setMonthContPrice(699 + 89);
                        setMonthCFOPrice(899 + 89);
                    }
                } else {
                    if (checkedPay) {
                        setMonthAccPrice(399 - 0.15 * 399);
                        setMonthContPrice(699 - 0.15 * 699);
                        setMonthCFOPrice(899 - 0.15 * 899);
                    } else {
                        setMonthAccPrice(399);
                        setMonthContPrice(699);
                        setMonthCFOPrice(899);
                    }
                }
                setMonthAccPriceO(399);
                setMonthContPriceO(699);
                setMonthCFOPriceO(899);
                setMonthTaxPrice(89);
                break;
            case 65:
                if (!isDisabled) {
                    if (checkedPay) {
                        setMonthAccPrice(499 - 0.15 * 499 + 99);
                        setMonthContPrice(849 - 0.15 * 849 + 99);
                        setMonthCFOPrice(1099 - 0.15 * 1099 + 99);
                    } else {
                        setMonthAccPrice(499 + 99);
                        setMonthContPrice(849 + 99);
                        setMonthCFOPrice(1099 + 99);
                    }
                } else {
                    if (checkedPay) {
                        setMonthAccPrice(499 - 0.15 * 499);
                        setMonthContPrice(849 - 0.15 * 849);
                        setMonthCFOPrice(1099 - 0.15 * 1099);
                    } else {
                        setMonthAccPrice(499);
                        setMonthContPrice(849);
                        setMonthCFOPrice(1099);
                    }
                }
                setMonthAccPriceO(499);
                setMonthContPriceO(849);
                setMonthCFOPriceO(1099);
                setMonthTaxPrice(99);
                break;
            case 81:
                if (!isDisabled) {
                    if (checkedPay) {
                        setMonthAccPrice(599 - 0.15 * 599 + 109);
                        setMonthContPrice(999 - 0.15 * 999 + 109);
                        setMonthCFOPrice(1299 - 0.15 * 1299 + 109);
                    } else {
                        setMonthAccPrice(599 + 109);
                        setMonthContPrice(999 + 109);
                        setMonthCFOPrice(1299 + 109);
                    }
                } else {
                    if (checkedPay) {
                        setMonthAccPrice(599 - 0.15 * 599);
                        setMonthContPrice(999 - 0.15 * 999);
                        setMonthCFOPrice(1299 - 0.15 * 1299);
                    } else {
                        setMonthAccPrice(599);
                        setMonthContPrice(999);
                        setMonthCFOPrice(1299);
                    }
                }
                setMonthAccPriceO(599);
                setMonthContPriceO(999);
                setMonthCFOPriceO(1299);
                setMonthTaxPrice(109);
                break;
            default:
                if (!isDisabled) {
                    if (checkedPay) {
                        setMonthAccPrice(649 - 0.15 * 649 + 119);
                        setMonthContPrice(1069 - 0.15 * 1069 + 119);
                        setMonthCFOPrice(1399 - 0.15 * 1399 + 119);
                    } else {
                        setMonthAccPrice(649 + 119);
                        setMonthContPrice(1069 + 119);
                        setMonthCFOPrice(1399 + 119);
                    }
                } else {
                    if (checkedPay) {
                        setMonthAccPrice(649 - 0.15 * 649);
                        setMonthContPrice(1069 - 0.15 * 1069);
                        setMonthCFOPrice(1399 - 0.15 * 1399);
                    } else {
                        setMonthAccPrice(649);
                        setMonthContPrice(1069);
                        setMonthCFOPrice(1399);
                    }
                }
                setMonthAccPriceO(649);
                setMonthContPriceO(1069);
                setMonthCFOPriceO(1399);
                setMonthTaxPrice(119);
                break;
        }
        setValue(newValue);
    };

    const showTaxes = (val) => {
        setisDisabled(val);
        setChecked1Tax(false);
        setChecked2Tax(false);
        setChecked3Tax(false);
        setChecked4Tax(false);
        setChecked5Tax(false);
        if (!val) {
            setMonthAccPrice(monthAccPrice + monthTaxPrice);
            setMonthContPrice(monthContPrice + monthTaxPrice);
            setMonthCFOPrice(monthCFOPrice + monthTaxPrice);
        } else {
            setMonthAccPrice(monthAccPrice - monthTaxPrice);
            setMonthContPrice(monthContPrice - monthTaxPrice);
            setMonthCFOPrice(monthCFOPrice - monthTaxPrice);
        }
    };

    const showTaxesExp = () => {
        showTaxes(false);
        myRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const cleanParams = () => {
        setValue(0);
        setChecked1Acc(false);
        setChecked2Acc(false);
        setChecked3Acc(false);
        setChecked4Acc(false);
        setChecked5Acc(false);
        setChecked1Cont(false);
        setChecked2Cont(false);
        setChecked3Cont(false);
        setChecked1CFO(false);
        setChecked2CFO(false);
        setChecked3CFO(false);
        setChecked1Tax(false);
        setChecked2Tax(false);
        setChecked3Tax(false);
        setChecked4Tax(false);
        setChecked5Tax(false);
        setCheckedPay(false);
        setisDisabled(true);
    };

    const handleClick = (val) => {
        cleanParams();
        setAcco(false);
        setCont(false);
        setCFO(false);
        if (window.innerWidth < 600) {
            myRefService.current.scrollIntoView({ behavior: "smooth" });
        }
        switch (val) {
            case 1:
                setAcco(true);
                break;
            case 2:
                setCont(true);
                break;
            case 3:
                setCFO(true);
                break;
            default:
                break;
        }
    };

    function Accountant1Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priRec")}
                </Typography>
            );
        }
        return null;
    }
    function Accountant2Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priPRec")}
                </Typography>
            );
        }
        return null;
    }
    function Accountant3Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priClass")}
                </Typography>
            );
        }
        return null;
    }
    function Accountant4Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priMulAcc")}
                </Typography>
            );
        }
        return null;
    }
    function Accountant5Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priPerAcc")}
                </Typography>
            );
        }
        return null;
    }

    function Controller1Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priContTax")}
                </Typography>
            );
        }
        return null;
    }
    function Controller2Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priContFin")}
                </Typography>
            );
        }
        return null;
    }
    function Controller3Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priContExt")}
                </Typography>
            );
        }
        return null;
    }

    function CFO1Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priCFOCorp")}
                </Typography>
            );
        }
        return null;
    }
    function CFO2Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priCFOInv")}
                </Typography>
            );
        }
        return null;
    }
    function CFO3Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priCFOInve")}
                </Typography>
            );
        }
        return null;
    }

    function Tax1Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priTaxFor")}
                </Typography>
            );
        }
        return null;
    }
    function Tax2Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priTaxInt")}
                </Typography>
            );
        }
        return null;
    }
    function Tax3Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priTaxQuar")}
                </Typography>
            );
        }
        return null;
    }
    function Tax4Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priTaxPlan")}
                </Typography>
            );
        }
        return null;
    }
    function Tax5Check(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <Typography variant="body2" className={classes.spacingAdd}>
                    {t("pricing.priTaxInter")}
                </Typography>
            );
        }
        return null;
    }

    function TotalPriceMAcc(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <>
                    <Typography variant="body1" style={{ paddingBottom: 5, paddingTop: 5 }}>
                        {t("pricing.priCoin")}
                        {monthAccPriceO}/<span style={{ fontSize: 14 }}>{t("pricing.priOnFee")}</span>
                    </Typography>
                    <Typography variant="body1">
                        {t("pricing.priCoin")}
                        {parseFloat(monthAccPrice).toLocaleString(t("language.currencyFormat"), {
                            maximumFractionDigits: 2,
                        })}
                        /<span style={{ fontSize: 14 }}>{t("pricing.priMonths")}</span>
                    </Typography>
                </>
            );
        }
        return null;
    }
    function TotalPriceMCont(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <>
                    <Typography variant="body1" style={{ paddingBottom: 5, paddingTop: 5 }}>
                        {t("pricing.priCoin")}
                        {parseFloat(monthContPriceO).toLocaleString(t("language.currencyFormat"), {
                            maximumFractionDigits: 2,
                        })}
                        /<span style={{ fontSize: 14 }}>{t("pricing.priOnFee")}</span>
                    </Typography>
                    <Typography variant="body1">
                        {t("pricing.priCoin")}
                        {parseFloat(monthContPrice).toLocaleString(t("language.currencyFormat"), {
                            maximumFractionDigits: 2,
                        })}
                        /<span style={{ fontSize: 14 }}>{t("pricing.priMonths")}</span>
                    </Typography>
                </>
            );
        }
        return null;
    }
    function TotalPriceMCFO(props) {
        const isChecked = props.isChecked;
        if (isChecked) {
            return (
                <>
                    <Typography variant="body1" style={{ paddingBottom: 5, paddingTop: 5 }}>
                        {t("pricing.priCoin")}
                        {parseFloat(monthCFOPriceO).toLocaleString(t("language.currencyFormat"), {
                            maximumFractionDigits: 2,
                        })}
                        /<span style={{ fontSize: 14 }}>{t("pricing.priOnFee")}</span>
                    </Typography>
                    <Typography variant="body1">
                        {t("pricing.priCoin")}
                        {parseFloat(monthCFOPrice).toLocaleString(t("language.currencyFormat"), {
                            maximumFractionDigits: 2,
                        })}
                        /<span style={{ fontSize: 14 }}>{t("pricing.priMonths")}</span>
                    </Typography>
                </>
            );
        }
        return null;
    }

    function TotalPriceAddAcc(props) {
        if (props.isChecked1 || props.isChecked2 || props.isChecked3 || props.isChecked4 || props.isChecked5) {
            return (
                <>
                    <Typography variant="body1" style={{ paddingBottom: 5 }}>
                        {t("pricing.priAddAcc")}/<span style={{ fontSize: 14 }}>{t("pricing.priH")}</span>{" "}
                        <span
                            style={{ fontSize: 14 }}
                            dangerouslySetInnerHTML={{
                                __html: accountant
                                    ? t("pricing.priAcc")
                                    : controller
                                    ? t("pricing.priCont")
                                    : cfo
                                    ? t("pricing.priCFO")
                                    : "",
                            }}
                        ></span>
                    </Typography>
                </>
            );
        }
        return null;
    }
    function TotalPriceAddCont(props) {
        if (props.isChecked1 || props.isChecked2 || props.isChecked3) {
            return (
                <>
                    <Typography variant="body1" style={{ paddingBottom: 5 }}>
                        {t("pricing.priAddCont")}/<span style={{ fontSize: 14 }}>{t("pricing.priH")}</span>{" "}
                        <span
                            style={{ fontSize: 14 }}
                            dangerouslySetInnerHTML={{
                                __html: accountant
                                    ? t("pricing.priAcc")
                                    : controller
                                    ? t("pricing.priCont")
                                    : cfo
                                    ? t("pricing.priCFO")
                                    : "",
                            }}
                        ></span>
                    </Typography>
                </>
            );
        }
        return null;
    }
    function TotalPriceAddCFO(props) {
        if (props.isChecked1 || props.isChecked2 || props.isChecked3) {
            return (
                <>
                    <Typography variant="body1" style={{ paddingBottom: 5 }}>
                        {t("pricing.priAddCFO")}/<span style={{ fontSize: 14 }}>{t("pricing.priH")}</span>{" "}
                        <span
                            style={{ fontSize: 14 }}
                            dangerouslySetInnerHTML={{
                                __html: accountant
                                    ? t("pricing.priAcc")
                                    : controller
                                    ? t("pricing.priCont")
                                    : cfo
                                    ? t("pricing.priCFO")
                                    : "",
                            }}
                        ></span>
                    </Typography>
                </>
            );
        }
        return null;
    }
    function TotalPriceAddTax(props) {
        if (props.isChecked1 || props.isChecked2 || props.isChecked3 || props.isChecked4 || props.isChecked5) {
            return (
                <>
                    <Typography variant="body1">
                        {t("pricing.priAddCont")}/<span style={{ fontSize: 14 }}>{t("pricing.priH")}</span>{" "}
                        <span
                            style={{ fontSize: 14 }}
                            dangerouslySetInnerHTML={{ __html: t("pricing.priTaxExp") }}
                        ></span>
                    </Typography>
                </>
            );
        }
        return null;
    }

    function TotalPriceAddTaxText(props) {
        if (
            props.isChecked1 ||
            props.isChecked2 ||
            props.isChecked3 ||
            props.isChecked4 ||
            props.isChecked5 ||
            props.isChecked6 ||
            props.isChecked7 ||
            props.isChecked8 ||
            props.isChecked9 ||
            props.isChecked10 ||
            props.isChecked11 ||
            props.isChecked12 ||
            props.isChecked13 ||
            props.isChecked14 ||
            props.isChecked15 ||
            props.isChecked16
        ) {
            return (
                <Typography variant="h6" style={{ paddingBottom: 5, paddingTop: 5 }}>
                    +{t("pricing.priAddons")}
                </Typography>
            );
        }
        return null;
    }

    const sendEmail = async () => {
        if (name.trim() && last.trim() && email.trim() && companyName.trim() && /.+@.+\..+/.test(email)) {
            showLoading(true);

            let serviceName = "";
            let amcAmount = 0;
            let serviceAddons = [];
            let taxService = !isDisabled;
            let taxAddons = [];
            let totalServiceAmount = 0;
            let totalFeeAmount = 0;
            let lang = calls.getSessionStorageLanguage();

            if (accountant) {
                serviceName = t("pricing.priAcc");
                if (checked1Acc) {
                    serviceAddons.push(t("pricing.priRec"));
                }
                if (checked2Acc) {
                    serviceAddons.push(t("pricing.priPRec"));
                }
                if (checked3Acc) {
                    serviceAddons.push(t("pricing.priClass"));
                }
                if (checked4Acc) {
                    serviceAddons.push(t("pricing.priMulAcc"));
                }
                if (checked5Acc) {
                    serviceAddons.push(t("pricing.priPerAcc"));
                }
                totalFeeAmount = monthAccPriceO;
                totalServiceAmount = monthAccPrice;
            }
            if (controller) {
                serviceName = t("pricing.priCont");
                if (checked1Cont) {
                    serviceAddons.push(t("pricing.priContTax"));
                }
                if (checked2Cont) {
                    serviceAddons.push(t("pricing.priContFin"));
                }
                if (checked3Cont) {
                    serviceAddons.push(t("pricing.priContExt"));
                }
                totalFeeAmount = monthContPriceO;
                totalServiceAmount = monthContPrice;
            }
            if (cfo) {
                serviceName = t("pricing.priCFO");
                if (checked1CFO) {
                    serviceAddons.push(t("pricing.priCFOCorp"));
                }
                if (checked2CFO) {
                    serviceAddons.push(t("pricing.priCFOInv"));
                }
                if (checked3CFO) {
                    serviceAddons.push(t("pricing.priCFOInve"));
                }
                totalFeeAmount = monthCFOPriceO;
                totalServiceAmount = monthCFOPrice;
            }

            amcAmount =
                value === 0
                    ? 0
                    : value === 17
                    ? 5
                    : value === 33
                    ? 10
                    : value === 49
                    ? 25
                    : value === 65
                    ? 50
                    : value === 81
                    ? 75
                    : 100;

            if (taxService) {
                if (checked1Tax) {
                    taxAddons.push(t("pricing.priTaxFor"));
                }
                if (checked5Tax) {
                    taxAddons.push(t("pricing.priTaxInter"));
                }
                if (checked2Tax) {
                    taxAddons.push(t("pricing.priTaxInt"));
                }
                if (checked3Tax) {
                    taxAddons.push(t("pricing.priTaxQuar"));
                }
                if (checked4Tax) {
                    taxAddons.push(t("pricing.priTaxPlan"));
                }
            }

            const param = {
                service_name: serviceName,
                amc_amount: amcAmount,
                service_addons: serviceAddons,
                tax_service: taxService,
                tax_addons: taxAddons,
                pay_annually: checkedPay,
                total_service_amount: totalServiceAmount,
                total_taxes_amount: monthTaxPrice,
                total_fee_amount: totalFeeAmount,
                first_name: name,
                last_name: last,
                email: email,
                company_name: companyName,
                phone_number: phone,
                language: lang,
            };

            await calls
                .postPricing(param)
                .then((response) => {
                    if (response.data) {
                        clearInputs();
                        history.push("/quote?quoteId=" + response.data);
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });

            showLoading(false);
        } else {
            setisError(true);
        }
    };

    useEffect(() => {
        if (document.getElementById("contL") !== null) {
            let checkBox = document.getElementById("contL").childNodes;
            checkBox[0].innerHTML = t("pricing.priContDed");
        }
        if (document.getElementById("cfoL") !== null) {
            let checkBox = document.getElementById("cfoL").childNodes;
            checkBox[0].innerHTML = t("pricing.priCFODed");
        }
    }, [t, value]);

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <div className={classes.purpleBack}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} className={classes.normalBack}>
                            <Typography
                                variant="h5"
                                className={classes.firstT}
                                dangerouslySetInnerHTML={{ __html: t("header.submenuP") }}
                            ></Typography>
                            <Typography variant="h3" className={classes.secondT}>
                                {t("pricing.priBook")}
                            </Typography>
                            <Typography variant="h3" color="secondary" className={classes.secondT2}>
                                {t("pricing.priScale")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.normalBack2}>
                            <img src={PriGif} alt="pricing" className={classes.pricingImg} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth="xl" disableGutters className={classes.normalBack2} ref={customTextRef}>
                <Grid container justifyContent="center" className={classes.spacing2} spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.thirdT}>
                            {t("pricing.priCust")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={0}
                            className={accountant ? classes.paperFB : classes.paper}
                            onClick={() => handleClick(1)}
                        >
                            <Typography
                                variant="h5"
                                className={classes.spacingText}
                                dangerouslySetInnerHTML={{ __html: t("pricing.priAcc") }}
                            ></Typography>
                            <Typography
                                variant="h6"
                                dangerouslySetInnerHTML={{ __html: t("pricing.priTop") }}
                            ></Typography>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={0}
                            className={controller ? classes.paperFB : classes.paper}
                            onClick={() => handleClick(2)}
                        >
                            <Typography
                                variant="h5"
                                className={classes.spacingText}
                                dangerouslySetInnerHTML={{ __html: t("pricing.priCont") }}
                            ></Typography>
                            <Typography
                                variant="h6"
                                dangerouslySetInnerHTML={{ __html: t("pricing.priGui") }}
                            ></Typography>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={0}
                            className={cfo ? classes.paperFB : classes.paper}
                            onClick={() => handleClick(3)}
                        >
                            <Typography
                                variant="h5"
                                className={classes.spacingText}
                                dangerouslySetInnerHTML={{ __html: t("pricing.priCFO") }}
                            ></Typography>
                            <Typography
                                variant="h6"
                                dangerouslySetInnerHTML={{ __html: t("pricing.priStrat") }}
                            ></Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={6} ref={myRefService}>
                    <Grid item xs={12} md={9} className={classes.spacing3}>
                        {accountant || controller || cfo ? (
                            <>
                                <Typography variant="h6" className={classes.spacing4}>
                                    {t("pricing.priComp")}
                                </Typography>
                                <PrettoSlider marks={marks} value={value} step={null} onChange={handleChange} />
                                <Grid container justifyContent="flex-end" className={classes.spacingS}>
                                    <Grid item>
                                        <Button
                                            component={Link}
                                            to="/tailored-solution"
                                            color="secondary"
                                            disableElevation
                                            className={classes.buttonMore}
                                        >
                                            {t("pricing.priMore")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : null}
                        {accountant && value !== 0 ? (
                            <Grid container justifyContent="flex-end" className={classes.spacingS}>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant="h5"
                                        dangerouslySetInnerHTML={{ __html: t("pricing.priAcc") }}
                                    ></Typography>
                                    <span style={{ fontSize: 18, fontWeight: 700 }}>
                                        {" "}
                                        {t("pricing.priCoin")}
                                        {monthAccPriceO}/{t("pricing.priMonths")}
                                    </span>
                                    <List>
                                        <Tooltip title={t("pricing.priToolAcc")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priDedic")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolCash")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priA")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolBank")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priWeekAcc")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolBalan")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priMonthAcc")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolVid")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priVirtAcc")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolSup")}>
                                            <ListItem disableGutters ref={myRef}>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priUnliAcc")} />
                                            </ListItem>
                                        </Tooltip>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">{t("pricing.priAddons")}</Typography>
                                    <span style={{ fontSize: 18, fontWeight: 700 }}> {t("pricing.priAccStart")}</span>
                                    <Grid container style={{ paddingTop: 15 }}>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolAct")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked1Acc}
                                                            onChange={handleChangeCheck1Acc}
                                                        />
                                                    }
                                                    label={t("pricing.priRec")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolTie")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked2Acc}
                                                            onChange={handleChangeCheck2Acc}
                                                        />
                                                    }
                                                    label={t("pricing.priPRec")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolCost")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked3Acc}
                                                            onChange={handleChangeCheck3Acc}
                                                        />
                                                    }
                                                    label={t("pricing.priClass")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolInter")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked4Acc}
                                                            onChange={handleChangeCheck4Acc}
                                                        />
                                                    }
                                                    label={t("pricing.priMulAcc")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolTay")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked5Acc}
                                                            onChange={handleChangeCheck5Acc}
                                                        />
                                                    }
                                                    label={t("pricing.priPerAcc")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : controller && value !== 0 ? (
                            <Grid container justifyContent="flex-end" className={classes.spacingS}>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant="h5"
                                        dangerouslySetInnerHTML={{ __html: t("pricing.priCont") }}
                                    ></Typography>
                                    <span style={{ fontSize: 18, fontWeight: 700 }}>
                                        {t("pricing.priCoin")}
                                        {parseFloat(monthContPriceO).toLocaleString(t("language.currencyFormat"), {
                                            maximumFractionDigits: 2,
                                        })}
                                        /{t("pricing.priMonths")}
                                    </span>
                                    <List>
                                        <Tooltip title={t("pricing.priToolPub")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText id="contL" primary="" />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolStruc")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priContBuss")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolAccCont")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priContAdmin")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolContFin")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priContFinan")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolImp")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priContAcc")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolDef")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priContPol")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolTay")}>
                                            <ListItem disableGutters ref={myRef}>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priContMont")} />
                                            </ListItem>
                                        </Tooltip>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">{t("pricing.priAddons")}</Typography>
                                    <span style={{ fontSize: 18, fontWeight: 700 }}> {t("pricing.priContStart")}</span>
                                    <Grid container style={{ paddingTop: 15 }}>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolImpCont")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked1Cont}
                                                            onChange={handleChangeCheck1Cont}
                                                        />
                                                    }
                                                    label={t("pricing.priContTax")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolComb")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked2Cont}
                                                            onChange={handleChangeCheck2Cont}
                                                        />
                                                    }
                                                    label={t("pricing.priContFin")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolManag")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked3Cont}
                                                            onChange={handleChangeCheck3Cont}
                                                        />
                                                    }
                                                    label={t("pricing.priContExt")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : cfo && value !== 0 ? (
                            <Grid container justifyContent="flex-end" className={classes.spacingS}>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant="h5"
                                        dangerouslySetInnerHTML={{ __html: t("pricing.priCFO") }}
                                    ></Typography>
                                    <span style={{ fontSize: 18, fontWeight: 700 }}>
                                        {t("pricing.priCoin")}
                                        {parseFloat(monthCFOPriceO).toLocaleString(t("language.currencyFormat"), {
                                            maximumFractionDigits: 2,
                                        })}
                                        /{t("pricing.priMonths")}
                                    </span>
                                    <List>
                                        <Tooltip title={t("pricing.priToolPubCFO")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText id="cfoL" primary="" />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolFin")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priCFOBud")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolStru")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priCFOFina")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolImpa")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priCFOBus")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolPrem")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priCFOCash")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolBus")}>
                                            <ListItem disableGutters>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priCFOKpi")} />
                                            </ListItem>
                                        </Tooltip>
                                        <Tooltip title={t("pricing.priToolTay")}>
                                            <ListItem disableGutters ref={myRef}>
                                                <ListItemIcon style={{ minWidth: 35 }}>
                                                    <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={t("pricing.priContMont")} />
                                            </ListItem>
                                        </Tooltip>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">{t("pricing.priAddons")}</Typography>
                                    <span style={{ fontSize: 18, fontWeight: 700 }}> {t("pricing.priCFOStart")}</span>
                                    <Grid container style={{ paddingTop: 15 }}>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolRul")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked1CFO}
                                                            onChange={handleChangeCheck1CFO}
                                                        />
                                                    }
                                                    label={t("pricing.priCFOCorp")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolSupp")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked2CFO}
                                                            onChange={handleChangeCheck2CFO}
                                                        />
                                                    }
                                                    label={t("pricing.priCFOInv")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title={t("pricing.priToolTayl")} placement="right">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{ color: "#031851" }}
                                                            checked={checked3CFO}
                                                            onChange={handleChangeCheck3CFO}
                                                        />
                                                    }
                                                    label={t("pricing.priCFOInve")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                        {value !== 0 ? (
                            <>
                                <Grid container justifyContent="center">
                                    <Grid item className={classes.purpleBackR}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1" className={classes.purpleText}>
                                                    {t("pricing.priPurCint")}
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.Taxdiv}>
                                                <Button
                                                    color="secondary"
                                                    disableElevation
                                                    variant="contained"
                                                    className={classes.button2}
                                                    onClick={() => showTaxesExp()}
                                                >
                                                    {t("pricing.priAddT")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.spacingR}>
                                    <Grid item xs={12} md={6}>
                                        <Typography
                                            variant="h5"
                                            dangerouslySetInnerHTML={{ __html: t("pricing.priTaxExp") }}
                                        ></Typography>
                                        <span style={{ fontSize: 18, fontWeight: 700 }}>
                                            {t("pricing.priCoin")}
                                            {parseFloat(monthTaxPrice).toLocaleString(t("language.currencyFormat"), {
                                                maximumFractionDigits: 2,
                                            })}
                                            /{t("pricing.priMonths")}
                                        </span>
                                        <List>
                                            <Tooltip title={t("pricing.priToolPubl")}>
                                                <ListItem disableGutters>
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        className={isDisabled ? classes.textDisabled : null}
                                                        primary={t("pricing.priTaxDed")}
                                                    />
                                                </ListItem>
                                            </Tooltip>
                                            <Tooltip title={t("pricing.priToolInc")}>
                                                <ListItem disableGutters>
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        className={isDisabled ? classes.textDisabled : null}
                                                        primary={t("pricing.priTaxFed")}
                                                    />
                                                </ListItem>
                                            </Tooltip>
                                            <Tooltip title={t("pricing.priToolSupport")}>
                                                <ListItem disableGutters>
                                                    <ListItemIcon style={{ minWidth: 35 }}>
                                                        <CheckIcon fontSize="small" style={{ color: "#031851" }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        className={isDisabled ? classes.textDisabled : null}
                                                        primary={t("pricing.priTaxUnli")}
                                                    />
                                                </ListItem>
                                            </Tooltip>
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h5">{t("pricing.priAddons")}</Typography>
                                        <span style={{ fontSize: 18, fontWeight: 700 }}>
                                            {" "}
                                            {t("pricing.priTaxStart")}
                                        </span>
                                        <Grid container style={{ paddingTop: 15 }}>
                                            <Grid item xs={12}>
                                                <Tooltip title={t("pricing.priToolForm")} placement="right">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                style={{ color: "#031851" }}
                                                                checked={checked1Tax}
                                                                disabled={isDisabled}
                                                                onChange={handleChangeCheck1Tax}
                                                            />
                                                        }
                                                        label={t("pricing.priTaxFor")}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title={t("pricing.priToolForm3")} placement="right">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                style={{ color: "#031851" }}
                                                                checked={checked5Tax}
                                                                disabled={isDisabled}
                                                                onChange={handleChangeCheck5Tax}
                                                            />
                                                        }
                                                        label={t("pricing.priTaxInter")}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title={t("pricing.priToolForm2")} placement="right">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                style={{ color: "#031851" }}
                                                                checked={checked2Tax}
                                                                disabled={isDisabled}
                                                                onChange={handleChangeCheck2Tax}
                                                            />
                                                        }
                                                        label={t("pricing.priTaxInt")}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title={t("pricing.priToolFinan")} placement="right">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                style={{ color: "#031851" }}
                                                                checked={checked3Tax}
                                                                disabled={isDisabled}
                                                                onChange={handleChangeCheck3Tax}
                                                            />
                                                        }
                                                        label={t("pricing.priTaxQuar")}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Tooltip title={t("pricing.priToolDefi")} placement="right">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                style={{ color: "#031851" }}
                                                                checked={checked4Tax}
                                                                disabled={isDisabled}
                                                                onChange={handleChangeCheck4Tax}
                                                            />
                                                        }
                                                        label={t("pricing.priTaxPlan")}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                    {value !== 0 ? (
                        <Grid item xs={12} md={3}>
                            <Paper elevation={0} className={classes.paperF}>
                                <Typography variant="h5" color="secondary">
                                    {t("pricing.priSum")}
                                </Typography>
                                <Typography variant="body2" className={classes.spacingO}>
                                    {t("pricing.priAmc")} {t("pricing.priCoin")}
                                    {value === 0
                                        ? 0
                                        : value === 17
                                        ? 5
                                        : value === 33
                                        ? 10
                                        : value === 49
                                        ? 25
                                        : value === 65
                                        ? 50
                                        : value === 81
                                        ? 75
                                        : 100}
                                    K
                                </Typography>
                                <Typography
                                    variant="h6"
                                    dangerouslySetInnerHTML={{
                                        __html: accountant
                                            ? t("pricing.priAcc")
                                            : controller
                                            ? t("pricing.priCont")
                                            : cfo
                                            ? t("pricing.priCFO")
                                            : "",
                                    }}
                                ></Typography>
                                {checked1Acc ||
                                checked2Acc ||
                                checked3Acc ||
                                checked4Acc ||
                                checked5Acc ||
                                checked1Cont ||
                                checked2Cont ||
                                checked3Cont ||
                                checked1CFO ||
                                checked2CFO ||
                                checked3CFO ? (
                                    <Typography variant="h6" className={classes.spacingAdd}>
                                        {t("pricing.priAddons")}
                                    </Typography>
                                ) : null}
                                {accountant ? (
                                    <>
                                        <Accountant1Check isChecked={checked1Acc} />
                                        <Accountant2Check isChecked={checked2Acc} />
                                        <Accountant3Check isChecked={checked3Acc} />
                                        <Accountant4Check isChecked={checked4Acc} />
                                        <Accountant5Check isChecked={checked5Acc} />
                                    </>
                                ) : controller ? (
                                    <>
                                        <Controller1Check isChecked={checked1Cont} />
                                        <Controller2Check isChecked={checked2Cont} />
                                        <Controller3Check isChecked={checked3Cont} />
                                    </>
                                ) : (
                                    <>
                                        <CFO1Check isChecked={checked1CFO} />
                                        <CFO2Check isChecked={checked2CFO} />
                                        <CFO3Check isChecked={checked3CFO} />
                                    </>
                                )}
                                {!isDisabled ? (
                                    <>
                                        <Divider classes={{ root: classes.divider }} />
                                        <Typography
                                            variant="h6"
                                            dangerouslySetInnerHTML={{ __html: t("pricing.priTaxExp") }}
                                        ></Typography>
                                        {checked1Tax || checked2Tax || checked3Tax || checked4Tax || checked5Tax ? (
                                            <Typography variant="h6" className={classes.spacingAdd}>
                                                {t("pricing.priAddons")}
                                            </Typography>
                                        ) : null}
                                        <Tax1Check isChecked={checked1Tax} />
                                        <Tax5Check isChecked={checked5Tax} />
                                        <Tax2Check isChecked={checked2Tax} />
                                        <Tax3Check isChecked={checked3Tax} />
                                        <Tax4Check isChecked={checked4Tax} />
                                    </>
                                ) : null}
                                <Paper elevation={0} className={classes.paperCustom2}>
                                    <Typography variant="h6">{t("pricing.priTot")}:</Typography>
                                    <TotalPriceMAcc isChecked={accountant} />
                                    <TotalPriceMCont isChecked={controller} />
                                    <TotalPriceMCFO isChecked={cfo} />
                                    <TotalPriceAddTaxText
                                        isChecked1={checked1Acc}
                                        isChecked2={checked2Acc}
                                        isChecked3={checked3Acc}
                                        isChecked4={checked4Acc}
                                        isChecked5={checked5Acc}
                                        isChecked6={checked1Cont}
                                        isChecked7={checked2Cont}
                                        isChecked8={checked3Cont}
                                        isChecked9={checked1CFO}
                                        isChecked10={checked2CFO}
                                        isChecked11={checked3CFO}
                                        isChecked12={checked1Tax}
                                        isChecked13={checked5Tax}
                                        isChecked14={checked2Tax}
                                        isChecked15={checked3Tax}
                                        isChecked16={checked4Tax}
                                    />
                                    <TotalPriceAddAcc
                                        isChecked1={checked1Acc}
                                        isChecked2={checked2Acc}
                                        isChecked3={checked3Acc}
                                        isChecked4={checked4Acc}
                                        isChecked5={checked5Acc}
                                    />
                                    <TotalPriceAddCont
                                        isChecked1={checked1Cont}
                                        isChecked2={checked2Cont}
                                        isChecked3={checked3Cont}
                                    />
                                    <TotalPriceAddCFO
                                        isChecked1={checked1CFO}
                                        isChecked2={checked2CFO}
                                        isChecked3={checked3CFO}
                                    />
                                    <TotalPriceAddTax
                                        isChecked1={checked1Tax}
                                        isChecked2={checked5Tax}
                                        isChecked3={checked2Tax}
                                        isChecked4={checked3Tax}
                                        isChecked5={checked4Tax}
                                    />
                                </Paper>
                                <Typography
                                    style={{ fontSize: 14, paddingTop: 10, paddingBottom: 20 }}
                                    dangerouslySetInnerHTML={{ __html: t("pricing.priOnFeeText") }}
                                ></Typography>
                                <Grid container alignItems="center" spacing={1} className={classes.spacing}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            className={classes.formlabel}
                                            control={
                                                <CustomSwitch checked={checkedPay} onChange={handleChangeSwitch} />
                                            }
                                            label={t("pricing.priPay")}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: 14, marginLeft: 55 }} color="secondary">
                                            {t("pricing.priSave")}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container justifyContent="space-evenly" alignItems="center">
                                    <Grid item>
                                        <Button
                                            color="secondary"
                                            disableElevation
                                            className={classes.button}
                                            onClick={() => {
                                                cleanParams();
                                                customTextRef.current.scrollIntoView({ behavior: "smooth" });
                                            }}
                                        >
                                            {t("pricing.priStartO")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            color="secondary"
                                            className={classes.button}
                                            onClick={() => setOpen(true)}
                                        >
                                            {t("pricing.priGet")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ) : null}
                </Grid>
            </Container>
            <Dialog
                open={open}
                onClose={clearInputs}
                maxWidth={"md"}
                fullWidth={true}
                classes={{ paper: classes.dialogRoot }}
            >
                <DialogTitle id="alert-dialog-title" classes={{ root: classes.dialogTitle }}>
                    {t("pricing.priGetQuote")}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookFirst")}
                                    variant="outlined"
                                    value={name}
                                    onChange={handleChangeName}
                                    error={isError && !name.trim()}
                                    helperText={isError && !name.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookLast")}
                                    variant="outlined"
                                    value={last}
                                    onChange={handleChangeLast}
                                    error={isError && !last.trim()}
                                    helperText={isError && !last.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookEmail")}
                                    variant="outlined"
                                    onChange={handleChangeEmail}
                                    value={email}
                                    error={isError && (!email.trim() || !/.+@.+\..+/.test(email))}
                                    helperText={
                                        isError && !email.trim()
                                            ? t("forms.forReq")
                                            : isError && !/.+@.+\..+/.test(email)
                                            ? t("error.enterEmail")
                                            : ""
                                    }
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CssTextField
                                    fullWidth
                                    placeholder={t("pricing.priCompName")}
                                    variant="outlined"
                                    value={companyName}
                                    onChange={handleChangeCompany}
                                    error={isError && !companyName.trim()}
                                    helperText={isError && !companyName.trim() ? t("forms.forReq") : ""}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CssTextField
                                    fullWidth
                                    placeholder={t("bookkeeping.bookPhone")}
                                    variant="outlined"
                                    value={phone}
                                    onChange={handleChangePhone}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogButtons}>
                    <Button onClick={clearInputs} color="secondary" disableElevation className={classes.buttonMore}>
                        {t("pricing.priCancel")}
                    </Button>
                    <Button
                        onClick={sendEmail}
                        variant="contained"
                        color="secondary"
                        disableElevation
                        className={classes.buttonMore}
                    >
                        {t("pricing.priAccept")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop open={loading} />
        </div>
    );
};

export default Pricing;
