/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** Icons/images */
import { ReactComponent as Badge1 } from "../assets/images/badge-xero-migration-specialist.svg";
import { ReactComponent as Badge2 } from "../assets/images/badge-xero-partner-since.svg";
import { ReactComponent as Badge3 } from "../assets/images/badge-xero-partner.svg";
import BannerImgAbout from "../assets/images/gca-isolated-company.png";
import MariaBarrientosImg from "../assets/images/our-team-maria-barrientos.jpg";
import BrianTorresImg from "../assets/images/our-team-brian-torres.jpg";
import GabrielLoyoImg from "../assets/images/our-team-gabriel-loyo.png";
import DavidLoyoImg from "../assets/images/our-team-david-loyo.jpg";
import DeyaniraGoncalvesImg from "../assets/images/our-team-deyanira-goncalvez.jpg";
import FeliceCaloImg from "../assets/images/our-team-felice-calo.jpg";
import GiancarloAlbaniImg from "../assets/images/our-team-giancarlo-albani.png";
import GeraldineCallesImg from "../assets/images/our-team-geraldine-calles.jpg";
import IraixyGuevaraImg from "../assets/images/our-team-iraixy-guevara.jpg";
import JoseAlvarezImg from "../assets/images/our-team-jose-manuel-alvarez.jpg";
import KeniaGarciaImg from "../assets/images/our-team-kenia-garcia.jpg";
import PedroHerreraImg from "../assets/images/our-team-pedro-herrera.jpg";
import RodrigoQuinteroImg from "../assets/images/our-team-rodrigo-quintero.jpg";
import TrinidadAguilarImg from "../assets/images/our-team-trinidad-aguilar.jpg";
import YaritzaRosasImg from "../assets/images/our-team-yaritza-rosas.jpg";
import JohannaRojas from "../assets/images/our-team-johanna-rojas.jpg";
import MicaelaMedina from "../assets/images/our-team-micaela-medina.jpg";

/** Components ----------------------------------------------------------- */
import { makeStyles, Button, Container, Divider, Grid, Paper, Typography } from "@material-ui/core";
import LinkUI from "@material-ui/core/Link";
import Banner from "./submodules/Banner";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "white",
    },
    rootBanner: {
        flexGrow: 1,
        paddingTop: 100,
        "& p": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.down("md")]: {
            background: "#2F3190",
        },
        [theme.breakpoints.up("lg")]: {
            background: "linear-gradient(90deg, #2F3190 50%, #ffffff 50%)",
        },
    },
    purpleBack: {
        [theme.breakpoints.down("md")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "70px !important",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "120px !important",
        },
    },
    secondBack: {
        backgroundColor: "#F8F9F8",
        "& p": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: 70,
        },
        [theme.breakpoints.up("xl")]: {
            padding: 120,
        },
    },
    thirdBack: {
        "& p": {
            lineHeight: 1.2,
        },
        [theme.breakpoints.down("md")]: {
            padding: "60px 30px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: 70,
        },
        [theme.breakpoints.up("xl")]: {
            padding: 120,
        },
    },
    firstT: {
        [theme.breakpoints.down("lg")]: {
            fontSize: 40,
        },
    },
    firstT2: {
        [theme.breakpoints.up("md")]: {
            paddingRight: 80,
        },
    },
    firstT3: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: 50,
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 50,
            paddingRight: 80,
        },
    },
    firstT4: {
        color: "#ff5968",
    },
    thirdT: {
        color: "#FFFFFF",
        [theme.breakpoints.down("xl")]: {
            paddingTop: 20,
        },
        [theme.breakpoints.up("xl")]: {
            paddingTop: 25,
        },
    },
    spacing: {
        padding: "60px 0 50px",
    },
    spacing1: {
        paddingTop: 40,
    },
    spacing2: {
        paddingTop: 20,
        paddingBottom: 10,
    },
    spacing3: {
        paddingTop: 60,
    },
    spacing4: {
        [theme.breakpoints.down("md")]: {
            paddingTop: "80px !important",
            paddingBottom: "40px !important",
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: "100px !important",
            paddingBottom: "60px !important",
        },
        [theme.breakpoints.up("xl")]: {
            paddingTop: "120px !important",
            paddingBottom: "60px !important",
        },
    },
    link: {
        paddingTop: 50,
        color: theme.palette.text.primary,
        textDecoration: "underline",
    },
    paperCustom: {
        [theme.breakpoints.down("md")]: {
            padding: "30px !important",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "50px 35px !important",
        },
        borderRadius: 20,
    },
    button: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down("md")]: {
            marginTop: 60,
            fontSize: 18,
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: 150,
        },
    },
    button2: {
        borderRadius: 50,
        paddingLeft: 40,
        paddingRight: 40,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "40px !important",
            marginBottom: "70px !important",
            fontSize: 18,
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "30px !important",
            marginBottom: "80px !important",
        },
        [theme.breakpoints.up("xl")]: {
            marginTop: "60px !important",
            marginBottom: "60px !important",
        },
    },
    image: {
        borderRadius: 10,
        backgroundColor: "#E9EAE8",
        height: 100,
        width: 100,
    },
    teamImage: {
        borderRadius: 10,
        height: 100,
        width: 100,
    },
    positionText: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "85%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "90%",
        },
    },
    divider: {
        background: "#ff5968",
        height: 4,
        [theme.breakpoints.down("md")]: {
            marginTop: "0px !important",
            marginBottom: "100px !important",
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "60px !important",
            marginBottom: "150px !important",
        },
        [theme.breakpoints.up("xl")]: {
            marginTop: "0px !important",
            marginBottom: "180px !important",
        },
    },
    badge2: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: 210,
        height: 100,
    },
    badge3: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        [theme.breakpoints.down("md")]: {
            height: 80,
        },
        [theme.breakpoints.up("lg")]: {
            height: 100,
        },
    },
}));

const About = () => {
    const classes = useStyles();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component states */
    const [ger, setGer] = useState(false);

    const [keni, setKenia] = useState(false);

    useEffect(() => {}, [t]);

    const geralBio = () => {
        if (ger) {
            return <span> {t("about.abGeral2")} </span>;
        } else {
            return <span>...</span>;
        }
    };

    const keniaBio = () => {
        if (keni) {
            return <span> {t("about.abKenia2")}</span>;
        } else {
            return <span>...</span>;
        }
    };

    const showGeral = () => {
        setGer(!ger);
    };

    const showKenia = () => {
        setKenia(!keni);
    };

    let extText = (
        <>
            <Typography variant="body1" className={classes.thirdT}>
                {t("about.abWe")}
            </Typography>
            <Typography variant="body1" className={classes.thirdT}>
                {t("about.abFri")}
            </Typography>
            <Typography variant="body1" className={classes.thirdT}>
                {t("about.abGet")}
            </Typography>
        </>
    );

    let geral = geralBio();
    let kenia = keniaBio();

    return (
        <div className={`${classes.root} animated fadeIn`}>
            <Banner
                firstT={t("header.company")}
                secondT={t("about.abDisco")}
                secondT2={t("about.abWho")}
                thirdT={t("about.abGCA")}
                button={extText}
                firstBack={classes.purpleBack}
                persona={BannerImgAbout}
                root={classes.rootBanner}
            />
            <div className={classes.secondBack}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.firstT}>
                                {t("footer.ourCompany")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" color="secondary" className={classes.spacing}>
                                {t("about.abOur")}
                            </Typography>
                            <Typography variant="body1" className={classes.firstT2}>
                                {t("about.abCost")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" color="secondary" className={classes.spacing}>
                                {t("about.abPro")}
                            </Typography>
                            <Typography variant="body1" className={classes.firstT2}>
                                {t("about.abCrea")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" color="secondary" className={classes.spacing}>
                                {t("about.abCore")}
                            </Typography>
                            <Typography variant="body1" className={classes.firstT2}>
                                {t("about.abVal")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.spacing1}>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paperCustom}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">{t("about.abGa")}</Typography>
                                        <Typography variant="body1">{t("about.abPut")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">{t("about.abCol")}</Typography>
                                        <Typography variant="body1">{t("about.abColl")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">{t("about.abAg")}</Typography>
                                        <Typography variant="body1">{t("about.abWin")}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.spacing1}>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paperCustom}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">{t("about.abGre")}</Typography>
                                        <Typography variant="body1">{t("about.abRemark")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">{t("about.abCre")}</Typography>
                                        <Typography variant="body1">{t("about.abDiff")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">{t("about.abAdap")}</Typography>
                                        <Typography variant="body1">{t("about.abAdapt")}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <Button
                                component={Link}
                                to="/company/contact"
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.button}
                            >
                                {t("about.abTouch")}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.thirdBack}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.firstT}>
                                {t("about.abTeam")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.spacing3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1">{t("about.abBelieve")}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.spacing3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" color="secondary">
                                {t("about.abExe")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={3}>
                                    <img src={GeraldineCallesImg} alt="GeraldineC" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Geraldine Calles
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        dangerouslySetInnerHTML={{ __html: t("about.abChief") }}
                                    ></Typography>
                                    <Typography variant="body1" className={classes.firstT3}>
                                        {t("about.abGeral")}
                                        {geral}
                                    </Typography>
                                    {!ger ? (
                                        <LinkUI
                                            component="button"
                                            variant="body1"
                                            className={classes.link}
                                            onClick={() => {
                                                showGeral();
                                            }}
                                        >
                                            {t("about.abMore")}
                                        </LinkUI>
                                    ) : (
                                        <LinkUI
                                            component="button"
                                            variant="body1"
                                            className={classes.link}
                                            onClick={() => {
                                                showGeral();
                                            }}
                                        >
                                            {t("about.abLess")}
                                        </LinkUI>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={3}>
                                    <img src={KeniaGarciaImg} alt="KeniaG" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Kenia García
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        dangerouslySetInnerHTML={{ __html: t("about.abProC") }}
                                    ></Typography>
                                    <Typography variant="body1" className={classes.firstT3}>
                                        {t("about.abKenia")}
                                        {kenia}
                                    </Typography>
                                    {!keni ? (
                                        <LinkUI
                                            component="button"
                                            variant="body1"
                                            className={classes.link}
                                            onClick={() => {
                                                showKenia();
                                            }}
                                        >
                                            {t("about.abMore")}
                                        </LinkUI>
                                    ) : (
                                        <LinkUI
                                            component="button"
                                            variant="body1"
                                            className={classes.link}
                                            onClick={() => {
                                                showKenia();
                                            }}
                                        >
                                            {t("about.abLess")}
                                        </LinkUI>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.spacing3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" color="secondary">
                                {t("about.abLead")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={3}>
                                    <img src={DeyaniraGoncalvesImg} alt="DeyaniraG" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Deyanira Goncalves
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        dangerouslySetInnerHTML={{ __html: t("about.abCloudM") }}
                                    ></Typography>
                                    <Typography variant="body1" className={classes.firstT3}>
                                        {t("about.abDan")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={3}>
                                    <img src={DavidLoyoImg} alt="DavidL" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        David Loyo
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        dangerouslySetInnerHTML={{ __html: t("about.abProBusM") }}
                                    ></Typography>
                                    <Typography variant="body1" className={classes.firstT3}>
                                        {t("about.abGiss")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={3}>
                                    <img src={JoseAlvarezImg} alt="JoseA" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        José Manuel Álvarez
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        dangerouslySetInnerHTML={{ __html: t("about.abDeveM") }}
                                    ></Typography>
                                    <Typography variant="body1" className={classes.firstT3}>
                                        {t("about.abJose")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={3}>
                                    <img src={BrianTorresImg} alt="BrianT" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Brian Torres
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abDeveL") }}
                                    ></Typography>
                                    <Typography variant="body1" className={classes.firstT3}>
                                        {t("about.abBrian")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={3}>
                                    <img src={FeliceCaloImg} alt="FeliceC" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Felice Caló
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abBussL") }}
                                    ></Typography>
                                    <Typography variant="body1" className={classes.firstT3}>
                                        {t("about.abFelice")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={3}>
                                    <img src={MariaBarrientosImg} alt="MariaB" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        María Fernanda Barrientos
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abCloudL") }}
                                    ></Typography>
                                    <Typography variant="body1" className={classes.firstT3}>
                                        {t("about.abAndr")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.spacing3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" color="secondary">
                                {t("about.abSpec")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={YaritzaRosasImg} alt="YaritzaR" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Yaritza Rosas
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abCloudU") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={IraixyGuevaraImg} alt="IraixyG" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Iraixy Guevara
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abCloudU") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={PedroHerreraImg} alt="PedroH" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Pedro Herrera
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abBusA") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={JohannaRojas} alt="JohannaR" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Johanna Rojas
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abFullStack") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={MicaelaMedina} alt="MicaelaM" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Micaela Medina
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abFullStack") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={RodrigoQuinteroImg} alt="RodrigoQ" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Rodrigo Quintero
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abBusGS") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={TrinidadAguilarImg} alt="TrinidadA" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Trinidad Aguilar
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abFinOp") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={GiancarloAlbaniImg} alt="GiancarloA" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Giancarlo Albani
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abProDevA") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container className={classes.spacing3}>
                                <Grid item xs={12} lg={4}>
                                    <img src={GabrielLoyoImg} alt="GabrielL" className={classes.teamImage} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                    <Typography variant="h6" className={classes.spacing2}>
                                        Gabriel Loyo
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={classes.positionText}
                                        dangerouslySetInnerHTML={{ __html: t("about.abProDevI") }}
                                    ></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.thirdBack}>
                <Container maxWidth="xl" disableGutters>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider classes={{ root: classes.divider }} />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h3" className={classes.firstT}>
                                {t("about.abProud")}
                                <span className={classes.firstT4}>{t("about.abCert")}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" spacing={6} className={classes.spacing4}>
                        <Grid item xs={12} lg={4}>
                            <Badge3 className={classes.badge3} />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Badge1 className={classes.badge3} />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Badge2 className={classes.badge2} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <Button
                                component={Link}
                                to="/company/contact"
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.button2}
                            >
                                {t("about.abContact")}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

export default About;
