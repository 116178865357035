/** Imports ---------------------------------------------------------------- */

/** React imports */
import React from 'react';

/** Components ----------------------------------------------------------- */
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    firstT: {
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        },
    },
    secondT: {
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            paddingTop: 30,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 40,
            paddingTop: 50,
        },
        [theme.breakpoints.up('xl')]: {
            width: 750,
            paddingTop: 90,
            fontSize: 50
        },
    },
    secondT2: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 40
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 50
        },
    },
    thirdT: {
        color: '#FFFFFF',
        [theme.breakpoints.down('xl')]: {
            paddingTop: 50,
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: 70,
        },
    },
    personaImg: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 300,
        },
        [theme.breakpoints.up('lg')]: {
            width: 640,
            height: '100%',
        },
        [theme.breakpoints.up('xl')]: {
            width: 780,
        },
    },
}));

const Banner = (props) => {
    
    const classes = useStyles();

    return (
        <div className={props.root}>
            <Container maxWidth="xl" disableGutters>
                <Grid container>
                    <Grid item xs={12} lg={6} className={props.firstBack}>
                        <Typography variant="h5" className={classes.firstT} dangerouslySetInnerHTML={{ __html: props.firstT }}></Typography>
                        <Typography variant="h3" className={classes.secondT} dangerouslySetInnerHTML={{ __html: props.secondT }}></Typography>
                        <Typography variant="h3" color="secondary" className={classes.secondT2} dangerouslySetInnerHTML={{ __html: props.secondT2 }}></Typography>
                        <Typography variant="body1" className={classes.thirdT} dangerouslySetInnerHTML={{ __html: props.thirdT }}></Typography>
                        {props.button}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <img src={props.persona} alt="persona1" className={classes.personaImg} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Banner;
