import Api from "./api";

const calls = {

    // Check and save the language variable in session storage
    getSessionStorageLanguage: () => {
        let language = 'en';

        if (sessionStorage.getItem('lng')) {
            language = sessionStorage.getItem('lng');
            return language;
        }
        sessionStorage.setItem('lng', language);
        return language;
    },

    postContact: (params) => {
        return Api().post('contact', params);
    },

    postBook: (params) => {
        return Api().post('bookMeeting', params);
    },

    postBookDemo: (params) => {
        return Api().post('bookDemo', params);
    },

    postReview: (params) => {
        return Api().post('review', params);
    },

    postQuote: (params) => {
        return Api().post('quote', params);
    },

    postCustomCare: (params) => {
        return Api().post('customerCare', params);
    },

    postBecomeAmbass: (params) => {
        return Api().post('becomeAmbass', params);
    },

    postPartner: (params) => {
        return Api().post('partner', params);
    },

    post100K: (params) => {
        return Api().post('more100K', params);
    },

    postPricing: (params) => {
        return Api().post('createQuote', params);
    },

    postJoinUS: (params) => {
        return Api().post('joinUs', params);
    },

    getPricing: (values) => {
        return Api().get('getQuote', {
            params: {
                quoteId: values
            }
        });
    },

    getPost: (values) => {
        return Api().get('getPost', {
            params: {
                postId: values
            }
        });
    },

    getPosts: (values) => {
        return Api().get('getPosts', {
            params: {
                category: values.cat,
                search: values.search
            }
        });
    },

    getComments: (values) => {
        return Api().get('getComments', {
            params: {
                postId: values
            }
        });
    },
}

export default calls;
